import React from 'react';
import types from 'prop-types';
import { injectStripe } from 'react-stripe-elements';

// Components

import { Button, ErrorMessage, Text, Select, PaymentCard, Loader } from '../../../components';
import Form from '../../core/Form';

// Modules

import history from '../../../modules/history';

// Styles

import './styles.scss';

// ----------------

// Data

const getSubscriptionPlans = isEuResident => [
  {
    label: `Monthly ($19.99${isEuResident ? ' incl. VAT' : ''})`,
    value: '0'
  },
  {
    label: `Annual ($203.88${isEuResident ? ' incl. VAT' : ''})`,
    value: '1'
  }
];

// ----------------

class StripeSubscriptionForm extends Form {
  // Type of props

  static propTypes = {
    fetchingSubscriptionProcess: types.object,
    networkProcess: types.object,
    subscription: types.object,
    className: types.string,
    style: types.object
  };

  // Default value for props

  static defaultProps = { className: '' };

  constructor(props) {
    super(props);

    // State of component
    const { subscription } = props;

    this.state = {
      form: {
        planId:
          typeof subscription.subscriptionNumber !== 'undefined'
            ? subscription.subscriptionNumber + ''
            : props.planId || ''
      }
    };

    // Validation rules

    this.commonRules = {
      optional: false
    };

    // Init form

    this.init();
  }

  // -------- Methods --------

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
    if (prevProps.subscription.subscriptionNumber !== this.props.subscription.subscriptionNumber) {
      const { subscription } = this.props;
      this.setState(prevState => ({
        form: {
          planId:
            typeof subscription.subscriptionNumber !== 'undefined'
              ? subscription.subscriptionNumber + ''
              : ''
        }
      }));
    }
  }

  submitHandler() {
    const promise = this.props.stripe.createToken({ type: 'card' });

    (async () => {
      try {
        const result = await promise;
        if (result && result.error) {
          this.setState({ status: { non_field_errors: result.error.message } });
        } else if (result && result.token) {
          if (this.state.status.non_field_errors) {
            this.setState({ status: { non_field_errors: '' } });
          }
          this.validateAll(() => {
            if (this.submitAction) {
              if (Object.keys(this.state.status).length) this.setState({ status: {} });
              this.submitAction(
                {
                  ...this.state.form,
                  stripeToken: result.token.id
                },
                null,
                { subscriptionId: this.props.subscription.id }
              );
            }
          });
        }
      } catch (error) {
        this.setState({ status: { non_field_errors: error.message } });
      }
    })();
  }

  // Render

  render() {
    const {
      fetchingSubscriptionProcess,
      networkProcess,
      isEuResident,
      subscription,
      className,
      style
    } = this.props;
    const { status } = this.state;

    return (
      <div className={`stripe-subscription-form ${className}`} style={style}>
        <div className="stripe-subscription-form__upper-block">
          <Select
            disabled={!!(subscription && subscription.id)}
            placeholder="Subscription plan"
            options={getSubscriptionPlans(!!isEuResident)}
            name="planId"
            ctx={this}
            customSideBlock={
              fetchingSubscriptionProcess &&
              fetchingSubscriptionProcess.status === 'loading' && (
                <Loader size="small" color="mainBlue" />
              )
            }
          />
          <Text center withMargin type="small">
            Please, enter payment card details (number and expiration date)
          </Text>
        </div>
        <PaymentCard lastCardNumbers={subscription.last4} />
        <ErrorMessage>{status.non_field_errors}</ErrorMessage>
        <div className="stripe-subscription-form__buttons-block">
          <Button color="filledGrey" onClick={() => history.push('/main/leads')}>
            Cancel
          </Button>
          <Button status={networkProcess && networkProcess.status} onClick={this.submitHandler}>
            {subscription.id ? 'Save' : 'Enroll'}
          </Button>
        </div>
      </div>
    );
  }
}

export default injectStripe(StripeSubscriptionForm);
