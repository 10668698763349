import React from 'react';
import { connect } from 'react-redux';
import { StripeProvider, Elements } from 'react-stripe-elements';
import query from 'querystringify';

// Constants

import { STRIPE_PUBLIC_KEY } from '../../../../../constants/enviroment';

// Components

import {
  StripeSubscriptionForm,
  CenterBlock,
  PageWrapper,
  Heading,
  Page,
  Modal,
  Loader
} from '../../../../../components';

// Modules

import history from '../../../../../modules/history';

// Action creators

import { subscribeUser, fetchUserSubscription } from '../../../../../store/actionCreators/user';
import { toggleModal } from '../../../../../store/actionCreators/ui';

// Action types

import {
  SUBSCRIBE_USER_NETWORK,
  FETCH_USER_SUBSCRIPTION_NETWORK
} from '../../../../../store/actionTypes/user';

// ----------------

class SubscriptionInfoTab extends Page {
  // Route config

  static title = 'Subscription information';
  static asTab = {
    displayName: 'Subscription information',
    queryName: 'subscription'
  };

  constructor(props) {
    super(props);
    this.state = { stripe: null };

    // Data

    this.mount = [{ ac: fetchUserSubscription, mapState: ['user', 'subscription', 'status'] }];

    // Bind methods
    this.createStripeInstance = this.createStripeInstance.bind(this);
  }

  // -------- Methods --------

  createStripeInstance() {
    const stripe = window.Stripe(STRIPE_PUBLIC_KEY);
    this.setState({ stripe });
  }

  componentDidMount() {
    super.componentDidMount();

    if (window.Stripe) {
      this.createStripeInstance();
    } else {
      // Load the Stripe script
      (function(d, s, id) {
        let js,
          sjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://js.stripe.com/v3/';
        sjs.parentNode.insertBefore(js, sjs);
      })(document, 'script', 'stripe-js');
      document.getElementById('stripe-js').addEventListener('load', this.createStripeInstance);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    document.getElementById('stripe-js').removeEventListener('load', this.createStripeInstance);
  }

  // Render

  render() {
    const planId = query.parse(history.location.search).planId;
    const { stripe } = this.state;
    const {
      fetchingSubscriptionProcess,
      fetchUserSubscription,
      networkProcess,
      subscribeUser,
      onModalClose,
      subscription,
      isEuResident,
      modalData
    } = this.props;
    const isFetchingSubscription =
      fetchingSubscriptionProcess && fetchingSubscriptionProcess.status === 'loading';

    return (
      <PageWrapper>
        <CenterBlock maxWidth="642px">
          {isFetchingSubscription ? (
            <Loader color="mainBlue" />
          ) : (
            <Heading type="h5" color="blue" withMargin>
              {subscription.id ? 'Edit subscription plan' : 'Subscription plan'}
            </Heading>
          )}
          {stripe && !isFetchingSubscription && (
            <StripeProvider stripe={stripe}>
              <Elements>
                <StripeSubscriptionForm
                  fetchingSubscriptionProcess={fetchingSubscriptionProcess}
                  networkProcess={networkProcess}
                  submitAction={subscribeUser}
                  subscription={subscription}
                  isEuResident={isEuResident}
                  planId={subscription.subscriptionNumber || planId}
                />
              </Elements>
            </StripeProvider>
          )}
        </CenterBlock>
        {!!modalData && (
          <Modal
            data={modalData}
            onClose={onModalClose}
            onConfirm={fetchUserSubscription}
            popupTitle="Success"
            confirmation={true}
          />
        )}
      </PageWrapper>
    );
  }
}

export default connect(
  state => ({
    fetchingSubscriptionProcess: state.network[FETCH_USER_SUBSCRIPTION_NETWORK],
    networkProcess: state.network[SUBSCRIBE_USER_NETWORK],
    modalData: state.ui.modal.subscribeUserConfirmModal,
    subscription: state.user.subscription,
    isEuResident: state.user.userInfo.isEuResident
  }),
  {
    onModalClose: () => toggleModal({ name: 'subscribeUserConfirmModal' }),
    fetchUserSubscription,
    subscribeUser
  }
)(SubscriptionInfoTab);
