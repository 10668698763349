import React from 'react';
import types from 'prop-types';
import classNames from 'classnames';

// Styles

import './styles.scss';

// ----------------

// Type of props

Loader.propTypes = {
  fullHeight: types.bool,
  className: types.string,
  stretch: types.bool,
  style: types.object,
  color: types.string,
  size: types.oneOf(['small', 'normal', 'big'])
};

// Default value for props

Loader.defaultProps = {
  className: '',
  color: 'white',
  size: 'normal'
};

// ----------------

// Modify styles

const modify = props =>
  classNames({
    [`loader__wrapper--${props.color}`]: props.color,
    [`loader__wrapper--${props.size}`]: props.size,
    'loader__wrapper--stretch': props.stretch
  });

// ----------------

export default function Loader(props) {
  const { className, style } = props;

  return (
    <p className={`loader__wrapper ${modify(props)} ${className}`} style={style}>
      <span />
      <span />
      <span />
    </p>
  );
}
