import React from 'react';
import { connect } from 'react-redux';

// Constants

import history from '../../../modules/history';

// Components

import { Page, AuthFormStruct, ForgotPasswordForm, Modal } from '../../../components';

// Action types

import { FORGOT_PASSWORD_NETWORK } from '../../../store/actionTypes/user';

// Action creators

import { forgotPassword } from '../../../store/actionCreators/user';
import { toggleModal } from '../../../store/actionCreators/ui';

// ----------------

class ForgotPasswordPage extends Page {
  // Route (Page) config

  static title = 'Forgot Password';
  static path = '/auth/forgot-password';

  // -------- Methods --------

  onConfirmModal = () => {
    history.push('/auth/login');
  };

  // Render

  render() {
    const { networkProcess, modalData, onModalClose, forgotPassword } = this.props;

    return (
      <AuthFormStruct small title="Forgot Password">
        <ForgotPasswordForm submitAction={forgotPassword} networkProcess={networkProcess} />
        {!!modalData && (
          <Modal
            data={modalData}
            onClose={onModalClose}
            onConfirm={this.onConfirmModal}
            popupTitle="Success"
            confirmation={true}
          />
        )}
      </AuthFormStruct>
    );
  }
}

export default connect(
  state => ({
    networkProcess: state.network[FORGOT_PASSWORD_NETWORK],
    modalData: state.ui.modal.forgotPasswordConfirmModal
  }),
  {
    onModalClose: () => toggleModal({ name: 'forgotPasswordConfirmModal' }),
    forgotPassword
  }
)(ForgotPasswordPage);
