import React from 'react';
import { connect } from 'react-redux';

// Components

import {
  PageWrapper,
  AccountsRow,
  PageTitle,
  Button,
  Modal,
  Table,
  Page
} from '../../../components';

// Modules

import history from '../../../modules/history';

// Action creators

import {
  downloadAccountsCSV,
  fetchAccounts,
  deleteAccount,
  restoreAccount
} from '../../../store/actionCreators/accounts';
import { toggleModal } from '../../../store/actionCreators/ui';

// Action types

import { FETCH_ACCOUNTS_NETWORK } from '../../../store/actionTypes/accounts';

// ----------------
class AccountPage extends Page {
  static title = 'Account Managemant';
  static path = '/main/accounts';
  static exactPath = true;
  static permissions = ['ADMIN'];

  // Data

  mount = [{ ac: fetchAccounts }];
  pathUpdate = [fetchAccounts];

  // Bind methods

  pageContent = this.pageContent.bind(this);

  // -------- Methods --------

  // Content of route

  pageContent() {
    const {
      onAccountActionSuccesModalClose,
      accountActionSuccesModalData,
      downloadAccountsCSV,
      onDeleteModalClose,
      onDeleteModalOpen,
      deleteModalData,
      networkProcess,
      restoreAccount,
      deleteAccount,
      accounts,
      pages
    } = this.props;

    return (
      <PageWrapper>
        <PageTitle
          sideBlock={
            <Button type="secondary" onClick={downloadAccountsCSV}>
              Download csv
            </Button>
          }
        >
          Account Management
        </PageTitle>
        <Table
          columns={[
            {
              label: 'Name',
              width: 21
            },
            {
              label: 'Email',
              width: 25
            },
            {
              label: 'Date',
              width: 17
            },
            {
              label: 'Integration',
              width: 17
            },
            {
              label: 'Subscription',
              width: 10,
              style: { flex: 1 }
            },
            {
              label: 'Actions',
              style: { width: '95px', textAlign: 'left' }
            }
          ]}
          row={AccountsRow}
          networkProcess={networkProcess}
          list={accounts}
          pages={pages}
          rowProps={{
            onEdit: data => history.push(`/main/edit-account/${data.id}`),
            onDelete: data => onDeleteModalOpen(data),
            onRestore: data => restoreAccount(data.id)
          }}
        />
        {!!deleteModalData && (
          <Modal
            onConfirm={() => deleteAccount(deleteModalData.account.id)}
            popupTitle="Delete account"
            onClose={onDeleteModalClose}
            data={deleteModalData}
            confirmButtonTitle="delete"
            confirmButtonColor="filledRed"
            withCancel
            error
          />
        )}
        {!!accountActionSuccesModalData && (
          <Modal
            popupTitle="Success"
            onClose={onAccountActionSuccesModalClose}
            data={accountActionSuccesModalData}
            confirmation
          />
        )}
      </PageWrapper>
    );
  }

  // Render

  render() {
    return this.securRenderPage(this.pageContent);
  }
}

export default connect(
  state => ({
    accountActionSuccesModalData: state.ui.modal.accountActionSuccesModal,
    deleteModalData: state.ui.modal.deleteAccountModal,
    networkProcess: state.network[FETCH_ACCOUNTS_NETWORK],
    accounts: state.accounts.accounts,
    pages: state.accounts.pages
  }),
  {
    onDeleteModalClose: () => toggleModal({ name: 'deleteAccountModal' }),
    onAccountActionSuccesModalClose: () => toggleModal({ name: 'accountActionSuccesModal' }),
    onDeleteModalOpen: account =>
      toggleModal({
        name: 'deleteAccountModal',
        data: {
          account,
          message:
            'Are you sure you want to delete this account? All the related information will be deleted.'
        }
      }),
    downloadAccountsCSV,
    fetchAccounts,
    deleteAccount,
    restoreAccount
  }
)(AccountPage);
