import React from 'react';
import types from 'prop-types';

// Constants

import languages from '../../../constants/languages';
import rules from '../../../constants/validation';

// Components

import { Button, Input, ErrorMessage } from '../../../components';
import Form from '../../core/Form';

// Modules

import history from '../../../modules/history';

// Styles

import './styles.scss';

// ----------------

export default class EditAccountForm extends Form {
  // Type of props

  static propTypes = {
    networkProcess: types.object,
    className: types.string,
    onCancel: types.func,
    style: types.object
  };

  // Default value for props

  static defaultProps = { className: '', onCancel: () => history.push('/main/accounts') };

  constructor(props) {
    super(props);
    const data = props.data || {};

    // State of component

    this.state = {
      form: {
        name: data.name || '',
        phone_number: data.phone_number || '',
        email: data.email || ''
      }
    };

    // Validation rules

    this.commonRules = {
      optional: false
    };

    this.rules = {
      phone_number: {
        ...rules.phone
      },
      email: {
        ...rules.email,
        message: {
          regExp: languages.errorMessage.email
        }
      }
    };

    // Init form

    this.init();
  }

  // -------- Methods --------

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);

    if (prevProps.data !== this.props.data) {
      const { data } = this.props;
      this.setState(prevState => ({
        form: {
          name: data.name || '',
          phone_number: data.phone_number || '',
          email: data.email || ''
        }
      }));
    }
  }

  // Render

  render() {
    const { className, style, networkProcess, onCancel, accountId } = this.props;
    const { status } = this.state;

    return (
      <div className={`edit-account-form ${className}`} style={style}>
        <div className="edit-account-form__upper-block">
          <Input name="name" ctx={this} placeholder="name" className="fullWidth" mainInput />
          <Input name="phone_number" type="tel" ctx={this} placeholder="phone" mainInput />
          <Input name="email" ctx={this} placeholder="email" mainInput />
          <ErrorMessage>{status.non_field_errors}</ErrorMessage>
        </div>
        <div className="edit-account-form__buttons-block">
          <Button color="filledGrey" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            status={networkProcess && networkProcess.status}
            onClick={() => this.submitHandler(accountId)}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}
