import * as TYPES from '../actionTypes/user';
import * as URL from '../../constants/endpoints';
import query from 'querystringify';

// Constants

import { localStorageNames, redirectPath } from '../../constants';

// Modules

import history from '../../modules/history';
import createActionType from '../../modules/createActionType';

// Action types

import { DEFAULT_STATE } from '../actionTypes/base';

// Action creators

import { toggleModal } from '../actionCreators/ui';

// ----------------

// Register user

export const registerUser = (payload, callBack) => ({
  afterConfirmIgnore: true,
  networkType: TYPES.REGISTER_NETWORK,
  endpoint: query => URL.REGISTER,
  payload,
  method: 'POST',
  then: (data, dispatch) => {
    dispatch(
      toggleModal({
        name: 'registerUserSuccessModal',
        data: {
          message: `A link has been sent to ${
            payload.email
          }. Please check your email and follow the link to start using EzyLead.`
        }
      })
    );
    if (callBack) callBack();
  },
  thenError: (error, dispatch) => {
    if (
      error.des &&
      error.des.object &&
      (error.des.object.error === 'Email is already in use' ||
        error.des.object.error === 'Phone number is already in use')
    ) {
      dispatch({
        type: createActionType(TYPES.REGISTER_NETWORK, 'err'),
        errorInfo: {
          des: {
            status: 'error',
            code: 422,
            object: {
              error: 'The email or phone number you entered are already in use!'
            }
          }
        }
      });
    }
  }
});

// Verify account

export const verifyAccount = payload => ({
  before: () => {
    return query.stringify(
      {
        email: payload.email,
        verificationToken: payload.token
      },
      true
    );
  },
  networkType: TYPES.VERIFY_ACCOUNT_NETWORK,
  endpoint: query => URL.VERIFY_ACCOUNT + query
});

// Login user

export const loginUser = payload => ({
  afterConfirmIgnore: true,
  networkType: TYPES.LOGIN_NETWORK,
  endpoint: query => URL.LOGIN,
  payload,
  method: 'POST',
  then: (data, dispatch) => {
    localStorage.setItem(localStorageNames.token, data.token);
    localStorage.setItem(localStorageNames.refreshToken, data.refreshToken);
    dispatch(fetchUserInfo());

    // Redirect user to the previous path stored in localStorags

    const entryPath = localStorage.getItem(localStorageNames.entryPath);
    if (entryPath) {
      localStorage.removeItem(localStorageNames.entryPath);
      history.push(entryPath);
    } else {
      history.push(redirectPath.withToken);
    }
  },
  thenError: (error, dispatch) => {
    if (error.des && (error.des.code === 401 || error.des.code === 404)) {
      dispatch({
        type: createActionType(TYPES.LOGIN_NETWORK, 'err'),
        errorInfo: {
          des: {
            status: 'error',
            code: 401,
            object: {
              email: [' '],
              password: ['Something went wrong. Please check the correctness of your credentials.'],
              error: ''
            }
          }
        }
      });
    }
  }
});

// Logout

export const logout = () => dispatch => {
  localStorage.removeItem(localStorageNames.token);
  localStorage.removeItem(localStorageNames.refreshToken);
  localStorage.removeItem(localStorageNames.facebookToken);
  history.push(redirectPath.withoutToken);
  dispatch({ type: DEFAULT_STATE });
};

// Send email to reset password

export const forgotPassword = payload => ({
  networkType: TYPES.FORGOT_PASSWORD_NETWORK,
  endpoint: query => URL.FORGOT_PASSWORD,
  payload,
  method: 'POST',
  then: (data, dispatch) => {
    dispatch(
      toggleModal({
        name: 'forgotPasswordConfirmModal',
        data: {
          message: 'An email has been sent. Please check it'
        }
      })
    );
  },
  thenError: (error, dispatch) => {
    if (error.des && error.des.code === 404) {
      dispatch({
        type: createActionType(TYPES.FORGOT_PASSWORD_NETWORK, 'err'),
        errorInfo: {
          des: {
            status: 'error',
            code: 404,
            object: {
              email: ['Email doesn’t exist in the system. Please check its correctness.'],
              error: ''
            }
          }
        }
      });
    }
  }
});

// Check token for reser password

export const checkResetPasswordToken = payload => ({
  networkType: TYPES.CHECK_RESET_PASSWORD_TOKEN_NETWORK,
  endpoint: query => `${URL.RESET_PASSWORD}/${payload}`,
  thenError: (error, dispatch) => {
    dispatch(
      toggleModal({
        name: 'resetPasswordConfirmModal',
        data: {
          isError: true,
          message: 'The link has been already used!'
        }
      })
    );
  }
});

// Reset password

export const resetPassword = payload => ({
  networkType: TYPES.RESET_PASSWORD_NETWORK,
  endpoint: query => `${URL.RESET_PASSWORD}/${payload.token}`,
  payload,
  method: 'POST',
  then: (data, dispatch) => {
    dispatch(
      toggleModal({
        name: 'resetPasswordConfirmModal',
        data
      })
    );
  }
});

// Fetch user info

export const fetchUserInfo = () => ({
  networkType: TYPES.FETCH_USER_INFO_NETWORK,
  endpoint: query => URL.USER_INFO
});

// Update user info

export const updateUserInfo = payload => ({
  networkType: TYPES.UPDATE_USER_INFO_NETWORK,
  endpoint: query => URL.UPDATE_USER_INFO,
  payload,
  method: 'PUT',
  then: (data, dispatch) => {
    dispatch(fetchUserInfo());
    dispatch(
      toggleModal({
        name: 'updateUserInfoModal',
        data
      })
    );
  }
});

// Change password

export const changePassword = payload => ({
  networkType: TYPES.CHANGE_PASSWORD_NETWORK,
  endpoint: query => URL.CHANGE_PASSWORD,
  payload,
  method: 'PUT',
  then: (data, dispatch) => {
    dispatch(
      toggleModal({
        name: 'changePasswordConfirmModal',
        data
      })
    );
  }
});

// Delete profile

export const deleteProfile = () => ({
  networkType: TYPES.DELETE_PROFILE_NETWORK,
  endpoint: query => URL.DELETE_PROFILE,
  method: 'DELETE',
  then: (data, dispatch) => {
    dispatch(logout());
  }
});

// Fetch user's subscription

export const fetchUserSubscription = () => ({
  networkType: TYPES.FETCH_USER_SUBSCRIPTION_NETWORK,
  endpoint: query => URL.SUBSCRIPTION
});

// Support request

export const supportRequest = (payload, callBack) => ({
  networkType: TYPES.SUPPORT_REQUEST_NETWORK,
  endpoint: query => URL.SUPPORT,
  payload,
  method: 'POST',
  then: (data, dispatch) => {
    dispatch(
      toggleModal({
        name: 'supportRequestConfirmModal',
        data: {
          message: 'Thanks for your message, we will contact you shortly on email.'
        }
      })
    );
    if (callBack) callBack();
  }
});

// Subscribe user

export const subscribeUser = (payload, callBack, rest) => ({
  networkType: TYPES.SUBSCRIBE_USER_NETWORK,
  endpoint: query => URL.SUBSCRIPTION + payload.planId,
  payload: {
    stripeToken: payload.stripeToken
  },
  method: 'POST',
  then: (data, dispatch) => {
    dispatch(
      toggleModal({
        name: 'subscribeUserConfirmModal',
        data: rest.subscriptionId
          ? {
              message:
                'Thanks. The subscription plan successfully updated. Starting from the first day of the next month new settings will be applied'
            }
          : data
      })
    );
  }
});
