export default {
  errorMessage: {
    confirm: 'Password does not match',
    oneOf: 'Please provide either email or phone',
    accept: 'Wrong file format',
    regExp: 'Wrong data format',
    empty: 'Required field',
    min: 'The entered value is too short',
    max: 'The entered value is too long',
    password:
      'Password should contain at least 1 number, 1 upper case letter and be at least 8 symbols long',
    email: 'Please enter correct email address format'
  }
};
