import createActionType from '../../modules/createActionType';
import * as BASE_TYPES from '../actionTypes/base';
import * as TYPES from '../actionTypes/accounts';

// -------- Default state for branch --------

const defaultState = {
  accounts: [],
  count: 0,
  pages: 0
};

// -------- Reducer --------

export default function(state, action) {
  if (!state) return defaultState;

  switch (action.type) {
    case createActionType(TYPES.FETCH_ACCOUNTS_NETWORK): {
      return {
        ...state,
        accounts: action.result.data,
        pages: action.result.pages,
        count: action.result.total
      };
    }

    case createActionType(TYPES.FETCH_ACCOUNT_BY_ID_NETWORK): {
      return {
        ...state,
        accounts: [...state.accounts, action.result.data]
      };
    }

    case createActionType(TYPES.DELETE_ACCOUNT_NETWORK): {
      const accounts = state.accounts.map(account =>
        account.id === action.accountId ? { ...account, markedForRemoval: true } : account
      );
      return {
        ...state,
        accounts
      };
    }

    case createActionType(TYPES.RESTORE_ACCOUNT_NETWORK): {
      const accounts = state.accounts.map(account =>
        account.id === action.accountId ? { ...account, markedForRemoval: false } : account
      );
      return {
        ...state,
        accounts
      };
    }

    case TYPES.DEFAULT_BRANCH_STATE: {
      return defaultState;
    }

    case BASE_TYPES.DEFAULT_STATE: {
      return defaultState;
    }

    default:
      return state;
  }
}
