import React from 'react';

// Constants

import { localStorageNames } from '../../constants';
import { FACEBOOK_APP_ID } from '../../constants/enviroment';

// ----------------

export default function withFacebookAPI(Component) {
  return class FacebookAPIHOC extends React.Component {
    static defaultProps = {};
    static displayName = `FacebookAPIHOC(${Component.name || 'Component'})`;
    static path = Component.path || '';

    // State

    state = {
      isLoading: true,
      isAuth: false,
      userInfo: {},
      adAccounts: [],
      pages: [],
      forms: [],
      error: '',
      ads: []
    };

    // Bind methods

    loginStatusChangeCallback = this.loginStatusChangeCallback.bind(this);
    getFacebookPages = this.getFacebookPages.bind(this);
    getFacebookForms = this.getFacebookForms.bind(this);
    checkLoginState = this.checkLoginState.bind(this);
    subscribePage = this.subscribePage.bind(this);
    errorHandling = this.errorHandling.bind(this);
    onLogIn = this.onLogIn.bind(this);

    // -------- Methods --------

    componentDidMount() {
      // Initialize facebook App

      if (!window.FB) {
        window.fbAsyncInit = () => {
          window.FB.init({
            appId: FACEBOOK_APP_ID,
            autoLogAppEvents: true,
            cookie: true,
            version: 'v3.2'
          });
          this.checkLoginState();
        };

        // Load the SDK

        (function(d, s, id) {
          let js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s);
          js.id = id;
          js.src = '//connect.facebook.net/en_US/sdk.js';
          fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
      } else {
        this.checkLoginState();
      }
    }

    // Methods for receiving data from facebook API

    // Get facebook forms related to selected page

    getFacebookForms(pageId, withSubscribe = false) {
      const page = this.state.pages.find(item => item.id === pageId);
      const pageToken = page && page.access_token;
      if (!pageToken) return;

      this.setState({ isLoading: true });
      if (withSubscribe) {
        this.subscribePage(pageId, pageToken);
      }
      window.FB.api(`/${pageId}/leadgen_forms?access_token=${pageToken}`, response => {
        if (response && response.error) {
          this.errorHandling(response.error);
        } else {
          this.setState({ forms: [...response.data], isLoading: false });
        }
      });
    }

    // Get facebook pages related to account

    getFacebookPages() {
      this.setState({ isLoading: true });
      window.FB.api(`me/accounts?fields=id,name,access_token${this.getToken()}`, response => {
        if (response && response.error) {
          this.errorHandling(response.error);
        } else {
          this.setState({ isAuth: true, isLoading: false, pages: [...response.data] });
        }
      });
    }

    // Check if user is logged in facebook application

    loginStatusChangeCallback(response) {
      if (response.status === 'connected') {
        localStorage.setItem(localStorageNames.facebookToken, response.authResponse.accessToken);
        this.setState({ userInfo: { ...response.authResponse }, isAuth: true });
        this.getFacebookPages();
      } else {
        this.setState({ userInfo: {}, isAuth: false, isLoading: false });
        localStorage.removeItem(localStorageNames.facebookToken);
      }
    }

    // Get saved token from localStorage to prevent auth in facebook after page reload

    getToken() {
      const token = localStorage.getItem(localStorageNames.facebookToken);
      return token ? `&access_token=${token}` : '';
    }

    // Login to facebook with permissions request

    onLogIn() {
      window.FB.login(response => this.loginStatusChangeCallback(response), {
        return_scopes: true,
        scope: 'leads_retrieval,manage_pages'
      });
    }

    // Check if user authorized

    checkLoginState() {
      this.setState({ isLoading: true });
      window.FB.getLoginStatus(this.loginStatusChangeCallback);
    }

    // Subscribe selected page which we want to receive leads from

    subscribePage(pageId, page_access_token) {
      window.FB.api(
        `/${pageId}/subscribed_apps`,
        'post',
        {
          access_token: page_access_token,
          subscribed_fields: ['leadgen']
        },
        response => {}
      );
    }

    // Facebook error handling

    errorHandling(error) {
      if (!error) return;
      if (error.code === 190) {
        this.setState({ isAuth: false, isLoading: false });
        localStorage.removeItem(localStorageNames.facebookToken);
      } else {
        this.setState({ isLoading: false, error: error.message });
      }
    }

    // Render

    render() {
      return (
        <Component
          {...this.props}
          facebookUserInfo={this.state.userInfo}
          getFacebookForms={this.getFacebookForms}
          getFacebookAds={this.getFacebookAds}
          facebookError={this.state.error}
          facebookData={this.state}
          onLogIn={this.onLogIn}
        />
      );
    }
  };
}
