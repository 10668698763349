import React from 'react';
import { Route, Switch, Redirect, Router } from 'react-router-dom';
import history from './modules/history';

// Components

import { Error404 } from './components';

// Styles

import './styles/fonts/index.scss';
import './styles/index.scss';

const topLevelRoutes = require('./routes').default;

export default function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={() => <Redirect to={'/main/home'} />} />
        {topLevelRoutes.map(route => (
          <Route path={route.path} component={route} key={route.path} />
        ))}
        <Route component={Error404} />
      </Switch>
    </Router>
  );
}
