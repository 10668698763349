import React from 'react';
import types from 'prop-types';
import classNames from 'classnames';
import Datetime from 'react-datetime';
import moment from 'moment';
import ReactSVG from 'react-svg';
import InputMask from 'react-input-mask';

// Components

import { FieldWrapper } from '../../../components';

// Modules

import deleteProps from '../../../modules/deleteProps';

// Styles

import './styles.scss';

// Icons

import calendarIcon from '../../../static/img/calendar.svg';

// ----------------

// Type of props

DatePicker.propTypes = {
  placeholder: types.string,
  className: types.string,
  withIcon: types.bool,
  style: types.object,
  title: types.string,
  value: types.string,
  error: types.string,
  name: types.string.isRequired,
  ctx: types.object.isRequired
};

// Default value for props

DatePicker.defaultProps = {
  className: ''
};

// Modify styles

const modify = (props, error) =>
  classNames({
    'datepicker__input--select-style': props.selectStyle,
    'datepicker__input--main': props.mainInput,
    'datepicker__input--error': error
  });

const modifyDatePicker = props =>
  classNames({
    'datepicker--select-style': props.selectStyle
  });

const modifyLabel = (error, value) =>
  classNames({
    'datepicker__datepicker-label--error': error,
    'datepicker__datepicker-label--filled': value
  });

const modifyInputWrapper = props =>
  classNames({
    'datepicker__wrapper--disabled': props.disabled,
    'datepicker__wrapper--select-style': props.selectStyle
  });

// ----------------

const changeHandler = (date, props) => {
  const valid = moment(date, 'MM-DD-YYYY', true).isValid();
  if (valid) {
    props.ctx.setState(
      {
        form: {
          ...props.ctx.state.form,
          [props.name]: date.format('MM-DD-YYYY')
        }
      },
      () => {
        if (props.ctx.changeCallBack) props.ctx.changeCallBack(props.name);
      }
    );
  } else if (!date) {
    props.ctx.formRefs[props.name].blur();
    props.ctx.setState(
      {
        form: {
          ...props.ctx.state.form,
          [props.name]: ''
        }
      },
      () => {
        if (props.ctx.changeCallBack) props.ctx.changeCallBack(props.name);
      }
    );
  }
};

const renderDateInput = props => <InputMask mask="99-99-9999" maskChar="" {...props} />;

export default function DatePicker(props) {
  const { className, error, name, ctx, placeholder, withIcon } = props;

  return (
    <FieldWrapper error={error || ctx.state.status[name]}>
      <div className={`datepicker__wrapper ${modifyInputWrapper(props)}`}>
        {withIcon && (
          <div className="datepicker__icon">
            <ReactSVG src={calendarIcon} />
          </div>
        )}
        <Datetime
          className={`datepicker ${modifyDatePicker(props)}`}
          onChange={date => changeHandler(date, props)}
          onFocus={() => ctx.focusHandler({ target: { name: name } })}
          onBlur={() => ctx.blurHandler({ target: { name: name } })}
          value={ctx.state.form[name]}
          closeOnSelect={true}
          closeOnTab={true}
          renderInput={renderDateInput}
          inputProps={{
            name,
            ref: ref => (ctx.formRefs[name] = ref && ref.getInputDOMNode()),
            autoComplete: 'off',
            id: `datepicker-${name}`,
            className: `datepicker__input ${modify(
              props,
              error || ctx.state.status[name]
            )} ${className}`
          }}
          dateFormat="MM-DD-YYYY"
          timeFormat={false}
          {...deleteProps(props, [
            'customSideBlockAutoWidth',
            'placeholderUppercase',
            'customSideBlock',
            'submitOnEnter',
            'withOutBorder',
            'mainInput',
            'className',
            'fullWidth',
            'title',
            'error',
            'ctx'
          ])}
        />
        <label
          className={`datepicker__datepicker-label ${modifyLabel(
            error || ctx.state.status[name],
            ctx.state.form[name]
          )}`}
          htmlFor={`datepicker-${name}`}
        >
          {placeholder}
        </label>
      </div>
    </FieldWrapper>
  );
}
