import React from 'react';
import types from 'prop-types';
import ReactSVG from 'react-svg';
import classNames from 'classnames';
import ReactPaginate from 'react-paginate';

// Modules

import addQuery from '../../../modules/addQuery';

// Icons and images

import collapseIcon from '../../../static/img/chevron_right.svg';

// Styles

import './styles.scss';

// ----------------

// Type of props

Pagination.propTypes = {
  activePage: types.number,
  pageCount: types.number,
  className: types.string,
  style: types.object
};

// Default value for props

Pagination.defaultProps = {
  className: ''
};

// ----------------

// Modify styles

const pagButtonStyle = props =>
  classNames({
    'pagination-block__btn--prev': props.side === 'prev',
    'pagination-block__btn--next': props.side === 'next'
  });

// ----------------

const NavButton = props => (
  <div className={`pagination-block__btn ${pagButtonStyle(props)}`}>
    <ReactSVG src={collapseIcon} />
  </div>
);

const pageChangeHandler = info => {
  addQuery({ page: info.selected + 1 });
};

// ----------------

export default function Pagination(props) {
  const { className, style, pageCount, activePage } = props;

  return (
    <div className={`pagination-block ${className}`} style={style}>
      <ReactPaginate
        subContainerClassName="pages pagination"
        marginPagesDisplayed={2}
        containerClassName="pagination"
        pageRangeDisplayed={2}
        activeClassName="active"
        breakClassName="break-me"
        previousLabel={<NavButton side="prev" />}
        onPageChange={pageChangeHandler}
        breakLabel={<a>...</a>} // eslint-disable-line
        nextLabel={<NavButton side="next" />}
        forcePage={activePage ? activePage - 1 : 0}
        pageCount={pageCount}
        {...activePage > 1 && { initialPage: activePage - 1 }}
      />
    </div>
  );
}
