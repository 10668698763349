/**
 * Generate form for custom integration
 *
 * @param {string}  url     - Url for form action
 *
 * @return {string} - Generated form
 */

export default function(url) {
  return `
    <form action="${url}" method="POST"  accept-charset="UTF-8" class="ezylead-form">
    <style>
      /* Common ezylead form styles */
      .ezylead-form{
    width: 100%;
    max-width: 100%;
    margin: 20px 0;
  }
  .ezylead-form__input {
    display: block;
    width: 100%;
    margin: 0;
    max-width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
  }  
  .ezylead-form__button{
    display: block;
    width: 100%;
    padding: 15px;
  }
    </style>
    <input required class="ezylead-form__input ezylead-form__input-name" type="text" placeholder="name" name="name">
    <input required class="ezylead-form__input ezylead-form__input-email" type="email" placeholder="email" name="email">
    <input required class="ezylead-form__input ezylead-form__input-phone" type="tel" placeholder="phone" name="phone">
    <textarea class="ezylead-form__input ezylead-form__input-notes" name="notes"  cols="30" rows="5" placeholder="notes"></textarea>
    <button class="ezylead-form__button" type="submit" >Submit</button>
  </form>`;
}
