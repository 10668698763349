import React from 'react';

// Components

import { IntegrationBlock, CenterBlock, PageWrapper, PageTitle, Page } from '../../../components';

// Modules

import history from '../../../modules/history';

// Icons

import leadPagesIcon from '../../../static/img/facebook.svg';
import instaPageIcon from '../../../static/img/instaPage.svg';
import facebookIcon from '../../../static/img/leadPages.svg';
import customIcon from '../../../static/img/baseline-settings.svg';

// Integration blocks data

const integrationBlocks = [
  {
    icon: instaPageIcon,
    buttonTitle: 'instapage',
    onClick: () => history.push('/main/new-integration/instapages')
  },
  {
    icon: facebookIcon,
    buttonTitle: 'facebook lead ads',
    onClick: () => history.push('/main/new-integration/facebook')
  },
  {
    icon: leadPagesIcon,
    buttonTitle: 'lead pages',
    onClick: () => history.push('/main/new-integration/leadpages')
  },
  {
    icon: customIcon,
    buttonTitle: 'custom integration',
    onClick: () => history.push('/main/new-integration/custom')
  }
];

// ----------------
export default class NewIntegrationPage extends Page {
  static title = 'Add New Integration';
  static path = '/main/new-integration';
  static exactPath = true;
  static accessWithSubscription = true;

  // Bind methods

  pageContent = this.pageContent.bind(this);

  // -------- Methods --------

  // Content of route

  pageContent() {
    return (
      <PageWrapper>
        <PageTitle>Add New Integration</PageTitle>
        <CenterBlock flex maxWidth="1005px" withLargePadding>
          {integrationBlocks.map(block => (
            <IntegrationBlock
              onClick={block.onClick}
              buttonTitle={block.buttonTitle}
              icon={block.icon}
              key={block.buttonTitle}
            />
          ))}
        </CenterBlock>
      </PageWrapper>
    );
  }

  // Render

  render() {
    return this.securRenderPage(this.pageContent);
  }
}
