import React from 'react';

// Components

import {
  TermsAndConditionsBlock,
  PageWrapper,
  CenterBlock,
  PageTitle,
  Page
} from '../../../../components';

// ----------------
class TermsPage extends Page {
  static title = 'Terms and Conditions';
  static path = '/main/settings/terms';
  static exactPath = true;

  // -------- Methods --------

  // Render

  render() {
    return (
      <PageWrapper>
        <PageTitle>Terms and Conditions</PageTitle>
        <CenterBlock maxWidth="642px" withPadding>
          <TermsAndConditionsBlock />
        </CenterBlock>
      </PageWrapper>
    );
  }
}

export default TermsPage;
