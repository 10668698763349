// -------- User --------

export const FORGOT_PASSWORD = 'auth/forgot/web';
export const RESET_PASSWORD = 'auth/reset';
export const CHANGE_PASSWORD = 'profile/change-password';
export const REGISTER = 'auth/signup';
export const LOGIN = 'auth/signin';
export const VERIFY_ACCOUNT = 'auth/verify';
export const USER_INFO = 'profile/';
export const UPDATE_USER_INFO = 'profile/update';
export const DELETE_PROFILE = 'profile/remove';
export const SUBSCRIPTION = 'subscriptions/subscription/';

// -------- Integrations --------

export const FETCH_INTEGRATIONS = 'integrations/my';
export const EDIT_INTEGRATION = 'integrations/update';
export const ADD_NEW_INTEGRATION = 'integrations/new';
export const DELETE_INTEGRATION = 'integrations/remove/';
export const INSTAPAGE_TOKEN = 'webhooks/token/instapages';
export const LEADPAGES_TOKEN = 'webhooks/token/leadpages';
export const CUSTOM_TOKEN = 'webhooks/token/custom';
export const INTEGRATION_NAMES = 'integrations/names';

// -------- Leads --------

export const LEAD_WITH_ID = 'leads/lead';
export const FETCH_LEADS = 'leads/my';
export const ADD_LEAD = 'leads/new';
export const DOWNLOAD_LEADS_CSV = 'leads/my/csv';

// -------- Accounts --------

export const ACCOUNTS = 'admin/users';
export const DELETE_ACCOUNT = 'admin/mark/';
export const RESTORE_ACCOUNT = 'admin/unmark/';
export const DOWNLOAD_ACCOUNTS_CSV = 'admin/users/csv';

// -------- Support --------

export const SUPPORT = 'support/request';
