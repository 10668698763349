import React from 'react';
import { connect } from 'react-redux';

// Components

import { Page, AuthFormStruct, LoginForm } from '../../../components';

// Action types

import { LOGIN_NETWORK } from '../../../store/actionTypes/user';

// Action creators

import { loginUser } from '../../../store/actionCreators/user';

// ----------------

class LoginPage extends Page {
  // Page (Route) config

  static title = 'Login';
  static path = '/auth/login';

  // -------- Methods --------

  // Render

  render() {
    const { networkProcess, loginUser } = this.props;

    return (
      <AuthFormStruct small>
        <LoginForm submitAction={loginUser} networkProcess={networkProcess} />
      </AuthFormStruct>
    );
  }
}

export default connect(
  state => ({
    networkProcess: state.network[LOGIN_NETWORK]
  }),
  { loginUser }
)(LoginPage);
