import React from 'react';
import types from 'prop-types';
import moment from 'moment';
import ReactSVG from 'react-svg';
import classNames from 'classnames';

// Components

import { TableRow, IconButton } from '../../../../components';

// Styles

import './styles.scss';

// Icons

import editIcon from '../../../../static/img/edit.svg';
import deleteIcon from '../../../../static/img/delete.svg';

import missedIcon from '../../../../static/img/warning.svg';
import newIcon from '../../../../static/img/new.svg';
import archivedIcon from '../../../../static/img/inbox.svg';
import scheduledIcon from '../../../../static/img/clock.svg';
import processedIcon from '../../../../static/img/phone.svg';

// ----------------

// Modify styles

const modifyRow = status =>
  classNames({
    [`leads-row--${status.toLowerCase()}`]: status
  });

const modify = status =>
  classNames({
    [`leads-row__status-icon--${status.toLowerCase()}`]: status
  });

// Get icon based on status

const getIcon = status => {
  switch (status) {
    case 'New':
      return newIcon;
    case 'Missed':
      return missedIcon;
    case 'Processed':
      return processedIcon;
    case 'Scheduled':
      return scheduledIcon;
    case 'Archived':
      return archivedIcon;
    default:
      return newIcon;
  }
};

// Type of props

LeadsRow.propTypes = {
  data: types.object,
  onEdit: types.func,
  onDelete: types.func
};

// ----------------

export default function LeadsRow(props) {
  const { data, onEdit, onDelete } = props;

  return (
    <TableRow {...props} className={modifyRow(data.status)}>
      <div title={data.status} className={`leads-row__status-icon ${modify(data.status)}`}>
        <ReactSVG src={getIcon(data.status)} />
      </div>
      <div className="leads-row__name">{data.name}</div>
      {moment
        .utc(data.createdAt)
        .local()
        .format('D MMM YYYY')}
      {data.source}
      {data.status.toLowerCase() !== 'archived' ? (
        <>
          <IconButton
            title="Edit"
            onClick={() => onEdit(data)}
            style={{ marginRight: '15px' }}
            type="xsmall"
            color="grey"
          >
            <ReactSVG src={editIcon} />
          </IconButton>
          <IconButton
            title="Delete"
            onClick={() => onDelete(data)}
            style={{ marginLeft: '15px' }}
            type="xsmall"
            color="grey"
          >
            <ReactSVG src={deleteIcon} />
          </IconButton>
        </>
      ) : null}
    </TableRow>
  );
}
