import React from 'react';
import { connect } from 'react-redux';

// Components

import {
  CustomIntegrationForm,
  CenterBlock,
  PageWrapper,
  PageTitle,
  Page
} from '../../../../components';

// Action creators

import {
  fetchIntegrationById,
  getCustomToken,
  addNewIntegration,
  editIntegration
} from '../../../../store/actionCreators/integrations';

// Action types

import {
  GET_CUSTOM_TOKEN_NETWORK,
  EDIT_INTEGRATION_NETWORK,
  ADD_NEW_INTEGRATION_NETWORK
} from '../../../../store/actionTypes/integrations';

// ----------------

class CustomIntegrationPage extends Page {
  // Route config

  static title = 'Custom integration';
  static path = '/main/(new-integration|edit-integration)/custom/:id?';
  static exactPath = true;

  // Data

  mount = !this.props.match.params.id
    ? [
        {
          ac: getCustomToken
        }
      ]
    : [
        {
          ac: fetchIntegrationById,
          payload: { id: this.props.match.params.id },
          find: { key: 'id', value: this.props.match.params.id },
          mapState: ['integrations', 'integrations']
        }
      ];

  // Bind methods

  pageContent = this.pageContent.bind(this);

  // -------- Methods --------

  // Content of route

  pageContent() {
    const integrationId = this.props.match.params.id;
    const {
      generatingTokenProcess,
      addNewIntegration,
      editIntegration,
      networkProcess,
      customMeta,
      integrations
    } = this.props;

    return (
      <PageWrapper>
        <PageTitle>Custom Integration</PageTitle>
        <CenterBlock withPadding>
          <CustomIntegrationForm
            generatingTokenProcess={generatingTokenProcess}
            networkProcess={networkProcess}
            integrationId={integrationId}
            submitAction={integrationId ? editIntegration : addNewIntegration}
            data={
              integrationId ? integrations.find(lead => lead.id === +integrationId) : customMeta
            }
          />
        </CenterBlock>
      </PageWrapper>
    );
  }

  // Render

  render() {
    return this.securRenderPage(this.pageContent);
  }
}

export default connect(
  state => ({
    generatingTokenProcess: state.network[GET_CUSTOM_TOKEN_NETWORK],
    customMeta: state.integrations.customMeta,
    integrations: state.integrations.integrations,
    networkProcess:
      state.network[ADD_NEW_INTEGRATION_NETWORK] || state.network[EDIT_INTEGRATION_NETWORK]
  }),
  {
    addNewIntegration,
    editIntegration
  }
)(CustomIntegrationPage);
