import React from 'react';
import types from 'prop-types';

// Modules

import history from '../../../modules/history';

// Constants

import rules from '../../../constants/validation';
import languages from '../../../constants/languages';

// Components

import { Button, Input, ErrorMessage, Visible } from '../../../components';
import Form from '../../core/Form';

// Styles

import './styles.scss';

// ----------------

export default class ResetPasswordForm extends Form {
  // Type of props

  static propTypes = { className: types.string, style: types.object };

  // Default value for props

  static defaultProps = { className: '' };

  constructor(props) {
    super(props);

    // State of component

    this.state = {
      form: {
        password: '',
        confirm: '',
        token: this.props.token
      },
      passwordType: 'password',
      confirmPasswordType: 'password'
    };

    // Validation rules

    this.rules = {
      password: {
        ...rules.password,
        message: {
          regExp: languages.errorMessage.password
        }
      },
      confirm: { confirm: 'password' }
    };

    // Bind

    this.changePasswordType = this.changePasswordType.bind(this);

    // Init form

    this.init();
  }

  // -------- Methods --------

  // Change password field type

  changePasswordType(type) {
    const passwordType = this.state[type];

    if (passwordType === 'password') {
      this.setState({ [type]: 'text' });
    } else {
      this.setState({ [type]: 'password' });
    }
  }

  // Render

  render() {
    const { className, style, networkProcess } = this.props;
    const { status, passwordType, confirmPasswordType } = this.state;

    return (
      <div className={`login-form ${className}`} style={style}>
        <div className="login-form__upper-block">
          <Input
            type={passwordType}
            name="password"
            placeholder="password"
            ctx={this}
            customSideBlock={
              <Visible
                onClick={() => this.changePasswordType('passwordType')}
                visible={passwordType !== 'text'}
              />
            }
          />
          <Input
            type={confirmPasswordType}
            name="confirm"
            placeholder="password confirmation"
            ctx={this}
            customSideBlock={
              <Visible
                onClick={() => this.changePasswordType('confirmPasswordType')}
                visible={confirmPasswordType !== 'text'}
              />
            }
          />
          <ErrorMessage>{status.non_field_errors}</ErrorMessage>
        </div>
        <div className="forgot-password-form__buttons-block">
          <Button status={networkProcess && networkProcess.status} onClick={this.submitHandler}>
            Save
          </Button>
          <Button color="grey" onClick={() => history.push('/auth/login')}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}
