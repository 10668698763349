import React from 'react';
import { connect } from 'react-redux';

// Components

import {
  InstapageIntegrationForm,
  PageWrapper,
  CenterBlock,
  PageTitle,
  Page
} from '../../../../components';

// Action creators

import {
  fetchIntegrationById,
  getInstapageToken,
  addNewIntegration,
  editIntegration
} from '../../../../store/actionCreators/integrations';

// Action types

import {
  GET_INSTAPAGE_TOKEN_NETWORK,
  ADD_NEW_INTEGRATION_NETWORK,
  EDIT_INTEGRATION_NETWORK
} from '../../../../store/actionTypes/integrations';

// ----------------

class InstapageIntegrationPage extends Page {
  // Route config

  static title = 'Instapage integration';
  static path = '/main/(new-integration|edit-integration)/instapages/:id?';
  static exactPath = true;
  static accessWithSubscription = true;

  // Data

  mount = !this.props.match.params.id
    ? [
        {
          ac: getInstapageToken
        }
      ]
    : [
        {
          ac: fetchIntegrationById,
          payload: { id: this.props.match.params.id },
          find: { key: 'id', value: this.props.match.params.id },
          mapState: ['integrations', 'integrations']
        }
      ];

  // Bind methods

  pageContent = this.pageContent.bind(this);

  // -------- Methods --------

  // Content of route

  pageContent() {
    const integrationId = this.props.match.params.id;
    const {
      generatingTokenProcess,
      addNewIntegration,
      editIntegration,
      networkProcess,
      instapageMeta,
      integrations
    } = this.props;

    return (
      <PageWrapper>
        <PageTitle>Instapage</PageTitle>
        <CenterBlock withPadding>
          <InstapageIntegrationForm
            generatingTokenProcess={generatingTokenProcess}
            networkProcess={networkProcess}
            integrationId={integrationId}
            submitAction={integrationId ? editIntegration : addNewIntegration}
            data={
              integrationId ? integrations.find(lead => lead.id === +integrationId) : instapageMeta
            }
          />
        </CenterBlock>
      </PageWrapper>
    );
  }

  // Render

  render() {
    return this.securRenderPage(this.pageContent);
  }
}

export default connect(
  state => ({
    generatingTokenProcess: state.network[GET_INSTAPAGE_TOKEN_NETWORK],
    instapageMeta: state.integrations.instapageMeta,
    integrations: state.integrations.integrations,
    networkProcess:
      state.network[ADD_NEW_INTEGRATION_NETWORK] || state.network[EDIT_INTEGRATION_NETWORK]
  }),
  {
    addNewIntegration,
    editIntegration
  }
)(InstapageIntegrationPage);
