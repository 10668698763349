import React from 'react';
import { connect } from 'react-redux';

// Components

import {
  CollapsedItem,
  PageWrapper,
  PageTitle,
  FAQForm,
  Modal,
  Page,
  Text
} from '../../../components';

// Action creators

import { supportRequest } from '../../../store/actionCreators/user';
import { toggleModal } from '../../../store/actionCreators/ui';

// Action types

import { SUPPORT_REQUEST_NETWORK } from '../../../store/actionTypes/user';

// Styles

import './styles.scss';

// Images

import custom1Image from '../../../static/img/faq/custom/custom1.png';
import custom2Image from '../../../static/img/faq/custom/custom2.png';
import custom3Image from '../../../static/img/faq/custom/custom3.png';
import custom4Image from '../../../static/img/faq/custom/custom4.png';
import leadpages1Image from '../../../static/img/faq/leadpages/leadpages1.png';
import leadpages2Image from '../../../static/img/faq/leadpages/leadpages2.png';
import leadpages3Image from '../../../static/img/faq/leadpages/leadpages3.png';
import leadpages4Image from '../../../static/img/faq/leadpages/leadpages4.png';
import leadpages5Image from '../../../static/img/faq/leadpages/leadpages5.png';
import leadpages6Image from '../../../static/img/faq/leadpages/leadpages6.png';
import instapage1Image from '../../../static/img/faq/instapage/instapage1.png';
import instapage2Image from '../../../static/img/faq/instapage/instapage2.png';
import instapage21Image from '../../../static/img/faq/instapage/instapage21.png';
import instapage3Image from '../../../static/img/faq/instapage/instapage3.png';
import instapage4Image from '../../../static/img/faq/instapage/instapage4.png';
import instapage5Image from '../../../static/img/faq/instapage/instapage5.png';
import instapage6Image from '../../../static/img/faq/instapage/instapage6.png';
import instapage7Image from '../../../static/img/faq/instapage/instapage7.png';
import instapage8Image from '../../../static/img/faq/instapage/instapage8.png';
import instapage9Image from '../../../static/img/faq/instapage/instapage9.png';
import instapage10Image from '../../../static/img/faq/instapage/instapage10.png';
import instapage101Image from '../../../static/img/faq/instapage/instapage101.png';
import instapage11Image from '../../../static/img/faq/instapage/instapage11.png';
import instapage12Image from '../../../static/img/faq/instapage/instapage12.png';
import facebook1Image from '../../../static/img/faq/facebook/Facebook1.png';
import facebook2Image from '../../../static/img/faq/facebook/Facebook2.png';
import facebook3Image from '../../../static/img/faq/facebook/Facebook3.png';
import facebook4Image from '../../../static/img/faq/facebook/Facebook4.png';
import facebook5Image from '../../../static/img/faq/facebook/Facebook5.png';
import facebook6Image from '../../../static/img/faq/facebook/Facebook6.png';
import facebook7Image from '../../../static/img/faq/facebook/Facebook7.png';
import facebook8Image from '../../../static/img/faq/facebook/Facebook8.png';

// ----------------

// Data

const faqItems = [
  {
    question: 'What applications does Ezylead integrate with?',
    answer:
      'You can easily integrate Ezylead with any Custom Web forms, Instapage, Leadpages and Facebook Lead Ads. All of your leads will be sent to your app in real-time.'
  },
  {
    question: 'Can I use Ezylead with iPhone or Android?',
    answer: 'Yes. The Ezylead app is available on both Android and iPhone.'
  },
  {
    question: 'How much does Ezylead cost?',
    answer: (
      <div>
        The individual pricing plan is $19.99 per month ($16.99 per month if the annual package is
        purchased). You will only be asked for your credit card details when your 14-day free trial
        has come to an end.
        <br />
        <br />
        Our agency account is due to launch shortly. When ready we will publish the pricing plans
        that will be available.
      </div>
    )
  },
  {
    question: 'When am I charged my subscription?',
    answer:
      'When your free 14-day trial is coming to an end. We will contact you to complete your subscription and request your credit card information for billing. Your card will be charged on a monthly basis or once a year if you have chosen the annual billing option.'
  },
  {
    question: 'What support is available?',
    answer: (
      <div>
        The Ezylead app and the lead dashboard are very intuitive and easy to navigate. Support is
        provided via email for individual accounts and email / telephone for agency accounts. You
        can email 
        <a href="support@ezylead.io" rel="noopener noreferrer">
          support@ezylead.io
        </a>
          at any time and a member of our team will assist you.
      </div>
    )
  },
  {
    question: 'Will I receive lead alerts 24/7?',
    answer:
      'The Ezylead app has a built in feature that allows you to specify when you receive lead notifications. This can be found in the profile section of your app. You can set the time of day to ensure you are not interrupted during your downtime. You can also switch off alerts during the weekends. Any new leads will still be updated within the app regardless of whether you receive an alert or not.'
  },
  {
    question: 'How secure is my lead data?',
    answer:
      'Ezylead have partnered with Amazon Web Services to ensure data security is maintained to the highest standard.'
  },
  {
    question: 'How do I cancel my subscription?',
    answer: (
      <div>
        You can cancel your subscription at anytime. Please email{' '}
        <a href="support@ezylead.io" rel="noopener noreferrer">
          support@ezylead.io
        </a>
         with your account details.
      </div>
    )
  },
  {
    question: 'Integrating with Facebook lead ads',
    answer: (
      <div>
        <Text withMargin type="medium" weight="normal">
          Intergrate with Facebook Lead Ads
          <br />
          <br />
          Follow the instruction below to integrate with your Facebook campaign.
          <br />
          <br />
          <strong>IMPORTANT NOTICE:</strong> Your Ezylead integration will not work unless you have
          a Facebook Business Page and be Page Admin for the page. Please ensure this is set up
          before you begin the integration process.
          <br />
          <br />
          1. Select the Facebook icon.
          <img src={facebook1Image} alt="facebook integration" />
          2. You will be asked to log-in to your Facebook account
          <img src={facebook2Image} alt="facebook integration" />
          3. Choose the pages that you would like to connect the Ezylead app too.
          <br />
          <br />
          <strong>
            * If you have multiple business pages, which have lead form campaigns, we suggest
            selecting all pages in your account. This will allow you to easily add additional
            Facebook integrations and save you having to login to Facebook again and select any
            unchecked pages.
          </strong>
          <img src={facebook3Image} alt="facebook integration" />
          4. Ensure that both options are set to YES.
          <img src={facebook4Image} alt="facebook integration" />
          5. Ezylead is now integrated with your Facebook account. Click ok.
          <img src={facebook5Image} alt="facebook integration" />
          6. Now select the page and lead form. Give your integration a name and click ‘INTEGRATE’
          <img src={facebook6Image} alt="facebook integration" />
          That’s it! Your Facebook lead form is now integrated with Ezylead.
          <br />
          <br />
          If you need to test your form and ensure leads are being submitted to Ezylead you can use
          this tool from Facebook:{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://developers.facebook.com/tools/lead-ads-testing"
          >
            https://developers.facebook.com/tools/lead-ads-testing
          </a>
          <br />
          <br />
          To submit a test lead: <br />
          1. Choose the Facebook page you are using. <br />
          2. Select the form linked to your campaign <br />
          3.Select ‘Delete Lead’ to clear any previously submitted leads. <br />
          4.Click ‘Preview Form’
          <img src={facebook7Image} alt="facebook integration" />
          7.Enter test details on the form preview and click submit
          <img src={facebook8Image} alt="facebook integration" />
          That’s it! Your Facebook Lead campaign is now integrated with Ezylead.
        </Text>
      </div>
    )
  },
  {
    question: 'Integrating with Instapage',
    answer: (
      <div>
        <Text withMargin type="medium" weight="normal">
          Integrate with Instapage
          <br />
          <br />
          Follow the instructions below to integrate with your Instapage campaign.
          <br />
          <br />
          The form fields available to send to your Ezylead app are name, email, phone and notes.
          <br />
          <br />
          1. Select the Instapage icon.
          <img src={instapage1Image} alt="instapage integration" />
          2. Click the copy icon to copy the link from your integration
          <img src={instapage2Image} alt="instapage integration" />
          Give your integration a name and click ‘Integrate’
          <img src={instapage21Image} alt="instapage integration" />
          3. Open your Instapage account and select integrations from the left menu.
          <img src={instapage3Image} alt="instapage integration" />
          4. Under integrations, select Webhook.
          <img src={instapage4Image} alt="instapage integration" />
          5. Paste the link you have copied from the Ezylead integration and click CONNECT.
          <img src={instapage5Image} alt="instapage integration" />
          6. Select the page you wish to integrate with.
          <img src={instapage6Image} alt="instapage integration" />
          7. Choose Integrations from the left menu and then select ‘Form Integrations’.
          <img src={instapage7Image} alt="instapage integration" />
          8. Choose Webhook.
          <img src={instapage8Image} alt="instapage integration" />
          9. Select the available endpoint (this is the link you pasted in the first step) and click
          continue.
          <img src={instapage9Image} alt="instapage integration" />
          10. Click ‘CONTINUE’ 3 times until you reach the ‘Fields’ section:
          <img src={instapage101Image} alt="instapage integration" />
          11. You now need to rename the fields in the ‘Fields’ tab to match the Ezylead form field
          structure.
          <br />
          <br />
          These should all be lower case and named as follows:
          <br />
          name
          <br />
          email
          <br />
          phone
          <br />
          notes
          <br />
          <br />
          * If you included a comments section on your web form, please change the label to ‘notes’.
          <img src={instapage10Image} alt="instapage integration" />
          12. Click Continue until you reach the finish.
          <br />
          13. Once done. You must then click on UPDATE to ensure your page is updated with the
          endpoint connection.
          <img src={instapage11Image} alt="instapage integration" />
          14. Then select the page and click PREVIEW. Enter test information into your Instapage
          form and click the ‘Call to Action’ button. Your campaign is now set up and you will have
          received a test lead on your Ezylead app.
          <img src={instapage12Image} alt="instapage integration" />
          That’s it! Your Instapage campaign is now integrated with Ezylead.
        </Text>
      </div>
    )
  },
  {
    question: 'Integrating with Leadpages',
    answer: (
      <div>
        <Text withMargin type="medium" weight="normal">
          Integrate with Leadpages
          <br />
          <br />
          Follow the instructions below to integrate with your Leadpages campaign.
          <br />
          <br />
          1. Select the Leadpages icon.
          <img src={leadpages1Image} alt="leadpages integration" />
          1. Add a redirect URL for the page that will load when a user submits data on your landing
          page. E.g. mylandingpage.com/thankyou.html <br />
          2. Click ‘COPY FORM’ <br />
          3. Give your integration a name <br />
          4. Then click ‘INTEGRATE’
          <img src={leadpages2Image} alt="leadpages integration" />
          ** If you have added a comments or notes field to your landing page form, ensure that you
          click ‘Additional Information’ under Build your form.
          <br />
          <br />
          5. Open your Leadpages account and choose your campaign landing page
          <img src={leadpages3Image} alt="leadpages integration" />
          6. From the menu on the left select widgets and choose the HTML widget
          <img src={leadpages4Image} alt="leadpages integration" />
          7. Drag the widget and place where you would like the form on your page. <br />
          8. Click in the center and the form code will appear <br />
          9. Paste the copied form from the Ezylead integration into the form code area <br />
          <img src={leadpages5Image} alt="leadpages integration" />
          10. Click save, update and then preview
          <br />
          11. Fill out the form fields and click submit. Your test lead will be sent to your app.
          <img src={leadpages6Image} alt="leadpages integration" />
          That’s it! Your Leadpages campaign is now integrated with Ezylead.
        </Text>
      </div>
    )
  },
  {
    question: 'Integrating using Custom Web Forms',
    answer: (
      <div>
        <Text withMargin type="medium" weight="normal">
          Integrate with a custom integration <br />
          <br />
          If you not using a specialist web form service such as Instapage, you can still integrate
          your website enquiry with Ezylead using an API URL.
          <br />
          <br />
          Follow the instructions below to integrate with your website enquiry form.
          <br />
          <br />
          1. Select the Custom Integration icon.
          <img src={custom1Image} alt="custom integration" />
          2. Select ‘COPY FORM’. Give your integration a name then click ‘INTEGRATE’
          <img src={custom2Image} alt="custom integration" />
          3. The code should be embedded on your website. If you are not skilled with HTML we
          suggest contacting your webmaster to assist you with the integration. <br />
          <br />
          4. If you wish to test the form before adding it to your website, we suggest using a site
          such as{' '}
          <a rel="noopener noreferrer" target="_blank" href="https://codepen.io/pen/">
            https://codepen.io/pen/
          </a>
          <br />
          <br />
          5. Enter the copied code in the HTML box and the form will appear below:
          <img src={custom3Image} alt="custom integration" />
          6. Enter some test data and click submit. You will see a message appear confirming the
          lead was submitted successfully:
          <img src={custom4Image} alt="custom integration" />
          That’s it! Your web form is now successfully integrated with Ezylead.
        </Text>
      </div>
    )
  }
];

// ----------------
class SupportPage extends Page {
  static title = 'FAQ & Support';
  static path = '/main/support';
  static exactPath = true;

  // Bind methods

  pageContent = this.pageContent.bind(this);

  // -------- Methods --------

  // Content of route

  pageContent() {
    const { supportRequest, networkProcess, modalData, onModalClose } = this.props;
    return (
      <PageWrapper>
        <PageTitle>FAQ & Support</PageTitle>
        <div className="faq-block">
          {faqItems.map((item, index) => (
            <CollapsedItem key={index} headerTitle={item.question} withBorder number={++index}>
              {item.answer}
            </CollapsedItem>
          ))}
        </div>
        <FAQForm networkProcess={networkProcess} submitAction={supportRequest} />
        {!!modalData && (
          <Modal data={modalData} onClose={onModalClose} popupTitle="Success" confirmation />
        )}
      </PageWrapper>
    );
  }

  // Render

  render() {
    return this.securRenderPage(this.pageContent);
  }
}

export default connect(
  state => ({
    networkProcess: state.network[SUPPORT_REQUEST_NETWORK],
    modalData: state.ui.modal.supportRequestConfirmModal
  }),
  {
    onModalClose: () => toggleModal({ name: 'supportRequestConfirmModal' }),
    supportRequest
  }
)(SupportPage);
