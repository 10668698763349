import React from 'react';
import { connect } from 'react-redux';
import query from 'querystringify';

// Components

import { Page, AuthFormStruct, Text, Loader, ErrorMessage, Button } from '../../../components';

// Modules

import history from '../../../modules/history';

// Action types

import { VERIFY_ACCOUNT_NETWORK } from '../../../store/actionTypes/user';

// Action creators

import { verifyAccount } from '../../../store/actionCreators/user';

// ----------------

class VerifyAccountPage extends Page {
  // Route (Page) config

  static queryForAccess = ['token', 'email'];
  static title = 'Verify Account';
  static path = '/auth/verify';

  constructor(props) {
    super(props);
    const queryObject = query.parse(this.props.location.search);

    // Data

    this.mount = [
      {
        ac: verifyAccount,
        payload: {
          token: queryObject.token,
          email: queryObject.email
        }
      }
    ];

    // Bind methods

    this.pageContent = this.pageContent.bind(this);
  }

  // -------- Methods --------

  // Content of page (Route)

  pageContent() {
    const { networkProcess, isVerified } = this.props;
    return (
      <AuthFormStruct small title="Verify Account">
        <Text withMargin center color="white">
          We are verifying your account. Please, wait a minute.
        </Text>
        {networkProcess && networkProcess.status === 'loading' && <Loader />}
        {networkProcess && networkProcess.status === 'error' && (
          <>
            <ErrorMessage withMargin>
              {networkProcess.object && networkProcess.object.error}
            </ErrorMessage>
            <Button onClick={() => history.push('/auth/login')}>Sign in</Button>
          </>
        )}
        {isVerified && (
          <>
            <Text withMargin center color="white">
              Success. Now you can sign in.
            </Text>
            <Button onClick={() => history.push('/auth/login')}>Sign in</Button>
          </>
        )}
      </AuthFormStruct>
    );
  }

  // Render

  render() {
    return this.securRenderPage(this.pageContent);
  }
}

export default connect(state => ({
  networkProcess: state.network[VERIFY_ACCOUNT_NETWORK],
  isVerified: state.user.isVerified
}))(VerifyAccountPage);
