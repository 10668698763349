import React from 'react';
import types from 'prop-types';
import ReactSVG from 'react-svg';
import classNames from 'classnames';

// Components

import { SidebarLinkItem, IconButton } from '../../../components';

// Styles

import './styles.scss';

// Image and icons

import logo from '../../../static/img/logo.svg';
import closeIcon from '../../../static/img/close.svg';
import menuIcon from '../../../static/img/menu.svg';
import supportIcon from '../../../static/img/support.svg';
import aspectRatioIcon from '../../../static/img/baseline-aspect_ratio.svg';
import exitIcon from '../../../static/img/baseline-exit.svg';
import fontIcon from '../../../static/img/baseline-font.svg';
import homeIcon from '../../../static/img/baseline-home.svg';
import peopleIcon from '../../../static/img/baseline-people.svg';
import settingsIcon from '../../../static/img/baseline-settings.svg';

// ----------------

// Sidebar links object

const sidebarLinks = [
  {
    withSublinks: true,
    title: 'Personal Settings',
    path: '/main/settings',
    icon: fontIcon,
    to: '/main/settings/details',
    children: [
      {
        title: 'Details',
        to: '/main/settings/details'
      },
      {
        title: 'Terms and Conditions',
        to: '/main/settings/terms'
      }
    ]
  },
  {
    title: 'Home',
    icon: homeIcon,
    to: '/main/home',
    isActive: (match, location) =>
      location.pathname.includes('home') || location.pathname.includes('plans')
  },
  {
    isSuperUserRequired: true,
    isSubscriptionRequired: true,
    title: 'Account Management',
    icon: aspectRatioIcon,
    to: '/main/accounts',
    isActive: (match, location) => location.pathname.includes('account')
  },
  {
    title: 'Leads',
    icon: peopleIcon,
    to: '/main/leads',
    isActive: (match, location) =>
      location.pathname.includes('lead') && !location.pathname.includes('leadpage')
  },
  {
    isSubscriptionRequired: true,
    title: 'Integrations',
    icon: settingsIcon,
    to: '/main/integrations',
    isActive: (match, location) => location.pathname.includes('integration')
  },
  {
    title: 'FAQ & Support',
    icon: supportIcon,
    to: '/main/support'
  }
];

// Type of props

Sidebar.propTypes = {
  toggleSidebar: types.func,
  isSuperUser: types.bool,
  className: types.string,
  collapsed: types.bool,
  onLogout: types.func
};

// Default value for props

Sidebar.defaultProps = { className: '' };

// Modify styles

const modify = props =>
  classNames({
    'sidebar--collapsed': props.collapsed
  });

// ----------------

export default function Sidebar(props) {
  const { className, toggleSidebar, collapsed, isSuperUser, onLogout, hasSubscribeAccess } = props;
  return (
    <div className={`sidebar ${className} ${modify(props)}`}>
      <div className="sidebar__close-button">
        <IconButton title="close" type="small" onClick={toggleSidebar}>
          <ReactSVG src={collapsed ? menuIcon : closeIcon} />
        </IconButton>
      </div>
      <div className="sidebar__logo">
        <ReactSVG src={logo} />
      </div>
      <div className="sidebar__menu">
        {sidebarLinks.map(sidebarLink => {
          const { children, ...rest } = sidebarLink;
          // Render sidebar links depends on user's permissions
          return (sidebarLink.isSuperUserRequired && !isSuperUser) ||
            (sidebarLink.isSubscriptionRequired && !hasSubscribeAccess && !isSuperUser) ? null : (
            <SidebarLinkItem {...rest} key={sidebarLink.title}>
              {children &&
                children.map(childlink => <SidebarLinkItem {...childlink} key={childlink.title} />)}
            </SidebarLinkItem>
          );
        })}
        <SidebarLinkItem title="Logout" icon={exitIcon} onClick={onLogout} />
      </div>
    </div>
  );
}
