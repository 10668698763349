import { Redirect } from 'react-router-dom';
import React, { PureComponent } from 'react';
import query from 'querystringify';
import history from '../../modules/history';
import types from 'prop-types';
import moment from 'moment';

// Constants

import { localStorageNames, redirectPath, displayAppName, trialTime } from '../../constants';

// ----------------

/**
  // Page title

  static title: string;

  // Sets whether the user should be authorized or unauthorized to access the page
  // If the static property "type" is not existed, then all users have access to this page

  static access: "auth" | "unauth";

  // Query params required to access the page

  static queryForAccess: Array<string>;

  // The path (Route) at which the component will be rendered

  static path: string;

  // If the route is a tab

  static asTab: { displayName: string, queryName: string, deleteQuery: Array<string> };

  // Permission required to access the page

  static permission: string;

  // Save match params to global object

  static saveParamsGlobal: boolean;

  // In archive page access only in edit mode

  static archiveEditOnly: boolean;

  // An array of action creators, that will be called when sub route or query params have been changed

  pathUpdate: Array<Function>;

  // An array of action creators, that will be called when leaving the page

  unmount: Array<Function>;

  // Action creators for the first page render

  mount: Array<{ ac: Function, mapState?: Array<string> }>;
 */

class Page extends PureComponent {
  constructor(props) {
    super(props);
    const pageInfo = this.constructor;
    const pathname = history.location.pathname;
    this.redirectTo = '';

    // Verifying user authorization, if necessary

    if (pageInfo.access) {
      const token = localStorage.getItem(localStorageNames.token);
      if (token) {
        // If token exist

        if (pageInfo.access === 'unauth') {
          this.redirectTo = redirectPath.withToken;
          return;
        }
      } else if (pageInfo.access === 'auth') {
        // If token not exist, but user should be authorized
        localStorage.setItem(localStorageNames.entryPath, pathname);
        this.redirectTo = redirectPath.withoutToken;
        return;
      }
    }

    // Verifying query params required to access the page

    if (pageInfo.queryForAccess && pageInfo.queryForAccess.length) {
      const queryObject = query.parse(history.location.search);

      if (!pageInfo.queryForAccess.every(item => queryObject[item])) {
        this.redirectTo = redirectPath.withoutToken;
        return;
      }
    }

    // If the user has access to the page

    if (!this.redirectTo) {
      this.currentPath = pathname + history.location.search;

      if (pageInfo.hasOwnProperty('title')) {
        document.title = `${pageInfo.title} | ${displayAppName}`;
      } else {
        document.title = displayAppName;
      }
    }
  }

  // -------- Utils --------

  securRenderPage(PageLayout) {
    if (this.redirectTo) {
      switch (this.redirectTo) {
        case 'no-access': {
          return <div>No access</div>;
        }

        default: {
          return <Redirect to={this.redirectTo} />;
        }
      }
    }
    const pageInfo = this.constructor;
    const store = this.context && this.context.store;

    if (store) {
      const userInfo = store.getState().user.userInfo;
      const isSuperUser = userInfo.Roles.find(role => role.name === 'ADMIN');

      // Check if user has access to the page based on roles

      if (pageInfo.permissions) {
        const access = pageInfo.permissions.every(permission =>
          userInfo.Roles.find(role => role.name === permission)
        );
        if (!access) {
          return <Redirect to={redirectPath.withToken} />;
        }
      }

      // Check if user has subscription

      if (pageInfo.accessWithSubscription) {
        const access =
          isSuperUser ||
          userInfo.StripeDetail ||
          moment().diff(userInfo.createdAt, 'hours') <= trialTime;
        if (!access) {
          return <Redirect to={redirectPath.withoutSubscription} />;
        }
      }
    }

    return <PageLayout />;
  }

  // -------- Life cycle --------

  /**
   *  Dispatch action creators on the first render, if necessary
   */

  componentDidMount() {
    if (!this.redirectTo) {
      const array = this.mount;
      const store = this.context && this.context.store;

      if (!store || !array || !array.length) return;

      const state = store.getState();
      const dispatch = store.dispatch;

      // Get data for route

      array.forEach(item => {
        if (item.mapState) {
          // By condition

          let prop = state;
          item.mapState.forEach(mapItem => {
            prop = prop[mapItem];
          });

          switch (typeof prop) {
            case 'object': {
              if (prop === null) {
                dispatch(item.ac(item.payload || {}, null, item.rest || {}));
              }
              if (prop instanceof Array) {
                if (item.find) {
                  if (!prop.some(object => object[item.find.key] + '' === item.find.value + ''))
                    dispatch(item.ac(item.payload || {}, null, item.rest || {}));
                } else if (!prop.length)
                  dispatch(item.ac(item.payload || {}, null, item.rest || {}));
              } else {
                if (item.find) {
                  if (!(prop[item.find.key] + '' === item.find.value + ''))
                    dispatch(item.ac(item.payload || {}, null, item.rest || {}));
                } else if (!Object.keys(prop).length)
                  dispatch(item.ac(item.payload || {}, null, item.rest || {}));
              }
              break;
            }

            default: {
              if (!prop) {
                dispatch(item.ac(item.payload || {}, null, item.rest || {}));
              }
            }
          }
        } else {
          // On every first render

          dispatch(item.ac(item.payload || {}, null, item.rest || {}));
        }
      });
    }
  }

  /**
   *  Dispatch action creators, when path was updated, but the page has not changed
   */

  componentDidUpdate() {
    if (this.currentPath !== history.location.pathname + history.location.search) {
      this.currentPath = history.location.pathname + history.location.search;

      const array = this.pathUpdate;
      const store = this.context && this.context.store;

      if (!array || !array.length || !store) return;

      const dispatch = store.dispatch;
      array.forEach(item => {
        if (typeof item === 'function') {
          dispatch(item());
        } else if (typeof item === 'object') {
          dispatch(item.ac(item.payload || {}, null, item.rest || {}));
        }
      });
    }
  }

  /**
   *  Dispatch action creators, when page will unmount
   */

  componentWillUnmount() {
    if (!this.redirectTo) {
      const array = this.unmount;
      const store = this.context && this.context.store;

      if (!array || !array.length || !store) return;
      const dispatch = store.dispatch;

      array.forEach(ac => {
        dispatch(ac());
      });
    }
  }
}
export default Page;

Page.contextTypes = {
  store: types.object
};
