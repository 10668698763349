import React from 'react';
import types from 'prop-types';
import classNames from 'classnames';

// Styles

import './styles.scss';

// ----------------

// Type of props

FieldWrapper.propTypes = {
  className: types.string,
  fullWidth: types.bool,
  children: types.object,
  referer: types.func,
  style: types.object,
  title: types.string,
  error: types.string,
  textarea: types.bool
};

// Default value for props

FieldWrapper.defaultProps = { className: '' };

// Modify styles

const modify = props =>
  classNames({
    'ui-field-wrapper__block--mobile-full-width': props.fullWidthMobile,
    'ui-field-wrapper__block--full-width': props.fullWidth,
    'ui-field-wrapper__block--textarea': props.textarea
  });

// ----------------

export default function FieldWrapper(props) {
  const { className, style, children, title, error, referer } = props;

  return (
    <div
      className={`ui-field-wrapper__block ${modify(props)} ${className}`}
      style={style}
      ref={referer}
    >
      {title && <p className="ui-field-wrapper__title">{title}</p>}
      {children}
      {error && <p className="ui-field-wrapper__error">{error}</p>}
    </div>
  );
}
