import React from 'react';
import types from 'prop-types';
import moment from 'moment';

// Constants

import rules from '../../../constants/validation';

// Components

import { Button, Input, ErrorMessage, Textarea } from '../../../components';
import Form from '../../core/Form';

// Modules

import history from '../../../modules/history';

// Styles

import './styles.scss';

// ----------------

export default class AddNewLeadForm extends Form {
  // Type of props

  static propTypes = { className: types.string, style: types.object };

  // Default value for props

  static defaultProps = { className: '' };

  constructor(props) {
    super(props);

    const data = props.data || {};

    // State of component

    this.state = {
      form: {
        status: data.status || 'New',
        source: data.source || 'Manually added',
        name: data.name || '',
        email: data.email || '',
        date: data.createdAt
          ? moment(data.createdAt).format('MM-DD-YYYY')
          : moment().format('MM-DD-YYYY'),
        phone_number: data.phone_number || '',
        notes: data.notes || ''
      }
    };

    // Validation rules

    this.rules = {
      name: { optional: false },
      email: { optional: true, oneOf: 'phone_number', ...rules.email },
      phone_number: { optional: true, oneOf: 'email', ...rules.phone }
    };

    // Init form

    this.init();
  }

  // -------- Methods --------

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
    if (prevProps.data !== this.props.data) {
      const data = this.props.data || {};
      this.setState(prevState => ({
        form: {
          status: data.status || 'New',
          source: data.source || 'Manually added',
          name: data.name || '',
          email: data.email || '',
          date: data.createdAt
            ? moment(data.createdAt).format('MM-DD-YYYY')
            : moment().format('MM-DD-YYYY'),
          phone_number: data.phone_number || '',
          notes: data.notes || ''
        }
      }));
    }
  }

  // Render

  render() {
    const { className, style, networkProcess, leadId } = this.props;
    const { status } = this.state;

    return (
      <div className={`new-lead-form ${className}`} style={style}>
        <div className="new-lead-form__upper-block">
          <Input name="name" ctx={this} placeholder="name" className="fullWidth" mainInput />
          <Input name="email" ctx={this} placeholder="email" mainInput />
          <Input name="phone_number" type="tel" ctx={this} placeholder="phone" mainInput />
          <Input disabled name="date" ctx={this} placeholder="date" mainInput />
          <Textarea name="notes" ctx={this} placeholder="notes" mainInput />
          <ErrorMessage>{status.non_field_errors}</ErrorMessage>
        </div>
        <div className="new-lead-form__buttons-block">
          <Button color="filledGrey" onClick={() => history.push('/main/leads')}>
            Cancel
          </Button>
          <Button
            status={networkProcess && networkProcess.status}
            onClick={() => this.submitHandler(leadId)}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}
