import React from 'react';
import types from 'prop-types';
import classNames from 'classnames';

// Components

import { FieldWrapper } from '../../../components';

// Modules

import deleteProps from '../../../modules/deleteProps';

// Style

import './styles.scss';

// ----------------

// Type of props

Textarea.propTypes = {
  placeholder: types.string,
  className: types.string,
  style: types.object,
  title: types.string,
  value: types.string,
  error: types.string,
  name: types.string.isRequired,
  ctx: types.object.isRequired
};

// Default value for props

Textarea.defaultProps = {
  className: ''
};

// Modify styles

const modify = (props, error) =>
  classNames({
    'textarea__textarea--main': props.mainInput,
    'textarea__textarea--error': error
  });

// ----------------

export default function Textarea(props) {
  const { className, title, error, name, ctx, placeholder } = props;

  return (
    <FieldWrapper
      textarea
      title={title}
      error={error || ctx.state.status[name]}
      referer={ref => (ctx.formRefs[name] = ref)}
    >
      <div className="textarea__wrapper">
        <textarea
          className={`textarea__textarea ${modify(
            props,
            error || ctx.state.status[name]
          )} ${className}`}
          onChange={ctx.changeHandler}
          onFocus={ctx.focusHandler}
          onBlur={ctx.blurHandler}
          value={ctx.state.form[name]}
          {...deleteProps(props, [
            'ctx',
            'customSideBlock',
            'className',
            'mainInput',
            'title',
            'error',
            'fullWidth'
          ])}
        />
        <label className="textarea__input-label" htmlFor={`input-${name}`}>
          {placeholder}
        </label>
      </div>
    </FieldWrapper>
  );
}
