import React from 'react';
import types from 'prop-types';
import ReactSVG from 'react-svg';
import { NavLink, Route } from 'react-router-dom';

// Constants

import history from '../../../modules/history';

// Styles

import './styles.scss';

// ----------------

// Type of props

SidebarLinkItem.propTypes = {
  withSublinks: types.bool,
  className: types.string,
  isActive: types.func,
  onClick: types.func,
  style: types.object,
  title: types.string,
  icon: types.node,
  path: types.string,
  to: types.string
};

// Default value for props

SidebarLinkItem.defaultProps = {
  className: ''
};

// ----------------

export default function SidebarLinkItem(props) {
  const {
    withSublinks,
    className,
    children,
    isActive,
    onClick,
    style,
    title,
    icon,
    path,
    to
  } = props;

  return onClick ? (
    <button className={`sidebar-link-item ${className}`} style={style} onClick={onClick}>
      <div className="sidebar-link-item__link-block">
        <div className="sidebar-link-item__icon-wrapper">{icon && <ReactSVG src={icon} />}</div>
        <p className="sidebar-link-item__label">{title}</p>
      </div>
    </button>
  ) : withSublinks ? (
    <Route
      path={path}
      children={({ match }) => (
        <div
          onClick={match && match.path.includes(path) ? null : () => history.push(to)}
          className={`sidebar-link-item ${match &&
            match.path.includes(path) &&
            'sidebar-link-item--active'}`}
        >
          <div className="sidebar-link-item__link-block">
            {icon && (
              <div className="sidebar-link-item__icon-wrapper">
                <ReactSVG src={icon} />
              </div>
            )}
            <p className="sidebar-link-item__label">{title}</p>
          </div>
          {children && <div className="sidebar-link-item__sublinks-block">{children}</div>}
        </div>
      )}
    />
  ) : (
    <NavLink
      isActive={isActive}
      activeClassName="sidebar-link-item--active"
      className={`sidebar-link-item ${className}`}
      style={style}
      to={to}
    >
      <div className="sidebar-link-item__link-block">
        {icon && (
          <div className="sidebar-link-item__icon-wrapper">
            <ReactSVG src={icon} />
          </div>
        )}
        <p className="sidebar-link-item__label">{title}</p>
      </div>
      {children && <div className="sidebar-link-item__sublinks-block">{children}</div>}
    </NavLink>
  );
}
