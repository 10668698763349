import React from 'react';
import types from 'prop-types';

// Constants

import history from '../../../modules/history';

// Modules

import addQuery from '../../../modules/addQuery';

// ----------------

// Type of props

QueryLink.propTypes = {
  deleteQuery: types.array,
  className: types.string,
  children: types.oneOfType([types.string, types.object]),
  style: types.object,
  query: types.object.isRequired
};

// Default value for props

QueryLink.defaultProps = {
  activeClassName: '',
  className: '',
  children: 'Query Link'
};

// ----------------

export default function QueryLink(props) {
  const { className, style, children, query, deleteQuery } = props;

  return (
    <a // eslint-disable-line
      className={`query-link ${className}`}
      onClick={() => {
        history.replace(`${history.location.pathname}${addQuery(query, deleteQuery, true)}`);
      }}
      style={style}
    >
      {children}
    </a>
  );
}
