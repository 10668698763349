import createActionType from '../../modules/createActionType';
import * as BASE_TYPES from '../actionTypes/base';
import * as TYPES from '../actionTypes/integrations';

// -------- Default state for branch --------

const defaultState = {
  integrationNames: [{ name: 'all' }, { name: 'manually added' }],
  integrations: [],
  count: 0,
  pages: 0,
  instapageMeta: {},
  leadpagesMeta: {},
  customMeta: {}
};

// -------- Reducer --------

export default function(state, action) {
  if (!state) return defaultState;

  switch (action.type) {
    case createActionType(TYPES.FETCH_INTEGRATION_NAMES_NETWORK): {
      return {
        ...state,
        integrationNames: [...defaultState.integrationNames, ...action.result.data]
      };
    }

    case createActionType(TYPES.FETCH_INTEGRATIONS_NETWORK): {
      return {
        ...state,
        integrations: action.result.data,
        pages: action.result.pages,
        count: action.result.total
      };
    }

    case createActionType(TYPES.GET_INSTAPAGE_TOKEN_NETWORK): {
      return {
        ...state,
        instapageMeta: action.result.data
      };
    }

    case createActionType(TYPES.GET_LEADPAGES_TOKEN_NETWORK): {
      return {
        ...state,
        leadpagesMeta: action.result.data
      };
    }

    case createActionType(TYPES.GET_CUSTOM_TOKEN_NETWORK): {
      return {
        ...state,
        customMeta: action.result.data
      };
    }

    case createActionType(TYPES.FETCH_INTEGRATION_BY_ID_NETWORK): {
      return {
        ...state,
        integrations: [...state.integrations, action.result.data]
      };
    }

    case createActionType(TYPES.DELETE_INTEGRATION_NETWORK): {
      return {
        ...state,
        integrations: [
          ...state.integrations.filter(integration => integration.id !== action.integrationId)
        ]
      };
    }

    case TYPES.DEFAULT_BRANCH_STATE: {
      return defaultState;
    }

    case BASE_TYPES.DEFAULT_STATE: {
      return defaultState;
    }

    default:
      return state;
  }
}
