import classNames from 'classnames';
import React from 'react';
import types from 'prop-types';

// Components

import withTimeout from '../../hoc/withTimeout';

// Styles

import './styles.scss';

// ----------------

// Type of props

ToastNotificationItem.propTypes = {
  className: types.string,
  onRemove: types.func,
  onClick: types.func,
  style: types.object,
  item: types.object
};

// Default value for props

ToastNotificationItem.defaultProps = {
  className: ''
};

// Modify styles

const modify = item =>
  classNames({
    [`toast-notification-item--${item.type}`]: item.type
  });

// ----------------

function ToastNotificationItem(props) {
  const { item, onClick, className, style, onRemove } = props;

  return (
    <div className={`toast-notification-item ${modify(item)} ${className}`} style={style}>
      <div className="toast-notification-item__status-color" />
      <div className="toast-notification-item__content" onClick={onClick}>
        {item.data}
      </div>
      <div className="toast-notification-item__close-button" onClick={() => onRemove(item.id)}>
        ×
      </div>
    </div>
  );
}

export default withTimeout(ToastNotificationItem);
