import createActionType from '../../modules/createActionType';
import * as BASE_TYPES from '../actionTypes/base';
import * as TYPES from '../actionTypes/user';

// -------- Default state for branch --------

const defaultState = {
  userInfo: {
    Roles: []
  },
  subscription: {},
  isVerified: false
};

// -------- Reducer --------

export default function(state, action) {
  if (!state) return defaultState;

  switch (action.type) {
    case createActionType(TYPES.FETCH_USER_INFO_NETWORK): {
      return {
        ...state,
        userInfo: action.result.data
      };
    }

    case createActionType(TYPES.FETCH_USER_SUBSCRIPTION_NETWORK): {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          StripeDetail: action.result.data
        },
        subscription: action.result.data || {}
      };
    }

    case createActionType(TYPES.VERIFY_ACCOUNT_NETWORK): {
      return {
        ...state,
        isVerified: true
      };
    }

    case TYPES.DEFAULT_BRANCH_STATE: {
      return defaultState;
    }

    case BASE_TYPES.DEFAULT_STATE: {
      return defaultState;
    }

    default:
      return state;
  }
}
