import createActionType from '../../modules/createActionType';
import * as BASE_TYPES from '../actionTypes/base';
import * as TYPES from '../actionTypes/leads';

// -------- Default state for branch --------

const defaultState = {
  leads: [],
  count: 0,
  pages: 0
};

// -------- Reducer --------

export default function(state, action) {
  if (!state) return defaultState;

  switch (action.type) {
    case createActionType(TYPES.FETCH_LEADS_NETWORK): {
      return {
        ...state,
        leads: action.result.data.docs,
        pages: action.result.data.pages,
        count: action.result.data.total
      };
    }

    case createActionType(TYPES.FETCH_LEAD_BY_ID_NETWORK): {
      return {
        ...state,
        leads: [...state.leads, action.result.data]
      };
    }

    case createActionType(TYPES.DELETE_LEAD_NETWORK): {
      const leads = state.leads.map(lead =>
        lead.id === action.leadId ? { ...lead, status: action.leadStatus } : lead
      );
      return {
        ...state,
        leads
      };
    }

    case TYPES.DEFAULT_BRANCH_STATE: {
      return defaultState;
    }

    case BASE_TYPES.DEFAULT_STATE: {
      return defaultState;
    }

    default:
      return state;
  }
}
