import React from 'react';
import types from 'prop-types';
import classNames from 'classnames';

// Styles

import './styles.scss';

// ----------------

// Type of props

Heading.propTypes = {
  fullWidth: types.bool,
  className: types.string,
  style: types.object,
  type: types.string,
  color: types.string,
  withMargin: types.bool,
  capitalize: types.bool
};

// Default value for props

Heading.defaultProps = {
  className: ''
};

// Modify styles

const modify = props =>
  classNames({
    [`heading--${props.type}`]: props.type,
    [`heading--${props.color}`]: props.color,
    'heading--with-margin': props.withMargin,
    'heading--capitalize': props.capitalize
  });

// ----------------

export default function Heading(props) {
  const { className, style, children } = props;

  return (
    <div className={`heading ${modify(props)} ${className}`} style={style}>
      {children}
    </div>
  );
}
