import React from 'react';
import types from 'prop-types';

// Components

import {
  CopyToClipboard,
  ErrorMessage,
  CheckBox,
  Heading,
  Loader,
  Button,
  Input,
  Text
} from '../../../components';
import Form from '../../core/Form';

// Modules

import history from '../../../modules/history';
import generateLeadpagesForm from '../../../modules/generateLeadpagesForm';

// Styles

import './styles.scss';

// ----------------

export default class LeadpagesIntegrationForm extends Form {
  // Type of props

  static propTypes = {
    generatingTokenProcess: types.object,
    networkProcess: types.object,
    integrationId: types.oneOfType([types.string, types.number]),
    className: types.string,
    style: types.object
  };

  // Default value for props

  static defaultProps = { className: '' };

  constructor(props) {
    super(props);

    const data = props.data || {};

    // State of component

    this.state = {
      form: {
        ...(!this.props.integrationId && { token: data.token || '' }),
        type: data.type || 'Leadpages',
        name: data.name || '',
        redirectUrl: data.redirectUrl || '',
        webHookUrl: data.webHookUrl || data.url || '',
        formName: data.formName || true,
        formEmail: data.formEmail || true,
        formPhone: data.formPhone || true,
        formInfo: data.formInfo || false,
        formSubmitButton: data.formSubmitButton || true
      }
    };

    // Validation rules

    this.commonRules = {
      optional: true
    };

    this.rules = {
      redirectUrl: { optional: false },
      name: { optional: false }
    };

    // Bind methods

    this.onCopy = this.onCopy.bind(this);

    // Init form

    this.init();
  }

  // -------- Methods --------

  onCopy() {
    const { redirectUrl, formInfo, webHookUrl } = this.state.form;

    if (!redirectUrl) {
      this.validateField('redirectUrl', true);
      return;
    }

    return generateLeadpagesForm(webHookUrl, redirectUrl, formInfo);
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
    if (prevProps.data !== this.props.data) {
      const { data } = this.props;
      this.setState(prevState => ({
        form: {
          ...(!this.props.integrationId && { token: data.token || '' }),
          type: data.type || 'Leadpages',
          name: data.name || '',
          webHookUrl: data.webHookUrl || data.url || '',
          redirectUrl: data.redirectUrl || '',
          formName: data.formName || true,
          formEmail: data.formEmail || true,
          formPhone: data.formPhone || true,
          formInfo: data.formInfo || false,
          formSubmitButton: data.formSubmitButton || true
        }
      }));
    }
  }

  // Render

  render() {
    const { className, style, networkProcess, integrationId, generatingTokenProcess } = this.props;
    const { status } = this.state;

    return (
      <div className={`leadpages-integration-form ${className}`} style={style}>
        <div className="leadpages-integration-form__upper-block">
          <Heading type="h5" color="blue" withMargin capitalize>
            Get ready!
          </Heading>
          <Text type="small" center withMargin>
            From your Leadpages account use a custom html widget integrate.
          </Text>
          <Heading type="h5" color="blue" withMargin>
            Build your form:
          </Heading>
          <div className="leadpages-integration-form__checkbox-container">
            <CheckBox alignLeft disabled mainInput name="formName" title="Name" ctx={this} />
            <CheckBox alignLeft disabled mainInput name="formEmail" title="Email" ctx={this} />
            <CheckBox alignLeft disabled mainInput name="formPhone" title="Phone" ctx={this} />
            <CheckBox
              alignLeft
              mainInput
              name="formInfo"
              title="Additional information"
              ctx={this}
            />
            <CheckBox
              alignLeft
              disabled
              mainInput
              name="formSubmitButton"
              title="Submit button"
              ctx={this}
            />
          </div>
          <Input name="redirectUrl" ctx={this} placeholder="redirect url" mainInput />
          <div className="leadpages-integration-form__copy-button-block">
            {generatingTokenProcess && generatingTokenProcess.status === 'loading' ? (
              <Loader color="mainBlue" />
            ) : (
              <CopyToClipboard
                buttonText="Copy form"
                beforeCopy={this.onCopy}
                text={this.state.form.redirectUrl}
              />
            )}
          </div>
          <Input name="name" ctx={this} placeholder="name of integration" mainInput />
          <ErrorMessage>{status.non_field_errors}</ErrorMessage>
        </div>
        <div className="leadpages-integration-form__buttons-block">
          <Button color="filledGrey" onClick={() => history.push('/main/integrations')}>
            Cancel
          </Button>
          <Button
            status={networkProcess && networkProcess.status}
            onClick={() => this.submitHandler(integrationId)}
          >
            {integrationId ? 'Save' : 'Integrate'}
          </Button>
        </div>
      </div>
    );
  }
}
