import React from 'react';
import types from 'prop-types';

// Components

import {
  CopyToClipboard,
  ErrorMessage,
  Heading,
  Loader,
  Button,
  Input,
  Text
} from '../../../components';
import Form from '../../core/Form';

// Modules

import history from '../../../modules/history';
import generateCustomForm from '../../../modules/generateCustomForm';

// Styles

import './styles.scss';

// ----------------

export default class CustomIntegrationForm extends Form {
  // Type of props

  static propTypes = {
    generatingTokenProcess: types.object,
    networkProcess: types.object,
    integrationId: types.oneOfType([types.string, types.number]),
    className: types.string,
    style: types.object
  };

  // Default value for props

  static defaultProps = { className: '' };

  constructor(props) {
    super(props);

    const data = props.data || {};

    // State of component

    this.state = {
      form: {
        ...(!this.props.integrationId && { token: data.token || '' }),
        type: data.type || 'Custom',
        name: data.name || '',
        webHookUrl: data.webHookUrl || data.url || ''
      }
    };

    // Validation rules

    this.commonRules = {
      optional: true
    };

    this.rules = {
      name: { optional: false }
    };

    // Bind methods

    this.onCopy = this.onCopy.bind(this);

    // Init form

    this.init();
  }

  // -------- Methods --------

  onCopy() {
    const { webHookUrl } = this.state.form;

    return generateCustomForm(webHookUrl);
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
    if (prevProps.data !== this.props.data) {
      const { data } = this.props;
      this.setState(prevState => ({
        form: {
          ...(!this.props.integrationId && { token: data.token || '' }),
          type: data.type || 'Custom',
          name: data.name || '',
          webHookUrl: data.webHookUrl || data.url || ''
        }
      }));
    }
  }

  // Render

  render() {
    const { className, style, networkProcess, integrationId, generatingTokenProcess } = this.props;
    const { status } = this.state;

    return (
      <div className={`custom-integration-form ${className}`} style={style}>
        <div className="custom-integration-form__upper-block">
          <Heading type="h5" color="blue" withMargin capitalize>
            Get ready!
          </Heading>
          <Text type="small" center withMargin>
            Use this API url to integrate with your existing form:
          </Text>
          <Input
            customSideBlockAlignment="right"
            customSideBlock={
              generatingTokenProcess && generatingTokenProcess.status === 'loading' ? (
                <Loader color="mainBlue" size="small" />
              ) : (
                <CopyToClipboard isIcon text={this.state.form.webHookUrl} />
              )
            }
            placeholder="api url"
            style={{ paddingRight: '18px' }}
            mainInput
            readOnly
            name="webHookUrl"
            ctx={this}
          />
          <Text type="small" center withMargin style={{ marginBottom: '35px' }}>
            Or copy generated code with HTML form to embed to your landing page:
          </Text>
          <div className="custom-integration-form__copy-button-block">
            {generatingTokenProcess && generatingTokenProcess.status === 'loading' ? (
              <Loader color="mainBlue" />
            ) : (
              <CopyToClipboard
                buttonText="Copy form"
                beforeCopy={this.onCopy}
                text={this.state.form.redirectUrl}
              />
            )}
          </div>
          <Input name="name" ctx={this} placeholder="name of integration" mainInput />
          <ErrorMessage>{status.non_field_errors}</ErrorMessage>
        </div>
        <div className="custom-integration-form__buttons-block">
          <Button color="filledGrey" onClick={() => history.push('/main/integrations')}>
            Cancel
          </Button>
          <Button
            status={networkProcess && networkProcess.status}
            onClick={() => this.submitHandler(integrationId)}
          >
            {integrationId ? 'Save' : 'Integrate'}
          </Button>
        </div>
      </div>
    );
  }
}
