import React from 'react';
import types from 'prop-types';
import ReactSVG from 'react-svg';

// Styles

import './styles.scss';

// Icons

import emptyIcon from '../../../static/img/empty-folder.svg';

// ----------------

// Type of props

Empty.propTypes = {
  className: types.string,
  style: types.object
};

// Default value for props

Empty.defaultProps = {
  className: ''
};

// ----------------

export default function Empty(props) {
  const { className, style, emptyText } = props;

  return (
    <div className={`empty-content ${className}`} style={style}>
      <div className="empty-content__icon">
        <ReactSVG src={emptyIcon} />
      </div>
      <div className="empty-content__text">{emptyText || 'There is no content yet'}</div>
    </div>
  );
}
