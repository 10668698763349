import React from 'react';
import types from 'prop-types';

// Components

import { Sidebar, MainContentStruct } from '../../../components';

// Styles

import './styles.scss';

// ----------------

// Type of props

MainStruct.propTypes = {
  toggleSidebar: types.func,
  isSidebarOpen: types.bool,
  closeSidebar: types.func,
  isSuperUser: types.bool,
  className: types.string,
  onLogout: types.func,
  children: types.node,
  style: types.object
};

// Default value for props

MainStruct.defaultProps = {
  className: ''
};

// ----------------

export default function MainStruct(props) {
  const {
    hasSubscribeAccess,
    toggleSidebar,
    isSidebarOpen,
    closeSidebar,
    isSuperUser,
    className,
    children,
    onLogout,
    style
  } = props;

  return (
    <div className={`main-structure ${className}`} style={style}>
      <Sidebar
        hasSubscribeAccess={hasSubscribeAccess}
        isSuperUser={isSuperUser}
        toggleSidebar={toggleSidebar}
        collapsed={!isSidebarOpen}
        onLogout={onLogout}
        closeSidebar={closeSidebar}
      />
      <MainContentStruct>{children}</MainContentStruct>
    </div>
  );
}
