import * as TYPES from '../actionTypes/leads';
import * as URL from '../../constants/endpoints';
import query from 'querystringify';

// Modules

import history from './../../modules/history';

// Action creators

import { toggleModal } from '../actionCreators/ui';

// ----------------

// Fetch leads

export const fetchLeads = () => ({
  before: () => {
    const queryObj = query.parse(history.location.search);
    return query.stringify(
      {
        ...(queryObj.status && { status: queryObj.status }),
        ...(queryObj.source && { source: queryObj.source }),
        ...(queryObj.orderDir && { orderDir: queryObj.orderDir }),
        ...(queryObj.orderField && { orderField: queryObj.orderField }),
        ...(queryObj.endDate && { endDate: queryObj.endDate }),
        ...(queryObj.startDate && { startDate: queryObj.startDate }),
        offset: +queryObj.page > 1 ? (+queryObj.page - 1) * 10 : 0
      },
      true
    );
  },
  networkType: TYPES.FETCH_LEADS_NETWORK,
  endpoint: query => URL.FETCH_LEADS + query
});

// Fetch lead by id

export const fetchLeadById = payload => ({
  networkType: TYPES.FETCH_LEAD_BY_ID_NETWORK,
  endpoint: query => `${URL.LEAD_WITH_ID}/${payload.id}`
});

// Add new lead

export const addNewLead = payload => ({
  networkType: TYPES.ADD_NEW_LEAD_NETWORK,
  endpoint: query => URL.ADD_LEAD,
  payload,
  method: 'POST',
  then: (data, dispatch) => {
    dispatch(
      toggleModal({
        name: 'addNewLeadConfirmModal',
        data
      })
    );
  }
});

// Edit lead

export const editLead = (payload, callBack, leadId) => ({
  networkType: TYPES.EDIT_LEAD_NETWORK,
  endpoint: query => `${URL.LEAD_WITH_ID}/${leadId}`,
  payload,
  method: 'PUT',
  then: (data, dispatch) => {
    dispatch(
      toggleModal({
        name: 'addNewLeadConfirmModal',
        data
      })
    );
  }
});

// Delete lead

export const deleteLead = payload => ({
  networkType: TYPES.DELETE_LEAD_NETWORK,
  endpoint: query => `${URL.LEAD_WITH_ID}/${payload.id}`,
  payload: {
    ...payload,
    status: 'Archived'
  },
  leadId: payload.id,
  leadStatus: 'Archived',
  method: 'PUT',
  then: (data, dispatch) => {
    dispatch(
      toggleModal({
        name: 'deleteLeadConfirmModal',
        data: {
          message: 'The lead has been successfully deleted.'
        }
      })
    );
  }
});

// Download csv file

export const downloadLeadsCSV = () => ({
  before: getState => {
    const limit = getState().leads.count;
    const queryObj = query.parse(history.location.search);
    return query.stringify(
      {
        ...(queryObj.status && { status: queryObj.status }),
        ...(queryObj.source && { source: queryObj.source }),
        ...(queryObj.orderDir && { orderDir: queryObj.orderDir }),
        ...(queryObj.orderField && { orderField: queryObj.orderField }),
        ...(queryObj.endDate && { endDate: queryObj.endDate }),
        ...(queryObj.startDate && { startDate: queryObj.startDate }),
        limit
      },
      true
    );
  },
  networkType: TYPES.DOWNLOAD_LEADS_CSV_NETWORK,
  endpoint: query => URL.DOWNLOAD_LEADS_CSV + query,
  fileName: 'leads.csv'
});
