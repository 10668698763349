// Default branch state

export const DEFAULT_BRANCH_STATE = '@ezylead/DEFAULT_BRANCH_STATE_LEADS';

// Fetch leads

export const FETCH_LEADS_NETWORK = '@ezylead/FETCH_LEADS_NETWORK';

// Fetch lead by id

export const FETCH_LEAD_BY_ID_NETWORK = '@ezylead/FETCH_LEAD_BY_ID_NETWORK';

// Add new lead

export const ADD_NEW_LEAD_NETWORK = '@ezylead/ADD_NEW_LEAD_NETWORK';

// Edit  lead

export const EDIT_LEAD_NETWORK = '@ezylead/EDIT_LEAD_NETWORK';

// Delete  lead

export const DELETE_LEAD_NETWORK = '@ezylead/DELETE_LEAD_NETWORK';

// Download CSV file

export const DOWNLOAD_LEADS_CSV_NETWORK = '@ezylead/DOWNLOAD_LEADS_CSV_NETWORK';
