import React from 'react';
import query from 'querystringify';
import { connect } from 'react-redux';

// Components

import { Page, AuthFormStruct, ResetPasswordForm, Modal } from '../../../components';

// Modules

import history from '../../../modules/history';

// Action types

import { RESET_PASSWORD_NETWORK } from '../../../store/actionTypes/user';

// Action creators

import { resetPassword, checkResetPasswordToken } from '../../../store/actionCreators/user';
import { toggleModal } from '../../../store/actionCreators/ui';

// ----------------

class ResetPasswordPage extends Page {
  // Route (Page) config

  static title = 'Reset Password';
  static path = '/auth/reset';
  static queryForAccess = ['token'];

  constructor(props) {
    super(props);

    // State

    this.state = {
      token: query.parse(this.props.location.search).token
    };

    // Data

    this.mount = [{ ac: () => checkResetPasswordToken(this.state.token) }];

    // Bind methods

    this.pageContent = this.pageContent.bind(this);
  }

  // -------- Methods --------

  onConfirmModal = () => {
    history.push('/auth/login');
  };

  // Content of page (Route)

  pageContent() {
    const { networkProcess, resetPassword, modalData, onModalClose } = this.props;
    const { token } = this.state;

    return (
      <AuthFormStruct small title="Reset Password">
        <ResetPasswordForm
          networkProcess={networkProcess}
          submitAction={resetPassword}
          token={token}
        />
        {!!modalData && (
          <Modal
            data={modalData}
            onClose={modalData.isError ? this.onConfirmModal : onModalClose}
            onConfirm={this.onConfirmModal}
            popupTitle={modalData.isError ? null : 'Success'}
            error={modalData.isError}
            confirmation={!modalData.isError}
          />
        )}
      </AuthFormStruct>
    );
  }

  // Render

  render() {
    return this.securRenderPage(this.pageContent);
  }
}

export default connect(
  state => ({
    networkProcess: state.network[RESET_PASSWORD_NETWORK],
    modalData: state.ui.modal.resetPasswordConfirmModal
  }),
  {
    onModalClose: () => toggleModal({ name: 'resetPasswordConfirmModal' }),
    resetPassword
  }
)(ResetPasswordPage);
