import React from 'react';
import { connect } from 'react-redux';

// Modules

import history from '../../../../modules/history';

// Components

import {
  FacebookIntegrationForm,
  PageWrapper,
  CenterBlock,
  PageTitle,
  Button,
  Loader,
  Text,
  Page
} from '../../../../components';
import withFacebookApi from '../../../../components/hoc/withFacebookAPI';

// Action creators

import {
  fetchIntegrationById,
  addNewIntegration,
  editIntegration
} from '../../../../store/actionCreators/integrations';

// Action types

import {
  ADD_NEW_INTEGRATION_NETWORK,
  EDIT_INTEGRATION_NETWORK
} from '../../../../store/actionTypes/integrations';

// ----------------

class FacebookIntegrationPage extends Page {
  // Route config

  static title = 'Facebook integration';
  static path = '/main/(new-integration|edit-integration)/facebook/:id?';
  static exactPath = true;
  static accessWithSubscription = true;

  // Data

  mount = this.props.match.params.id && [
    {
      ac: fetchIntegrationById,
      payload: { id: this.props.match.params.id },
      find: { key: 'id', value: this.props.match.params.id },
      mapState: ['integrations', 'integrations']
    }
  ];

  // Bind methods

  pageContent = this.pageContent.bind(this);

  // -------- Methods --------

  // Content of route

  pageContent() {
    const integrationId = this.props.match.params.id;
    const {
      addNewIntegration,
      facebookUserInfo,
      getFacebookForms,
      editIntegration,
      getFacebookAds,
      networkProcess,
      facebookData,
      integrations,
      onLogIn
    } = this.props;

    return (
      <PageWrapper>
        <PageTitle>Facebook</PageTitle>
        <CenterBlock withPadding>
          {facebookData.isLoading && !facebookData.isAuth ? (
            <Loader color="mainBlue" />
          ) : facebookData.isAuth ? (
            <FacebookIntegrationForm
              facebookUserInfo={facebookUserInfo}
              getFacebookAds={getFacebookAds}
              getFacebookForms={getFacebookForms}
              facebookData={facebookData}
              integrationId={integrationId}
              networkProcess={networkProcess}
              submitAction={integrationId ? editIntegration : addNewIntegration}
              data={integrationId && integrations.find(lead => lead.id === +integrationId)}
            />
          ) : (
            <>
              <Text center withLargeMargin>
                To continue you need to login with facebook
              </Text>
              <Button onClick={onLogIn} style={{ marginBottom: '25px' }}>
                Log in
              </Button>
              <Button color="filledGrey" onClick={() => history.push('/main/integrations')}>
                Back to integrations
              </Button>
            </>
          )}
        </CenterBlock>
      </PageWrapper>
    );
  }
  // Render

  render() {
    return this.securRenderPage(this.pageContent);
  }
}

export default connect(
  state => ({
    addingIntegrationProcess: state.network[ADD_NEW_INTEGRATION_NETWORK],
    integrations: state.integrations.integrations,
    networkProcess:
      state.network[ADD_NEW_INTEGRATION_NETWORK] || state.network[EDIT_INTEGRATION_NETWORK]
  }),
  {
    addNewIntegration,
    editIntegration
  }
)(withFacebookApi(FacebookIntegrationPage));
