import React from 'react';

// Constants

import history from '../../../modules/history';

// Components

import { TermsAndConditionsBlock, AuthFormStruct, Button, Page } from '../../../components';

// ----------------

class TermsPage extends Page {
  // Page (Route) config

  static title = 'Terms and Conditions';
  static path = '/auth/terms';

  // -------- Methods --------

  // Render

  render() {
    return (
      <AuthFormStruct bodyStyle={{ overflow: 'auto', maxHeight: '450px' }}>
        <TermsAndConditionsBlock color="white" />
        <Button
          style={{ minHeight: '50px', marginBottom: '30px' }}
          onClick={() => history.push('/auth/register')}
        >
          Back
        </Button>
      </AuthFormStruct>
    );
  }
}

export default TermsPage;
