import React from 'react';
import types from 'prop-types';
import classNames from 'classnames';

// Styles

import './styles.scss';

// ----------------

// Type of props

PageTitle.propTypes = {
  mobileHidden: types.bool,
  className: types.string,
  children: types.string,
  style: types.object
};

// Default value for props

PageTitle.defaultProps = {
  className: '',
  children: ''
};

// Modify styles

const modify = props =>
  classNames({
    'page-title--mobile-hidden': props.mobileHidden,
    'page-title--desktop-hidden': props.desktopHidden
  });

// ----------------

export default function PageTitle(props) {
  const { className, style, children, sideBlock: SideBlock } = props;

  return (
    <div className={`page-title ${className} ${modify(props)}`} style={style}>
      <h1 className="page-title__title">{children}</h1>
      <div className="page-title__side-block">{SideBlock}</div>
    </div>
  );
}
