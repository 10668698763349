// Default branch state

export const DEFAULT_BRANCH_STATE = '@ezylead/DEFAULT_BRANCH_STATE_USER';

// Login user

export const LOGIN_NETWORK = '@ezylead/LOGIN_NETWORK';

// Register user

export const REGISTER_NETWORK = '@ezylead/REGISTER_NETWORK';

// Verify account

export const VERIFY_ACCOUNT_NETWORK = '@ezylead/VERIFY_ACCOUNT_NETWORK';

// Logout

export const LOGOUT = '@ezylead/LOGOUT';

// Send email to reset password

export const FORGOT_PASSWORD_NETWORK = '@ezylead/FORGOT_PASSWORD_NETWORK';

// Reset password

export const RESET_PASSWORD_NETWORK = '@ezylead/RESET_PASSWORD_NETWORK';

// Fetch user info

export const FETCH_USER_INFO_NETWORK = '@ezylead/FETCH_USER_INFO_NETWORK';

// Update user info

export const UPDATE_USER_INFO_NETWORK = '@ezylead/UPDATE_USER_INFO_NETWORK';

// Change password

export const CHANGE_PASSWORD_NETWORK = '@ezylead/CHANGE_PASSWORD_NETWORK';

// Delete profile

export const DELETE_PROFILE_NETWORK = '@ezylead/DELETE_PROFILE_NETWORK';

// Fetch user's subscription

export const FETCH_USER_SUBSCRIPTION_NETWORK = '@ezylead/FETCH_USER_SUBSCRIPTION_NETWORK';

// Subscribe user

export const SUBSCRIBE_USER_NETWORK = '@ezylead/SUBSCRIBE_USER_NETWORK';

// Check token for reset password

export const CHECK_RESET_PASSWORD_TOKEN_NETWORK = '@ezylead/CHECK_RESET_PASSWORD_TOKEN_NETWORK';

// Support request

export const SUPPORT_REQUEST_NETWORK = '@ezylead/SUPPORT_REQUEST_NETWORK';
