import React from 'react';
import types from 'prop-types';
import moment from 'moment';
import ReactSVG from 'react-svg';

// Components

import { TableRow, IconButton } from '../../../../components';

// Styles

import './styles.scss';

// Icons

import editIcon from '../../../../static/img/edit.svg';
import deleteIcon from '../../../../static/img/delete.svg';

// ----------------

// Type of props

IntegrationRow.propTypes = {
  data: types.object,
  onEdit: types.func,
  onDelete: types.func
};

// ----------------

export default function IntegrationRow(props) {
  const { data, onEdit, onDelete } = props;

  return (
    <TableRow {...props}>
      {data.name}
      {data.type}
      {moment
        .utc(data.createdAt)
        .local()
        .format('D MMM YYYY')}
      {data.leadsCount}
      <>
        <IconButton
          title="Edit"
          onClick={() => onEdit(data)}
          style={{ marginRight: '15px' }}
          type="xsmall"
          color="grey"
        >
          <ReactSVG src={editIcon} />
        </IconButton>
        <IconButton
          title="Delete"
          onClick={() => onDelete(data)}
          style={{ marginLeft: '15px' }}
          type="xsmall"
          color="grey"
        >
          <ReactSVG src={deleteIcon} />
        </IconButton>
      </>
    </TableRow>
  );
}
