import React from 'react';
import { connect } from 'react-redux';

// Modules

import history from '../../../modules/history';

// Components

import {
  AddNewLeadForm,
  CenterBlock,
  PageWrapper,
  PageTitle,
  Modal,
  Page
} from '../../../components';

// Action creators

import { addNewLead, fetchLeadById, editLead } from '../../../store/actionCreators/leads';
import { toggleModal } from '../../../store/actionCreators/ui';

// Action types

import { ADD_NEW_LEAD_NETWORK, EDIT_LEAD_NETWORK } from '../../../store/actionTypes/leads';

// ----------------

class NewLeadPage extends Page {
  // Route config

  static title = 'Add New Lead';
  static path = '/main/(new-lead|edit-lead)/:id?';
  static exactPath = true;
  static accessWithSubscription = true;

  // Data

  mount = this.props.match.params.id && [
    {
      ac: fetchLeadById,
      payload: { id: this.props.match.params.id },
      find: { key: 'id', value: this.props.match.params.id },
      mapState: ['leads', 'leads']
    }
  ];
  pathUpdate = [() => fetchLeadById({ id: this.props.match.params.id })];

  // Bind methods

  pageContent = this.pageContent.bind(this);

  // -------- Methods --------

  // Content of route

  pageContent() {
    const leadId = this.props.match.params.id;
    const { networkProcess, onModalClose, addNewLead, modalData, editLead, leads } = this.props;

    return (
      <PageWrapper>
        <PageTitle>{leadId ? 'Edit Lead' : 'Add New Lead'}</PageTitle>
        <CenterBlock>
          <AddNewLeadForm
            leadId={leadId}
            data={leads.find(lead => lead.id === +leadId)}
            networkProcess={networkProcess}
            submitAction={leadId ? editLead : addNewLead}
          />
          {!!modalData && (
            <Modal
              data={modalData}
              onClose={onModalClose}
              popupTitle="Success"
              confirmation
              onConfirm={() => history.push('/main/leads')}
            />
          )}
        </CenterBlock>
      </PageWrapper>
    );
  }

  // Render

  render() {
    return this.securRenderPage(this.pageContent);
  }
}

export default connect(
  state => ({
    networkProcess: state.network[ADD_NEW_LEAD_NETWORK] || state.network[EDIT_LEAD_NETWORK],
    modalData: state.ui.modal.addNewLeadConfirmModal,
    leads: state.leads.leads
  }),
  {
    onModalClose: () => toggleModal({ name: 'addNewLeadConfirmModal' }),
    addNewLead,
    editLead
  }
)(NewLeadPage);
