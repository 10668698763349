import React from 'react';
import types from 'prop-types';
import ReactSVG from 'react-svg';
import classNames from 'classnames';

// Components

import { FieldWrapper } from '../../../components';

// Modules

import deleteProps from '../../../modules/deleteProps';

// Image and icons

import chevronIcon from '../../../static/img/chewron_down.svg';

// Styles

import './styles.scss';

// ----------------

// Type of props

Select.propTypes = {
  className: types.string,
  disabled: types.bool,
  options: types.array.isRequired,
  style: types.object,
  title: types.string,
  error: types.string,
  name: types.string.isRequired,
  ctx: types.object.isRequired,
  labelKey: types.string,
  valueKey: types.string,
  customSideBlock: types.node
};

// Default value for props

Select.defaultProps = {
  className: '',
  labelKey: 'label',
  valueKey: 'value'
};

// Modify styles

const modify = (props, error) =>
  classNames({
    'select__select--mobile-full-width': props.fullWidthMobile,
    'select__select--full-width': props.fullWidth,
    'select__select--main-input': props.mainInput,
    'select__select--error': error
  });

const modifyFieldWrapper = props =>
  classNames({
    'ui-field-wrapper__block--mobile-full-width': props.fullWidthMobile,
    'ui-field-wrapper__block--full-width': props.fullWidth
  });

// ----------------

export default function Select(props) {
  const {
    customSideBlock,
    className,
    style,
    title,
    error,
    name,
    ctx,
    options,
    placeholder,
    disabled,
    labelKey,
    valueKey
  } = props;

  return (
    <FieldWrapper
      title={title}
      error={error || ctx.state.status[name]}
      className={`${modifyFieldWrapper(props)} ${className}`}
      referer={ref => (ctx.formRefs[name] = ref)}
      style={style}
    >
      <div className="select__wrapper">
        <select
          className={`select__select ${modify(props, error || ctx.state.status[name])}`}
          onChange={ctx.changeHandler}
          disabled={disabled}
          onFocus={ctx.focusHandler}
          value={ctx.state.form[name]}
          {...deleteProps(props, [
            'fullWidthMobile',
            'customSideBlock',
            'className',
            'fullWidth',
            'options',
            'valueKey',
            'labelKey',
            'mainInput',
            'title',
            'style',
            'error',
            'ctx'
          ])}
        >
          <option value="" disabled hidden>
            {placeholder}
          </option>
          {options.map(option => (
            <option key={option[valueKey]} value={option[valueKey]}>
              {option[labelKey]}
            </option>
          ))}
        </select>
        <div className="select__side-block">
          {customSideBlock || <ReactSVG className="select__icon-wrapper" src={chevronIcon} />}
        </div>
      </div>
    </FieldWrapper>
  );
}
