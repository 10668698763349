// Default branch state

export const DEFAULT_BRANCH_STATE = '@ezylead/DEFAULT_BRANCH_STATE_ACCOUNTS';

// Fetch accounts

export const FETCH_ACCOUNTS_NETWORK = '@ezylead/FETCH_ACCOUNTS_NETWORK';

// Fetch account by id

export const FETCH_ACCOUNT_BY_ID_NETWORK = '@ezylead/FETCH_ACCOUNT_BY_ID_NETWORK';

// Delete account

export const DELETE_ACCOUNT_NETWORK = '@ezylead/DELETE_ACCOUNT_NETWORK';

// Restore account

export const RESTORE_ACCOUNT_NETWORK = '@ezylead/RESTORE_ACCOUNT_NETWORK';

// Edit account

export const EDIT_ACCOUNT_NETWORK = '@ezylead/EDIT_ACCOUNT_NETWORK';

// Download CSV file

export const DOWNLOAD_ACCOUNTS_CSV_NETWORK = '@ezylead/DOWNLOAD_ACCOUNTS_CSV_NETWORK';
