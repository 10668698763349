import * as BASE_TYPES from '../actionTypes/base';
import * as TYPES from '../actionTypes/ui';

// -------- Default state for branch --------

const defaultState = {
  modal: {},
  isSidebarOpen: false,
  toastNotifications: [],
  savedForm: {}
};

// -------- Reducer --------

export default function(state, action) {
  if (!state) return defaultState;

  switch (action.type) {
    case TYPES.TOGGLE_MODAL: {
      if (state.modal[action.payload.name]) {
        return { ...state, modal: { ...state.modal, [action.payload.name]: undefined } };
      }
      return {
        ...state,
        modal: { ...state.modal, [action.payload.name]: action.payload.data || true }
      };
    }

    case TYPES.TOGGLE_SIDEBAR: {
      return { ...state, isSidebarOpen: !state.isSidebarOpen };
    }

    case TYPES.ADD_TOAST_NOTIFICATION: {
      const toastNotification = {
        ...action.payload,
        id: action.payload.id || state.toastNotifications.length + Date.now()
      };
      return { ...state, toastNotifications: [...state.toastNotifications, toastNotification] };
    }

    case TYPES.REMOVE_TOAST_NOTIFICATION: {
      const removedId = action.payload;
      const toastNotifications = state.toastNotifications.filter(item => item.id !== removedId);
      return { ...state, toastNotifications };
    }

    case TYPES.SAVE_FORM: {
      return { ...state, savedForm: action.payload };
    }

    case TYPES.CLEAR_SAVED_FORM: {
      return {
        ...state,
        savedForm: {
          ...state.savedForm,
          [action.payload.name]: {}
        }
      };
    }

    case TYPES.DEFAULT_BRANCH_STATE: {
      return defaultState;
    }

    case BASE_TYPES.DEFAULT_STATE: {
      return defaultState;
    }

    default:
      return state;
  }
}
