import React, { useState } from 'react';
import types from 'prop-types';
import classNames from 'classnames';
import SmoothCollapse from 'react-smooth-collapse';

// Styles

import './styles.scss';

// ----------------

// Type of props

CollapsedItem.propTypes = {
  headerTitle: types.string,
  withBorder: types.bool,
  className: types.string,
  children: types.any,
  style: types.object,
  number: types.number
};

// Default value for props

CollapsedItem.defaultProps = {
  className: ''
};

// Modify styles

const modifyCollapsedItem = props =>
  classNames({
    'collapsed-item--with-border': props.withBorder
  });

// ----------------

export default function CollapsedItem(props) {
  const [state, setState] = useState({ expanded: !!props.expanded });
  const { className, style, headerTitle, children, number } = props;
  const { expanded } = state;

  const toggleExpandedBlock = () => setState({ expanded: !state.expanded });

  return (
    <div className={`collapsed-item ${className} ${modifyCollapsedItem(props)}`} style={style}>
      <div className="collapsed-item__header" onClick={toggleExpandedBlock}>
        <p className="collapsed-item__header-title">
          {number && <span className="collapsed-item__header-number">{number}</span>}
          {headerTitle}
        </p>
        <div className="collapsed-item__collapse-icon-wrapper">
          <div className="collapsed-item__collapse-icon">{expanded ? '-' : '+'}</div>
        </div>
      </div>
      <SmoothCollapse expanded={expanded} className="collapsed-item__collapse-wrapper">
        <div className="collapsed-item__content">{children}</div>
      </SmoothCollapse>
    </div>
  );
}
