import * as TYPES from '../actionTypes/accounts';
import * as URL from '../../constants/endpoints';
import query from 'querystringify';

// Modules

import history from '../../modules/history';

// Action creators

import { toggleModal } from '../actionCreators/ui';

// ----------------

// Fetch accounts

export const fetchAccounts = () => ({
  before: () => {
    const queryObj = query.parse(history.location.search);
    return query.stringify(
      {
        page: +queryObj.page > 1 ? queryObj.page : 1,
        limit: 10
      },
      true
    );
  },
  networkType: TYPES.FETCH_ACCOUNTS_NETWORK,
  endpoint: query => URL.ACCOUNTS + query
});

// Fetch account by id

export const fetchAccountById = payload => ({
  networkType: TYPES.FETCH_ACCOUNT_BY_ID_NETWORK,
  endpoint: query => `${URL.ACCOUNTS}/${payload.id}`
});

// Delete account

export const deleteAccount = payload => ({
  networkType: TYPES.DELETE_ACCOUNT_NETWORK,
  endpoint: query => URL.DELETE_ACCOUNT + payload,
  method: 'DELETE',
  accountId: payload,
  then: (data, dispatch) => {
    dispatch(
      toggleModal({
        name: 'accountActionSuccesModal',
        data
      })
    );
  }
});

// Restore account

export const restoreAccount = payload => ({
  networkType: TYPES.RESTORE_ACCOUNT_NETWORK,
  endpoint: query => URL.RESTORE_ACCOUNT + payload,
  accountId: payload,
  then: (data, dispatch) => {
    dispatch(
      toggleModal({
        name: 'accountActionSuccesModal',
        data
      })
    );
  }
});

// Edit account

export const editAccount = (payload, callBack, accountId) => ({
  networkType: TYPES.EDIT_ACCOUNT_NETWORK,
  endpoint: query => `${URL.ACCOUNTS}/${accountId}`,
  payload,
  method: 'PUT',
  then: () => {
    history.push('/main/accounts');
  }
});

// Download csv file

export const downloadAccountsCSV = () => ({
  before: getState => {
    const limit = getState().accounts.count;
    return query.stringify({ limit }, true);
  },
  networkType: TYPES.DOWNLOAD_ACCOUNTS_CSV_NETWORK,
  endpoint: query => URL.DOWNLOAD_ACCOUNTS_CSV + query,
  fileName: 'accounts.csv'
});
