import classNames from 'classnames';
import ReactDOM from 'react-dom';
import React from 'react';
import types from 'prop-types';
import ReactSVG from 'react-svg';

// Components

import { Outside, Button, Heading } from '../../../components';

// Styles

import './styles.scss';

// Icons

import confirmIcon from '../../../static/img/round-done-button.svg';
import warningIcon from '../../../static/img/warning.svg';
import errorIcon from '../../../static/img/error.svg';
import closeIcon from '../../../static/img/close.svg';

// ----------------

// Type of props

Modal.propTypes = {
  isAdaptiveHeight: types.bool,
  popupTitle: types.string,
  className: types.string,
  children: types.node,
  onClose: types.func.isRequired,
  onConfirm: types.func,
  onCancel: types.func,
  style: types.object,
  data: types.object,
  messageKey: types.string,
  confirmButtonTitle: types.string,
  cancelButtonTitle: types.string,
  withCancel: types.bool,
  warning: types.bool,
  confirmation: types.bool,
  error: types.bool,
  confirmButtonColor: types.string
};

// Default value for props

Modal.defaultProps = {
  className: '',
  messageKey: 'message'
};

// Modify styles

const modalModify = props =>
  classNames({
    'modal__modal--adaptive-height': props.isAdaptiveHeight
  });

const modalBodyModify = props =>
  classNames({
    [`modal__modal-body--${props.bodyTextStyle}`]: props.bodyTextStyle
  });

const modalFooterModify = props =>
  classNames({
    'modal__modal-footer--with-cancel': props.withCancel
  });

// ----------------

const onConfirmHandler = (onClose, onConfirm, data) => () => {
  onClose();
  onConfirm(data);
};

export default function Modal(props) {
  const {
    confirmButtonColor,
    confirmButtonTitle,
    cancelButtonTitle,
    networkProcess,
    confirmation,
    popupTitle,
    withCancel,
    messageKey,
    onConfirm,
    className,
    onClose,
    warning,
    style,
    data,
    error
  } = props;

  return ReactDOM.createPortal(
    <div className={`modal__window-wrapper ${className}`}>
      <div className="modal__modal-margin-wrapper" style={style}>
        <Outside onOutsideClick={onClose}>
          <div className={`modal__modal ${modalModify(props)}`}>
            <div className="modal__modal-close-icon" onClick={onClose}>
              <ReactSVG src={closeIcon} />
            </div>
            <div className="modal__modal-header">
              {confirmation && (
                <div className="modal__modal-header-icon modal__modal-header-icon--success">
                  <ReactSVG src={confirmIcon} />
                </div>
              )}
              {warning && (
                <div className="modal__modal-header-icon modal__modal-header-icon--warning">
                  <ReactSVG src={warningIcon} />
                </div>
              )}
              {error && (
                <div className="modal__modal-header-icon modal__modal-header-icon--warning">
                  <ReactSVG src={errorIcon} />
                </div>
              )}
              <Heading color="darkBlue" type="h1">
                {popupTitle}
              </Heading>
            </div>
            <div className={`modal__modal-body ${modalBodyModify(props)}`}>
              {data && messageKey && data[messageKey]}
              {!/[!?.]$/.test(data[messageKey]) && '.'}
            </div>
            <div className={`modal__modal-footer ${modalFooterModify(props)}`}>
              {withCancel && (
                <Button style={{ maxWidth: '132px' }} onClick={onClose} type="filledGrey">
                  {cancelButtonTitle || 'cancel'}
                </Button>
              )}
              {onConfirm ? (
                <Button
                  style={{ maxWidth: '132px' }}
                  onClick={onConfirmHandler(onClose, onConfirm, data)}
                  color={confirmButtonColor}
                  status={networkProcess && networkProcess.status}
                >
                  {confirmButtonTitle || 'ok'}
                </Button>
              ) : (
                <Button style={{ maxWidth: '132px' }} onClick={onClose}>
                  ok
                </Button>
              )}
            </div>
          </div>
        </Outside>
      </div>
    </div>,
    document.body
  );
}
