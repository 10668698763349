import React from 'react';
import types from 'prop-types';
import deleteProps from '../../modules/deleteProps';

export default function withTimeout(Component) {
  return class TimeoutHOC extends React.Component {
    static propTypes = {
      timeoutCallBack: types.func.isRequired,
      timeout: types.number
    };

    static defaultProps = {
      timeout: 5000
    };

    static displayName = `TimeoutHOC(${Component.name || 'Component'})`;

    timeoutId = null;

    // -------- Methods --------

    // Life cycle. Did mount

    componentDidMount() {
      const { timeout, timeoutCallBack } = this.props;
      this.timeoutId = setTimeout(timeoutCallBack, timeout);
    }

    // Life cycle. Will unmount

    componentWillUnmount() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
    }

    // Render

    render() {
      return <Component {...deleteProps(this.props, ['timeout', 'timeoutCallBack'])} />;
    }
  };
}
