import React from 'react';
import ReactSVG from 'react-svg';

// Components

import { Button } from '../../../components';

// Modules

import history from '../../../modules/history';

// Styles

import './styles.scss';

// Icons

import logoIcon from '../../../static/img/logo.svg';

// ----------------

export default function Error404() {
  return (
    <div className="error-404">
      <ReactSVG className="error-404__icon-wrapper" src={logoIcon} />
      <p className="error-404__title">404 Error</p>
      <p className="error-404__description">Page not found</p>
      <div className="error-404__back-button">
        <Button
          onClick={() => {
            history.replace('/');
          }}
        >
          Back to homepage
        </Button>
      </div>
    </div>
  );
}
