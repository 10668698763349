import React from 'react';
import types from 'prop-types';
import classNames from 'classnames';

// Styles

import './styles.scss';

// ----------------

// Type of props

ErrorMessage.propTypes = {
  withoutTransform: types.bool,
  withMargin: types.bool,
  fullWidth: types.bool,
  className: types.string,
  style: types.object
};

// Default value for props

ErrorMessage.defaultProps = {
  className: ''
};

// Modify styles

const modify = props =>
  classNames({
    [`error-message--${props.type}`]: props.type,
    'error-message--with-margin': props.withMargin,
    'error-message--without-transform': props.withoutTransform
  });

// ----------------

export default function ErrorMessage(props) {
  const { className, style, children } = props;

  return (
    <div className={`error-message ${modify(props)} ${className}`} style={style}>
      {children}
    </div>
  );
}
