import { combineReducers } from 'redux';

// Reducers

import integrations from './integrations';
import accounts from './accounts';
import network from './network';
import leads from './leads';
import user from './user';
import ui from './ui';

// ----------------

export default combineReducers({
  integrations,
  accounts,
  network,
  leads,
  user,
  ui
});
