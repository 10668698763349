import React from 'react';
import types from 'prop-types';

// Components

import { QueryLink } from '../../../';

// Styles

import './styles.scss';

// ----------------

// Type of props

BaseTabsHeader.propTypes = {
  activeTabIndex: types.number,
  className: types.string,
  style: types.object,
  tabs: types.array.isRequired,
  name: types.string
};

// Default value for props

BaseTabsHeader.defaultProps = {
  activeTabIndex: 0,
  className: '',
  tabs: []
};

// ----------------

export default function BaseTabsHeader(props) {
  const { className, style, tabs, name, activeTabIndex } = props;

  return (
    <div className={`base-tabs-header ${className}`} style={style}>
      <div className="base-tabs-header__tab-list">
        {tabs.map((tab, index) => (
          <div
            key={tab.queryName}
            className={`base-tabs-header__tab ${
              activeTabIndex === index ? 'base-tabs-header__tab--active' : ''
            }`}
          >
            <QueryLink query={{ [name]: tab.queryName }} deleteQuery={tab.deleteQuery}>
              <p className="base-tabs-header__tab-title base-tabs-header__tab-title">
                {tab.displayName}
              </p>
            </QueryLink>
          </div>
        ))}
      </div>
    </div>
  );
}
