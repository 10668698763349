import React from 'react';
import types from 'prop-types';
import query from 'querystringify';

// Constants

import history from '../../../modules/history';

// Components

import { Select, DatePicker, Loader } from '../../../components';
import Form from '../../core/Form';

// Modules

import addQuery from '../../../modules/addQuery';

// Styles

import './styles.scss';

// ----------------

// Data for selects

const statusOptions = [
  { label: 'All', value: 'all' },
  { label: 'New', value: 'new' },
  { label: 'Processed', value: 'processed' },
  { label: 'Scheduled', value: 'scheduled' },
  { label: 'Missed', value: 'missed' },
  { label: 'Archived', value: 'archived' }
];

// ----------------

class PatientsSearchForm extends Form {
  // Type of props

  static propTypes = {
    integrationNames: types.array,
    networkProcess: types.object,
    className: types.string,
    style: types.object
  };

  // Default value for props

  static defaultProps = {
    className: ''
  };

  // -------- Methods --------

  constructor(props) {
    super(props);

    // State of component

    const queryObj = query.parse(history.location.search);

    this.state = {
      form: {
        status: queryObj.status || '',
        source: queryObj.source || '',
        startDate: queryObj.startDate || '',
        endDate: queryObj.endDate || ''
      }
    };

    // Init form

    this.init();
  }

  changeCallBack(source) {
    const { form } = this.state;

    if (form[source] === 'all' || !form[source]) {
      addQuery({}, [source], false, true);
    } else {
      addQuery({ [source]: form[source] }, false, false, true);
    }
  }

  // Render

  render() {
    const { className, style, networkProcess, integrationNames } = this.props;

    return (
      <div className={`leads-filter-form ${className}`} style={style}>
        <Select placeholder="Status" options={statusOptions} name="status" ctx={this} />
        <Select
          placeholder="Source"
          options={integrationNames}
          name="source"
          ctx={this}
          customSideBlock={
            networkProcess &&
            networkProcess.status === 'loading' && <Loader size="small" color="mainBlue" />
          }
          labelKey="name"
          valueKey="name"
        />
        <DatePicker withIcon selectStyle name="startDate" ctx={this} placeholder="From" mainInput />
        <DatePicker withIcon selectStyle name="endDate" ctx={this} placeholder="To" mainInput />
      </div>
    );
  }
}

export default PatientsSearchForm;
