import React from 'react';
import { connect } from 'react-redux';

// Components

import {
  IntegrationRow,
  PageWrapper,
  CenterBlock,
  PageTitle,
  Loader,
  Button,
  Table,
  Modal,
  Page,
  Text
} from '../../../components';

// Modules

import history from '../../../modules/history';

// Action creators

import { fetchIntegrations, deleteIntegration } from '../../../store/actionCreators/integrations';
import { toggleModal } from '../../../store/actionCreators/ui';

// Action types

import { FETCH_INTEGRATIONS_NETWORK } from '../../../store/actionTypes/integrations';

// ----------------
class IntegrationsPage extends Page {
  static title = 'Integrations';
  static path = '/main/integrations';
  static exactPath = true;
  static accessWithSubscription = true;

  // Bind methods

  pageContent = this.pageContent.bind(this);

  // Data

  mount = [{ ac: fetchIntegrations }];
  pathUpdate = [fetchIntegrations];

  // -------- Methods --------

  // Content of route

  pageContent() {
    const {
      onDeleteModalClose,
      onDeleteModalOpen,
      deleteIntegration,
      deleteModalData,
      networkProcess,
      integrations,
      pages
    } = this.props;

    return (
      <PageWrapper>
        <PageTitle
          sideBlock={
            integrations && integrations.length ? (
              <Button type="secondary" onClick={() => history.push('/main/new-integration')}>
                + new integration
              </Button>
            ) : null
          }
        >
          Integrations
        </PageTitle>
        {networkProcess && networkProcess.status === 'loading' ? (
          <Loader stretch color="mainBlue" />
        ) : integrations && integrations.length ? (
          <Table
            columns={[
              {
                label: 'Name',
                width: 25,
                sort: 'name'
              },
              {
                label: 'Type',
                width: 25,
                sort: 'type'
              },
              {
                label: 'Date',
                width: 25,
                sort: 'createdAt'
              },
              {
                label: 'Leads',
                width: 15,
                sort: 'leadsCount',
                style: { flex: 1 }
              },
              {
                label: 'Actions',
                style: { width: '65px', textAlign: 'left' }
              }
            ]}
            row={IntegrationRow}
            networkProcess={networkProcess}
            list={integrations}
            pages={pages}
            rowProps={{
              onEdit: data =>
                history.push(`/main/edit-integration/${data.type.toLowerCase()}/${data.id}`),
              onDelete: data => onDeleteModalOpen(data)
            }}
          />
        ) : (
          <CenterBlock withLargePadding>
            <Text center withLargeMargin>
              Oops, looks like you didn't set anything yet, your leads are waiting for you. Click on
              the button below to add a new integration.
            </Text>
            <Button autoWidth onClick={() => history.push('/main/new-integration')}>
              + new integration
            </Button>
          </CenterBlock>
        )}
        {!!deleteModalData && (
          <Modal
            onConfirm={() => deleteIntegration(deleteModalData.integration.id)}
            onClose={onDeleteModalClose}
            data={deleteModalData}
            withCancel
            warning
          />
        )}
      </PageWrapper>
    );
  }

  // Render

  render() {
    return this.securRenderPage(this.pageContent);
  }
}

export default connect(
  state => ({
    networkProcess: state.network[FETCH_INTEGRATIONS_NETWORK],
    deleteModalData: state.ui.modal.deleteIntegrationModal,
    integrations: state.integrations.integrations,
    pages: state.integrations.pages
  }),
  {
    deleteIntegration,
    onDeleteModalClose: () => toggleModal({ name: 'deleteIntegrationModal' }),
    onDeleteModalOpen: integration =>
      toggleModal({
        name: 'deleteIntegrationModal',
        data: {
          integration,
          message:
            'Are you sure you want to delete this integration? All the data related to this integration will be deleted from the system.'
        }
      })
  }
)(IntegrationsPage);
