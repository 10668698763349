import * as TYPES from '../actionTypes/ui';

// ----------------

// Toggle modal

export const toggleModal = payload => ({
  payload,
  type: TYPES.TOGGLE_MODAL
});

// Toggle sidebar

export const toggleSidebar = () => ({
  type: TYPES.TOGGLE_SIDEBAR
});

// Add toast notification

export const addToastNotification = payload => ({
  payload,
  type: TYPES.ADD_TOAST_NOTIFICATION
});

// Remove toast notification

export const removeToastNotification = payload => ({
  payload,
  type: TYPES.REMOVE_TOAST_NOTIFICATION
});

// Save form

export const saveForm = payload => ({
  payload,
  type: TYPES.SAVE_FORM
});

// Clear form

export const clearForm = payload => ({
  payload,
  type: TYPES.CLEAR_SAVED_FORM
});
