import ReactDOM from 'react-dom';
import React from 'react';
import types from 'prop-types';

// Modules

import history from '../../../modules/history';

// Components

import { ToastNotificationsItem } from '../../../components';

// Styles

import './styles.scss';

// ----------------

// Type of props

ToastNotifications.propTypes = {
  removeToastNotification: types.func,
  notifications: types.array,
  className: types.string,
  style: types.object
};

// Default value for props

ToastNotifications.defaultProps = {
  className: ''
};

// ----------------

// Callback for <TimeoutHOC />

const timeoutCallBack = (callBack, id) => () => callBack(id);

// ClickHandler for <ToastNotificationsItem />

const onClickHandler = (callBack, item) => () => {
  callBack(item.id);
  item.url && history.push(item.url);
};

// ----------------

export default function ToastNotifications(props) {
  const { className, removeToastNotification, notifications, style } = props;

  return ReactDOM.createPortal(
    notifications && notifications.length ? (
      <div className={`toast-notifications ${className}`} style={style}>
        <div className="toast-notifications__container">
          {notifications.map(item => (
            <ToastNotificationsItem
              timeoutCallBack={timeoutCallBack(removeToastNotification, item.id)}
              onClick={onClickHandler(removeToastNotification, item)}
              onRemove={removeToastNotification}
              item={item}
              key={item.id}
            />
          ))}
        </div>
      </div>
    ) : null,
    document.body
  );
}
