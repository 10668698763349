import React from 'react';
import types from 'prop-types';

// Components

import { Button, Input, ErrorMessage, Text, Heading, Select, Loader } from '../../../components';
import Form from '../../core/Form';

// Modules

import history from '../../../modules/history';

// Styles

import './styles.scss';

// ----------------

export default class FacebookIntegrationForm extends Form {
  // Type of props

  static propTypes = {
    networkProcess: types.object,
    getFacebookAds: types.func,
    getFacebookForms: types.func,
    integrationId: types.oneOfType([types.string, types.number]),
    facebookError: types.string,
    facebookData: types.object,
    className: types.string,
    style: types.object
  };

  // Default value for props

  static defaultProps = { className: '' };

  constructor(props) {
    super(props);

    const data = props.data || {};

    // State of component

    this.state = {
      form: {
        token: 'abc',
        type: data.type || 'Facebook',
        facebookLeadPage: data.facebookLeadPage || '',
        facebookLeadAd: data.facebookLeadAd || '',
        fbAccessToken: props.facebookUserInfo.accessToken || '',
        name: data.name || '',
        facebookUserId: props.facebookUserInfo.userID
      }
    };

    // Validation rules

    this.commonRules = {
      optional: false
    };

    // Init form

    this.init();
  }

  // -------- Methods --------

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
    if (prevProps.data !== this.props.data) {
      const { data, facebookUserInfo } = this.props;
      this.setState(prevState => ({
        form: {
          token: 'facebook',
          type: data.type || 'Facebook',
          name: data.name || '',
          facebookLeadPage: data.facebookLeadPage || '',
          facebookLeadAd: data.facebookLeadAd || '',
          fbAccessToken: facebookUserInfo.accessToken || '',
          facebookUserId: facebookUserInfo.userID
        }
      }));
    }

    // After fetching facebook pages array we need to fetch facebook forms if we already have selected form

    if (
      prevProps.facebookData.pages.length !== this.props.facebookData.pages.length &&
      this.props.data &&
      this.props.data.facebookLeadAd
    ) {
      const { facebookLeadPage } = this.state.form;
      const { facebookData, getFacebookForms } = this.props;
      if (facebookLeadPage && !facebookData.forms.length) {
        getFacebookForms(facebookLeadPage);
      }
    }
  }

  changeCallBack(name) {
    if (name === 'facebookLeadPage' && this.props.getFacebookForms) {
      const id = this.state.form[name];
      this.props.getFacebookForms(id, true);
      this.setState({ form: { ...this.state.form, facebookLeadAd: '' } });
    }
  }

  // Render

  render() {
    const {
      networkProcess,
      facebookError,
      integrationId,
      facebookData,
      className,
      style
    } = this.props;
    const { status } = this.state;

    return (
      <div className={`facebook-integration-form ${className}`} style={style}>
        <div className="facebook-integration-form__upper-block">
          <Heading type="h5" color="blue" withMargin>
            Get ready!
          </Heading>
          <Text type="small" center withMargin>
            Create business page on Facebook Run your Facebook Lead ad
          </Text>
          <Select
            valueKey="id"
            labelKey="name"
            placeholder="Select the Facebook Page"
            options={facebookData.pages}
            customSideBlock={
              facebookData.isLoading &&
              !facebookData.pages.length && <Loader size="small" color="mainBlue" />
            }
            name="facebookLeadPage"
            ctx={this}
            mainInput
          />
          <Select
            valueKey="id"
            labelKey="name"
            placeholder={
              (facebookData.forms && facebookData.forms.length) || !this.state.form.facebookLeadPage
                ? 'Select the Facebook Form'
                : 'You have no forms on this page'
            }
            options={facebookData.forms}
            customSideBlock={facebookData.isLoading && <Loader size="small" color="mainBlue" />}
            name="facebookLeadAd"
            ctx={this}
            mainInput
          />
          <Input name="name" ctx={this} placeholder="set integration name" mainInput />
          <ErrorMessage>{facebookError || status.non_field_errors}</ErrorMessage>
        </div>
        <div className="facebook-integration-form__buttons-block">
          <Button color="filledGrey" onClick={() => history.push('/main/integrations')}>
            Cancel
          </Button>
          <Button
            status={networkProcess && networkProcess.status}
            onClick={() => this.submitHandler(integrationId)}
          >
            {integrationId ? 'Save' : 'Integrate'}
          </Button>
        </div>
      </div>
    );
  }
}
