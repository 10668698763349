// Default branch state

export const DEFAULT_BRANCH_STATE = '@ezylead/DEFAULT_BRANCH_STATE_INTEGRATIONS';

// Fetch integration names

export const FETCH_INTEGRATION_NAMES_NETWORK = '@ezylead/FETCH_INTEGRATION_NAMES_NETWORK';

// Fetch integrations

export const FETCH_INTEGRATIONS_NETWORK = '@ezylead/FETCH_INTEGRATIONS_NETWORK';

// Get token for instapage integration

export const GET_INSTAPAGE_TOKEN_NETWORK = '@ezylead/GET_INSTAPAGE_TOKEN_NETWORK';

// Get token for leadpages integration

export const GET_LEADPAGES_TOKEN_NETWORK = '@ezylead/GET_LEADPAGES_TOKEN_NETWORK';

// Get token for custom integration

export const GET_CUSTOM_TOKEN_NETWORK = '@ezylead/GET_CUSTOM_TOKEN_NETWORK';

// Add new integration

export const ADD_NEW_INTEGRATION_NETWORK = '@ezylead/ADD_NEW_INTEGRATION_NETWORK';

// Edit integration

export const EDIT_INTEGRATION_NETWORK = '@ezylead/EDIT_INTEGRATION_NETWORK';

// Fetch integration by id

export const FETCH_INTEGRATION_BY_ID_NETWORK = '@ezylead/FETCH_INTEGRATION_BY_ID_NETWORK';

// Delete integration

export const DELETE_INTEGRATION_NETWORK = '@ezylead/DELETE_INTEGRATION_NETWORK';
