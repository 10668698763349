import React from 'react';
import types from 'prop-types';

// Constants

import history from '../../../../modules/history';

// Styles

import './styles.scss';

// ----------------

// Type of props

TableRow.propTypes = {
  columnStyles: types.array,
  columnWidth: types.array,
  className: types.string,
  archive: types.bool,
  style: types.object,
  link: types.string
};

// Default value for props

TableRow.defaultProps = {
  className: ''
};

// ----------------

export default function TableRow(props) {
  const { columnWidth, className, children, style, link, columnStyles } = props;
  const _children = Array.isArray(children) ? children : [children];
  return (
    <div
      className={`table-row ${className}`}
      onClick={link ? () => history.push(link) : null}
      style={style}
    >
      {_children.map((column, i) => (
        <div
          className={`table-row__column-cell`}
          style={{
            width: `${columnWidth[i]}%`,
            minWidth: `${columnWidth[i]}%`,
            ...columnStyles[i]
          }}
          key={i}
        >
          {typeof column === 'object' ? (
            column
          ) : (
            <div className="table-row__text" title={column}>
              {column}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
