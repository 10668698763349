import React from 'react';
import types from 'prop-types';
import ReactSVG from 'react-svg';
import classNames from 'classnames';
import query from 'querystringify';

// Modules

import addQuery from '../../../modules/addQuery';
import history from '../../../modules/history';

// Components

import { Pagination, EmptyContent, Loader } from '../../../components';

// Icons

import arrowIcon from '../../../static/img/arrow-right.svg';

// Styles

import './styles.scss';

// ----------------

// Type of props

Table.propTypes = {
  className: types.string,
  row: types.func.isRequired,
  columns: types.array.isRequired,
  style: types.object,
  list: types.array.isRequired,
  pages: types.number,
  networkProcess: types.object,
  rowProps: types.object
};

// Default value for props

Table.defaultProps = {
  className: ''
};

// Modify styles

const modifyHeaderLi = (currentSort, currentDir, item) =>
  classNames({
    'table__header-list-li-inner--active': currentSort === item,
    'table__header-list-li-inner--active-asc': currentSort === item && currentDir === 'ASC',
    'table__header-list-li-inner--active-desc': currentSort === item && currentDir === 'DESC'
  });

const modifyBodyHeight = showPagination =>
  classNames({
    'table__body--without-pagination': !showPagination
  });

// ----------------

function sortHandler(currentSort, currentDir, sort) {
  if (currentSort === sort) {
    if (currentDir === 'DESC') {
      addQuery({}, ['orderField', 'orderDir'], false, true);
    } else {
      addQuery({ orderField: sort, orderDir: 'DESC' }, null, false, true);
    }
  } else {
    addQuery({ orderField: sort, orderDir: 'ASC' }, null, false, true);
  }
}

// ----------------

export default function Table(props) {
  const queryObj = query.parse(history.location.search);
  const {
    row: Row,
    className,
    columns,
    style,
    pages,
    list,
    link,
    rowProps,
    networkProcess
  } = props;
  const showPagination = pages > 1 && (!queryObj.page || +queryObj.page <= pages);
  const columnWidth = columns.map(item => item.width);
  const columnStyles = columns.map(item => item.style);

  return (
    <div className="table__wrapper">
      <div className={`table ${className}`} style={style}>
        <div className="table__header">
          <ul className="table__header-list">
            {columns.map(item =>
              item.sort ? (
                <li
                  className="table__header-list-li"
                  style={{
                    width: `${item.width}%`,
                    minWidth: `${item.width}%`,
                    ...item.style
                  }}
                  key={item.label}
                >
                  <div
                    className={`table__header-list-li-inner ${modifyHeaderLi(
                      queryObj.orderField,
                      queryObj.orderDir,
                      item.sort
                    )}`}
                    onClick={() => sortHandler(queryObj.orderField, queryObj.orderDir, item.sort)}
                  >
                    <span>{item.label}</span>
                    <div className="table__sort-icon-wrapper">
                      <ReactSVG src={arrowIcon} />
                    </div>
                  </div>
                </li>
              ) : (
                <li
                  className="table__header-list-li"
                  style={{ width: `${item.width}%`, minWidth: `${item.width}%`, ...item.style }}
                  key={item.label}
                >
                  {item.label}
                </li>
              )
            )}
          </ul>
        </div>
        <div className={`table__body ${modifyBodyHeight(showPagination)}`}>
          {networkProcess && networkProcess.status === 'loading' ? (
            <Loader color="mainBlue" style={{ minHeight: '540px' }} />
          ) : list && list.length ? (
            list.map(item => (
              <Row
                {...rowProps}
                columnStyles={columnStyles}
                columnWidth={columnWidth}
                data={item}
                link={link}
                key={item.id}
              />
            ))
          ) : (
            <EmptyContent emptyText="Sorry, we have no results for this request" />
          )}
        </div>
        {showPagination && (
          <div className="table__pagination-block">
            <Pagination pageCount={pages} activePage={+queryObj.page} />
          </div>
        )}
      </div>
    </div>
  );
}
