import * as TYPES from '../actionTypes/integrations';
import * as URL from '../../constants/endpoints';
import query from 'querystringify';

// Modules

import history from '../../modules/history';
import addQuery from '../../modules/addQuery';

// ----------------

// Fetch integration names

export const fetchIntegrationNames = () => ({
  networkType: TYPES.FETCH_INTEGRATION_NAMES_NETWORK,
  endpoint: query => URL.INTEGRATION_NAMES
});

// Fetch integrations

export const fetchIntegrations = payload => ({
  before: () => {
    const queryObj = query.parse(history.location.search);
    return query.stringify(
      {
        ...(queryObj.status && { status: queryObj.status }),
        ...(queryObj.source && { source: queryObj.source }),
        ...(queryObj.orderDir && { orderDir: queryObj.orderDir }),
        ...(queryObj.orderField && { orderField: queryObj.orderField }),
        ...(queryObj.endDate && { endDate: queryObj.endDate }),
        ...(queryObj.startDate && { startDate: queryObj.startDate }),
        page: +queryObj.page > 1 ? queryObj.page : 1,
        limit: 10
      },
      true
    );
  },
  networkType: TYPES.FETCH_INTEGRATIONS_NETWORK,
  endpoint: query => URL.FETCH_INTEGRATIONS + query,
  then: data => {}
});

// Fetch integration by id

export const fetchIntegrationById = payload => ({
  networkType: TYPES.FETCH_INTEGRATION_BY_ID_NETWORK,
  endpoint: query => `${URL.FETCH_INTEGRATIONS}/${payload.id}`
});

// Get instapage token

export const getInstapageToken = payload => ({
  networkType: TYPES.GET_INSTAPAGE_TOKEN_NETWORK,
  endpoint: query => URL.INSTAPAGE_TOKEN
});

// Get leadpages token

export const getLeadpagesToken = payload => ({
  networkType: TYPES.GET_LEADPAGES_TOKEN_NETWORK,
  endpoint: query => URL.LEADPAGES_TOKEN
});

// Get custom token

export const getCustomToken = payload => ({
  networkType: TYPES.GET_CUSTOM_TOKEN_NETWORK,
  endpoint: query => URL.CUSTOM_TOKEN
});

// Add new integration

export const addNewIntegration = payload => ({
  networkType: TYPES.ADD_NEW_INTEGRATION_NETWORK,
  endpoint: query => URL.ADD_NEW_INTEGRATION,
  payload,
  method: 'POST',
  then: (data, dispatch) => {
    history.push('/main/integrations');
  }
});

// Edit integration

export const editIntegration = (payload, callBack, integrationId) => ({
  networkType: TYPES.EDIT_INTEGRATION_NETWORK,
  endpoint: query => `${URL.EDIT_INTEGRATION}/${integrationId}`,
  payload,
  method: 'PUT',
  then: (data, dispatch) => {
    history.push('/main/integrations');
  }
});

// Delete integration

export const deleteIntegration = payload => ({
  networkType: TYPES.DELETE_INTEGRATION_NETWORK,
  endpoint: query => URL.DELETE_INTEGRATION + payload,
  method: 'DELETE',
  integrationId: payload,
  then: (data, dispatch, getState) => {
    // Go to actual page or fetch new integrations from next page depends on current state

    const integrationsObject = getState().integrations;
    const page = query.parse(history.location.search).page;
    if (!integrationsObject.integrations.length) {
      if (page && +page > 1) {
        addQuery({ page: page - 1 });
      }
    } else if (
      (!page && integrationsObject.pages > 1) ||
      (page && +page < integrationsObject.pages)
    ) {
      dispatch(fetchIntegrations());
    }
  }
});
