import React from 'react';
import { connect } from 'react-redux';

// Modules

import history from '../../../modules/history';

// Components

import {
  EditAccountForm,
  CenterBlock,
  PageWrapper,
  PageTitle,
  Modal,
  Page
} from '../../../components';

// Action creators

import { fetchAccountById, editAccount } from '../../../store/actionCreators/accounts';
import { toggleModal } from '../../../store/actionCreators/ui';

// Action types

import { EDIT_ACCOUNT_NETWORK } from '../../../store/actionTypes/accounts';

// ----------------

class EditAccountPage extends Page {
  // Route config

  static title = 'Edit Account';
  static path = '/main/edit-account/:id';
  static exactPath = true;
  static permissions = ['ADMIN'];

  // Data

  mount = this.props.match.params.id && [
    {
      ac: fetchAccountById,
      payload: { id: this.props.match.params.id },
      find: { key: 'id', value: this.props.match.params.id },
      mapState: ['accounts', 'accounts']
    }
  ];

  // Bind methods

  pageContent = this.pageContent.bind(this);

  // -------- Methods --------

  // Content of route

  pageContent() {
    const accountId = this.props.match.params.id;
    const { networkProcess, onModalClose, modalData, accounts, editAccount } = this.props;

    return (
      <PageWrapper>
        <PageTitle>Edit Account</PageTitle>
        <CenterBlock withPadding>
          <EditAccountForm
            submitAction={editAccount}
            accountId={accountId}
            data={accounts.find(account => account.id === +accountId)}
            networkProcess={networkProcess}
          />
          {!!modalData && (
            <Modal
              data={modalData}
              onClose={onModalClose}
              popupTitle="Success"
              confirmation
              onConfirm={() => history.push('/main/accounts')}
            />
          )}
        </CenterBlock>
      </PageWrapper>
    );
  }

  // Render

  render() {
    return this.securRenderPage(this.pageContent);
  }
}

export default connect(
  state => ({
    networkProcess: state.network[EDIT_ACCOUNT_NETWORK],
    modalData: state.ui.modal.addNewLeadConfirmModal,
    accounts: state.accounts.accounts
  }),
  {
    onModalClose: () => toggleModal({ name: 'addNewLeadConfirmModal' }),
    fetchAccountById,
    editAccount
  }
)(EditAccountPage);
