import React from 'react';
import types from 'prop-types';
import classNames from 'classnames';

// Styles

import './styles.scss';

// ----------------

// Type of props

RadioButton.propTypes = {
  className: types.string,
  style: types.object,
  title: types.string,
  value: types.string.isRequired,
  name: types.string.isRequired,
  ctx: types.object.isRequired
};

// Default value for props

RadioButton.defaultProps = {
  className: ''
};

// Modify styles

const modify = props =>
  classNames({
    'radio-btn__title--auto-width': props.autoWidth
  });

const modifyInput = error =>
  classNames({
    'radio-btn__input--error': error
  });

// ----------------

export default function RadioButton(props) {
  const { className, title, name, ctx, value, style, error } = props;
  return (
    <div className="radio-btn__wrapper" style={style} ref={ref => (ctx.formRefs[name] = ref)}>
      <label className={`radio-btn__title ${modify(props)}`}>
        <input
          className={`radio-btn__input ${className} ${modifyInput(
            error || ctx.state.status[name]
          )}`}
          onChange={ctx.changeHandler}
          checked={ctx.state.form[name] === value}
          value={value}
          name={name}
          type="radio"
        />
        <span>{title || ''}</span>
      </label>
    </div>
  );
}
