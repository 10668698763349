import React from 'react';
import types from 'prop-types';
import copy from 'copy-to-clipboard';

// Components

import { Button, IconButton } from '../../../components';

// Styles

import './styles.scss';

// Icons

import copyIcon from '../../../static/img/copy.svg';

// ----------------

export default class CopyToClipboard extends React.Component {
  static propTypes = {
    copiedText: types.string,
    beforeCopy: types.func,
    buttonText: types.string,
    children: types.node,
    timeout: types.number,
    isIcon: types.bool
  };

  static defaultProps = {
    timeout: 1500,
    tooltipText: 'Copied!',
    buttonText: 'Copy to clipboard'
  };

  timeoutId = null;

  state = {
    showTooltip: false,
    errorMessage: ''
  };

  // -------- Bind methods --------

  onCopy = this.onCopy.bind(this);

  // -------- Methods --------

  onCopy(text) {
    const { beforeCopy } = this.props;
    const textToCopy = beforeCopy ? beforeCopy() : text;

    if (textToCopy) {
      copy(textToCopy);
      this.setState({ showTooltip: true });
      this.timeoutId = setTimeout(() => this.setState({ showTooltip: false }), this.props.timeout);
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  // Render

  render() {
    const { tooltipText, text, buttonText, isIcon } = this.props;
    const { showTooltip, errorMessage } = this.state;

    return (
      <div className="copy-to-clipboard">
        {isIcon ? (
          <IconButton
            type="xsmall"
            onClick={showTooltip ? null : () => this.onCopy(text)}
            icon={copyIcon}
            color="mainBlue"
            title={buttonText}
          />
        ) : (
          <Button type="secondary" autoWidth onClick={showTooltip ? null : () => this.onCopy(text)}>
            {buttonText}
          </Button>
        )}
        {showTooltip && (
          <div className="copy-to-clipboard__tooltip">{errorMessage || tooltipText}</div>
        )}
      </div>
    );
  }
}
