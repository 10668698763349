import jwtDecode from 'jwt-decode';

/**
 * Check if JWT-token is expired
 *
 * @param {string}  token  - JWT token
 *
 * @return {boolean} - is token expired
 */

export default function isExpired(token) {
  const currentTime = Date.now();
  const expiresDate = jwtDecode(token).exp * 1000;
  return currentTime > expiresDate;
}
