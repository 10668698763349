import React from 'react';
import types from 'prop-types';
import classNames from 'classnames';

// Components

import { FieldWrapper } from '../../../components';

// Modules

import deleteProps from '../../../modules/deleteProps';

// Styles

import './styles.scss';

// ----------------

// Type of props

Input.propTypes = {
  customSideBlockAutoWidth: types.bool,
  customSideBlock: types.object,
  submitOnEnter: types.bool,
  withOutBorder: types.bool,
  placeholder: types.string,
  className: types.string,
  style: types.object,
  title: types.string,
  value: types.string,
  error: types.string,
  name: types.string.isRequired,
  ctx: types.object.isRequired
};

// Default value for props

Input.defaultProps = {
  className: ''
};

// Modify styles

const modify = (props, error) =>
  classNames({
    'input__input--default-paddings': !props.customSideBlock,
    'input__input--withOutBorder': props.withOutBorder,
    'input__input--full-width': props.fullWidth,
    'input__input--main': props.mainInput,
    'input__input--error': error
  });

const modifyInputWrapper = props =>
  classNames({
    'input__wrapper--disabled': props.disabled
  });

const modifyCustomSideBlock = props =>
  classNames({
    'input__side-block--auto-width': props.customSideBlockAutoWidth,
    [`input__side-block--${props.customSideBlockAlignment}`]: props.customSideBlockAlignment
  });

// ----------------

export default function Input(props) {
  const { customSideBlock, className, error, name, ctx, submitOnEnter, placeholder } = props;

  return (
    <FieldWrapper
      error={error || ctx.state.status[name]}
      referer={ref => (ctx.formRefs[name] = ref)}
      className={className}
    >
      <div className={`input__wrapper ${modifyInputWrapper(props)}`}>
        <input
          className={`input__input ${modify(props, error || ctx.state.status[name])} ${className}`}
          onChange={ctx.changeHandler}
          onFocus={ctx.focusHandler}
          onBlur={ctx.blurHandler}
          id={`input-${name}`}
          value={ctx.state.form[name]}
          {...submitOnEnter && { onKeyDown: ctx.enterHandler }}
          {...deleteProps(props, [
            'customSideBlockAlignment',
            'customSideBlockAutoWidth',
            'placeholderUppercase',
            'customSideBlock',
            'submitOnEnter',
            'withOutBorder',
            'mainInput',
            'className',
            'fullWidth',
            'title',
            'error',
            'ctx'
          ])}
        />
        <label className="input__input-label" htmlFor={`input-${name}`}>
          {placeholder}
        </label>
        {customSideBlock && (
          <div className={`input__side-block ${modifyCustomSideBlock(props)}`}>
            {customSideBlock}
          </div>
        )}
      </div>
    </FieldWrapper>
  );
}
