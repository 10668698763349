import React from 'react';
import { connect } from 'react-redux';

// Constants

import history from '../../../modules/history';

// Components

import { Page, AuthFormStruct, RegisterForm, Modal } from '../../../components';

// Action types

import { REGISTER_NETWORK } from '../../../store/actionTypes/user';

// Action creators

import { registerUser } from '../../../store/actionCreators/user';
import { toggleModal, saveForm, clearForm } from '../../../store/actionCreators/ui';

// ----------------

class RegisterPage extends Page {
  // Page (Route) config

  static title = 'Register';
  static path = '/auth/register';

  // -------- Methods --------

  onConfirmModal = () => {
    history.push('/auth/login');
  };

  // Render

  render() {
    const {
      networkProcess,
      onModalClose,
      registerUser,
      modalData,
      saveForm,
      savedForm,
      clearForm
    } = this.props;

    return (
      <AuthFormStruct>
        <RegisterForm
          networkProcess={networkProcess}
          submitAction={registerUser}
          clearForm={clearForm}
          savedForm={savedForm}
          saveForm={saveForm}
        />
        {!!modalData && (
          <Modal
            data={modalData}
            onClose={onModalClose}
            onConfirm={this.onConfirmModal}
            popupTitle="Success"
            confirmation={true}
          />
        )}
      </AuthFormStruct>
    );
  }
}

export default connect(
  state => ({
    networkProcess: state.network[REGISTER_NETWORK],
    modalData: state.ui.modal.registerUserSuccessModal,
    savedForm: state.ui.savedForm.registerForm
  }),
  {
    onModalClose: () => toggleModal({ name: 'registerUserSuccessModal' }),
    registerUser,
    saveForm,
    clearForm
  }
)(RegisterPage);
