import React from 'react';
import types from 'prop-types';
import classNames from 'classnames';

// Components

import { Text, Heading } from '../../../components';

// ----------------

// Styles

import './styles.scss';

// ----------------

// Type of props

TermsAndConditionsBlock.propTypes = {
  color: types.string
};

// Modify styles

const modify = props =>
  classNames({
    [`terms-block--${props.color}`]: props.color
  });

// ----------------

export default function TermsAndConditionsBlock(props) {
  return (
    <div className={`terms-block ${modify(props)}`}>
      <Heading type="h5" color="blue" withMargin>
        Terms and Conditions
      </Heading>
      <Text withSmallMargin weight="bold">
        Ezylead Access
      </Text>
      <Text withMargin>
        During your subscription term we will provide you with access to the subscription service
        including the mobile application and web lead dashboard.
      </Text>
      <Text withSmallMargin weight="bold">
        Availability
      </Text>
      <Text withMargin>
        We try to make the Subscription Service available 24 hours a day, 7 days a week, except for
        planned downtime for maintenance. If any maintenance is scheduled we will notify you by
        email, with the email we hold on account for you.
      </Text>
      <Text withSmallMargin weight="bold">
        Fees & Payments
      </Text>
      <Text withMargin>
        Once your 14-day trial is completed you will be asked to subscribe to the service for a
        fixed monthly fee. Fees are clearly stated on the website. Once paid a confirmation receipt
        will be sent to you.
      </Text>
      <Text withSmallMargin weight="bold">
        Payment by credit card 
      </Text>
      <Text withMargin>
        If you are paying by credit card, you authorize us to charge your credit card or bank
        account for all fees payable during the Subscription Term. You further authorize us to use a
        third party to process payments, and consent to the disclosure of your payment information
        to such third party.
      </Text>
      <Text withSmallMargin weight="bold">
        Your Payment Information
      </Text>
      <Text withMargin>
        You will keep your contact information, billing information and credit card information
        (where applicable) up to date. If we experience any difficulties processing your credit
        card, we will contact you via email and request that you update you details.
      </Text>
      <Text withSmallMargin weight="bold">
        Service cancellation
      </Text>
      <Text withMargin>
        To cancel your Ezylead subscription, please email
        <a href="mailto:support@ezylead.io"> support@ezylead.io</a> confirming your account details
        and cancellation request. A team member will confirm your cancellation within 48 hours.
      </Text>
      <Text withSmallMargin weight="bold">
        Privacy Policy
      </Text>
      <Text withMargin>
        This privacy policy sets out how we use and protect any information that you give us when
        you use this website. We are committed to ensuring that your privacy is protected. We will
        only use the information that we collect about you lawfully in accordance with the General
        Data Protection Regulation May 2019 (GDPR). We may change this policy from time to time by
        updating this page. You should check this page from time to time to ensure that you are
        happy with any changes.
      </Text>
      <Text withSmallMargin weight="bold">
        Who we are
      </Text>
      <Text withMargin>
        ID Lead Ltd (Ezylead) is a technology company that specializes in lead management and CRM
        technology. Our registered address is 27 Old Gloucester Street, London, WC1N 3AX.
        <br />
        <br />
        Our company registration number is: 1188963 You may contact us by post at the address above,
        or by email: <a href="mailto:contact@ezylead.io"> contact@ezylead.io</a>.
        <br />
        <br />
        ID Lead Ltd (Ezylead) is committed to lawfully processing your information and respecting
        your privacy.
      </Text>
      <Text withSmallMargin weight="bold">
        What Data We Collect
      </Text>
      <Text withMargin>
        We will collect the following information: Your first and last name  Your email address and
        telephone number. 
      </Text>
      <Text withSmallMargin weight="bold">
        What we do with your information
      </Text>
      <Text withMargin>
        The purpose for which we use this information and the legal basis under data protection law
        is as follows:
        <br />
        <br />
        Your information is stored on secure servers located within the EU. We ensure the security
        of your personal data using secure data storage technology, which stores your data and
        allows us to securely access and manage it.
        <br />
        <br />
        Your information is held on our systems for a minimum of 7 years from the date of your last
        transaction through our services. This does not affect your rights as a data subject
        (Natural Person).
        <br />
        <br />
        Anonymised information may be passed to companies who require statistical or performance
        data regarding commercial relationships between companies. This data will not contain any
        information that identifies who you are and will not compromise your privacy or security.
      </Text>
      <Text withMargin weight="bold">
        Your individual rights:
      </Text>
      <Text withSmallMargin weight="bold">
        - Your right to be informed
      </Text>
      <Text withMargin>
        This privacy notice is part of the process where you have the right to be informed. If we
        collect information from third parties that you have provided, we will provide this
        information within a reasonable period but no later than one month.
      </Text>
      <Text withSmallMargin weight="bold">
        - Your right of access
      </Text>
      <Text withMargin>
        You have the right to obtain confirmation that your data is being processed. You also have
        the right to access your personal data and to verify the lawfulness of processing.
        <br />
        <br />
        Any request is free of charge however we may charge a reasonable fee if the request is
        excessive. We may also charge if subsequent requests are made for the same information.
        <br />
        <br />
        We will ensure that any data subject access request is actioned within one month of receipt.
        <br />
        <br />
        We will need to verify your identity as part of this process using reasonable means.
        Generally, this is by letter or electronically by email.
      </Text>
      <Text withSmallMargin weight="bold">
        - Your right of rectification.
      </Text>
      <Text withMargin>
        You have the right to have any inaccurate information corrected or completed if incomplete.
        This can be made verbally or in writing.
        <br />
        <br />
        We will correct any data we hold within one month of being notified. We will not charge to
        amend or correct your data unless it is excessive. We may need to verify your identity in
        order to make the requested changes.
      </Text>
      <Text withSmallMargin weight="bold">
        - Your right to erasure
      </Text>
      <Text withMargin>
        You have the right to have your personal data erased. This is known as your right to be
        forgotten. Such a request can be made either verbally or in writing.
        <br />
        <br />
        We will respond to any request within one month of receipt of the request.
        <br />
        <br />
        We will not charge a fee for erasing your data. We will also need to verify your identity of
        each individual persons exercising the right to be forgotten.
      </Text>
      <Text withSmallMargin weight="bold">
        - Your right to restrict processing
      </Text>
      <Text withMargin>
        You have the right to request that we restrict or stop using your personal information. We
        may continue to store the information but will stop using it.
        <br />
        <br />
        Your request can be made verbally or in writing and will be responded to with undue delay
        and no later than one month from the date of request.
      </Text>
      <Text withSmallMargin weight="bold">
        - Rights to object
      </Text>
      <Text withMargin>
        You have the right to object to your personal data being used. If you wish for us to stop
        using your personal data we will do so without delay.
      </Text>
      <Text withSmallMargin weight="bold">
        - Rights in relation to automated decision making and profiling
      </Text>
      <Text withMargin>
        We do not carry out automated decision making. We may however use some of your personal data
        on an anonymised basis for internal statistical and / or marketing reasons.
        <br />
        <br />
        You can object to your data being used this way. In such an event, we will not use your
        information as part of our profiling.
      </Text>
      <Text withSmallMargin weight="bold">
        - Your right to complain
      </Text>
      <Text withMargin>
        If you have a complaint about our use of your information, you can contact the Information
        Commissioner's Office via their website at 
        <a target="_blank" rel="noopener noreferrer" href="http://www.ico.org/concerns">
          www.ico.org/concerns
        </a>
         or write to them at: Information Commissioner's Office, Wycliffe House, Water Lane,
        Wilmslow, Cheshire, SK9 5AF.
      </Text>
      <Text withSmallMargin weight="bold">
        Cookies
      </Text>
      <Text withMargin>
        A cookie is a small text-only file which is stored on your computer or mobile device when
        visiting a website to help it function or to track your visits. The ID Lead Ltd (Ezylead)
        website requires cookies to work correctly. By opting-in to the website you agree to the use
        of cookies by Lead Ltd (Ezylead). Further information can be obtained at{' '}
        <a target="_blank" rel="noopener noreferrer" href="http://www.allaboutcookies.org">
          http://www.allaboutcookies.org
        </a>
        . The table below explains the cookies that are used.
        <br />
        <br />
        In addition, the below cookies may be placed on your computer in order to identify web site
        visitors that have agreed to the use of their data for 3rd party online advertising,
        insight, analytics and content personalisation. This cookie is anonymous and is encrypted
        and only contains a code that identifies segments to partners who may use the cookie for the
        purposes of targeted advertising by tracking your online activity. Information provided by
        cookies are only used for advertising purposes. Information is collected, stored and used
        separately for each advertising partner.
      </Text>
      <Text withSmallMargin weight="bold">
        Google Analytics
      </Text>
      <Text withMargin>
        These cookies allow Identicom Digital to collect information about how visitors use the
        website. The cookies collect information in an anonymous form, including the number of
        visitors to the site, where visitors have come to the site from and the pages they visited.
        Click here for an overview of privacy at Google: {' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://support.google.com/analytics/answer/6004245"
        >
          https://support.google.com/analytics/answer/6004245
        </a>
      </Text>
      <Text withSmallMargin weight="bold">
        Google Adsense
      </Text>
      <Text withMargin>
        Google uses cookies to serve more relevant ads across the web and limit the number of times
        a given ad is shown to you. When you visit a website, view an ad, or click an ad supported
        by Google's advertising services, Google may set a cookie on your browser. This advertising
        cookie will appear in your browser as coming from the domain "doubleclick.net". Learn more
        about Googles Policies
      </Text>
      <Text withSmallMargin weight="bold">
        ID Lead Ltd (Ezylead)
      </Text>
      <Text withMargin>
        Lead Ltd (Ezylead) uses cookies to track the source of your visit to the Ezylead website.
      </Text>
      <Text withSmallMargin weight="bold">
        Google and remarketing
      </Text>
      <Text withMargin>
        From time to time Lead Ltd (Ezylead) uses a cookie-based system known as 'remarketing' to
        advertise online. This means we sometimes place a cookie on visitors' computers or devices
        that other companies, like Google, use to advertise our products to you on other websites
        around the web. This allows us to target the adverts based on your past visits to our
        websites.
        <br />
        <br />
        If you wish, you can opt-out of Google's use of these cookies by visiting{' '}
        <a target="_blank" rel="noopener noreferrer" href="http://www.google.com/settings/ads">
          http://www.google.com/settings/ads
        </a>
        , or you can opt out of other companies' use of these cookies by visiting the {' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.networkadvertising.org/managing/opt_out.asp"
        >
          http://www.networkadvertising.org/managing/opt_out.asp
        </a>
        <br />
        <br />
        Opting out does not mean you will no longer receive online advertising. It does mean that
        the company or companies from which you opted out will no longer deliver ads tailored to
        your web preferences and usage patterns.
      </Text>
      <Text withSmallMargin weight="bold">
        Links To Other Websites
      </Text>
      <Text withMargin>
        Our website may contain links to other websites of interest. However, once you have used
        these links to leave our site, you should note that we do not have any control over that
        other website. We cannot be responsible for the protection and privacy of any information,
        which you provide, whilst visiting such sites. This privacy statement does not govern
        external websites. You should exercise caution and look at the privacy statement applicable
        to the website in question.
      </Text>
    </div>
  );
}
