import React from 'react';
import types from 'prop-types';
import classNames from 'classnames';

// Styles

import './styles.scss';

// ----------------

// Type of props

CenterBlock.propTypes = {
  className: types.string,
  children: types.node,
  style: types.object,
  maxWidth: types.string,
  withPadding: types.bool,
  flex: types.bool
};

// Default value for props

CenterBlock.defaultProps = {
  className: ''
};

// Modify styles

const modify = props =>
  classNames({
    'center-block--with-large-padding': props.withLargePadding,
    'center-block--with-padding': props.withPadding,
    'center-block--flex': props.flex
  });

// ----------------

export default function CenterBlock(props) {
  const { className, style, children, maxWidth } = props;

  return (
    <div className={`center-block  ${className} ${modify(props)}`} style={{ ...style, maxWidth }}>
      {children}
    </div>
  );
}
