import React from 'react';
import types from 'prop-types';
import { Link } from 'react-router-dom';

// Constants

import rules from '../../../constants/validation';
import languages from '../../../constants/languages';

// Components

import { Button, Input, Visible, ErrorMessage } from '../../../components';
import Form from '../../core/Form';

// Styles

import './styles.scss';

// ----------------

export default class LoginForm extends Form {
  // Type of props

  static propTypes = { className: types.string, style: types.object };

  // Default value for props

  static defaultProps = { className: '' };

  constructor(props) {
    super(props);

    // State of component

    this.state = {
      form: {
        email: '',
        password: ''
      },
      passwordType: 'password'
    };

    // Validation rules

    this.rules = {
      password: {
        ...rules.password,
        message: {
          regExp: languages.errorMessage.password
        }
      },
      email: {
        ...rules.email,
        message: {
          regExp: languages.errorMessage.email
        }
      }
    };

    // Bind

    this.changePasswordType = this.changePasswordType.bind(this);

    // Init form

    this.init();
  }

  // -------- Methods --------

  // Change password field type

  changePasswordType() {
    const { passwordType } = this.state;

    if (passwordType === 'password') {
      this.setState({ passwordType: 'text' });
    } else {
      this.setState({ passwordType: 'password' });
    }
  }

  // Render

  render() {
    const { className, style, networkProcess } = this.props;
    const { status, passwordType } = this.state;

    return (
      <div className={`login-form ${className}`} style={style}>
        <div className="login-form__upper-block">
          <Input name="email" ctx={this} placeholder="email" autoFocus />
          <Input
            autoComplete="off"
            type={passwordType}
            name="password"
            placeholder="password"
            ctx={this}
            customSideBlock={
              <Visible onClick={this.changePasswordType} visible={passwordType !== 'text'} />
            }
          />
          <ErrorMessage>{status.non_field_errors}</ErrorMessage>
        </div>
        <div className="login-form__buttons-block">
          <Button status={networkProcess && networkProcess.status} onClick={this.submitHandler}>
            Sign in
          </Button>
        </div>
        <div className="login-form__links-block">
          <Link className="link link--button-transparent" to="/auth/register">
            Sign up
          </Link>
          <Link className="link link--transparent" to="/auth/forgot-password">
            Forgot password?
          </Link>
        </div>
      </div>
    );
  }
}
