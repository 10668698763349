// App version

export const appVersion = '0.1';

// App display name

export const displayAppName = 'EzyLeads';

// Local storage names

export const localStorageNames = {
  token: 'EzyLeads__token',
  refreshToken: 'EzyLeads__refresh-token',
  facebookToken: 'EzyLeads__facebook-token',
  entryPath: 'EzyLeads__entry-path'
};

// Redirect path

export const redirectPath = {
  withoutSubscription: '/main/plans',
  withoutToken: '/auth/login',
  withToken: '/main/home'
};

// Trial time in hours

export const trialTime = 336;
