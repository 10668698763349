import React from 'react';
import types from 'prop-types';

// Constants

import rules from '../../../constants/validation';
import languages from '../../../constants/languages';

// Components

import { Button, Input, ErrorMessage, Visible, Text } from '../../../components';
import Form from '../../core/Form';

// Modules

import history from '../../../modules/history';

// Styles

import './styles.scss';

// ----------------

export default class PersonalInfoForm extends Form {
  // Type of props

  static propTypes = { className: types.string, style: types.object };

  // Default value for props

  static defaultProps = { className: '' };

  constructor(props) {
    super(props);

    // State of component

    this.state = {
      form: {
        oldPassword: '',
        password: '',
        confirmPassword: ''
      },
      oldPasswordType: 'password',
      passwordType: 'password',
      confirmPasswordType: 'password'
    };

    // Validation rules

    this.rules = {
      oldPassword: {
        ...rules.password,
        message: {
          regExp: languages.errorMessage.password
        }
      },
      password: {
        ...rules.password,
        message: {
          regExp: languages.errorMessage.password
        }
      },
      confirmPassword: { confirm: 'password' }
    };

    // Bind

    this.changePasswordType = this.changePasswordType.bind(this);

    // Init form

    this.init();
  }

  // -------- Methods --------

  // Change password field type

  changePasswordType(type) {
    const passwordType = this.state[type];

    if (passwordType === 'password') {
      this.setState({ [type]: 'text' });
    } else {
      this.setState({ [type]: 'password' });
    }
  }

  // Render

  render() {
    const { className, style, networkProcess } = this.props;
    const { status, passwordType, oldPasswordType, confirmPasswordType } = this.state;

    return (
      <div className={`change-password-form ${className}`} style={style}>
        <div className="change-password-form__upper-block">
          <Text type="small" center withMargin>
            The password should be at least 8 characters long and a combination of uppercases (A-Z),
            lowercases (a-z) and digits (0-9).
          </Text>
          <Input
            type={oldPasswordType}
            name="oldPassword"
            placeholder="old password"
            ctx={this}
            mainInput
            customSideBlock={
              <Visible
                color="grey"
                onClick={() => this.changePasswordType('oldPasswordType')}
                visible={oldPasswordType !== 'text'}
              />
            }
          />
          <Input
            type={passwordType}
            name="password"
            placeholder="new password"
            ctx={this}
            mainInput
            customSideBlock={
              <Visible
                color="grey"
                onClick={() => this.changePasswordType('passwordType')}
                visible={passwordType !== 'text'}
              />
            }
          />
          <Input
            type={confirmPasswordType}
            name="confirmPassword"
            placeholder="confirm password"
            ctx={this}
            mainInput
            customSideBlock={
              <Visible
                color="grey"
                onClick={() => this.changePasswordType('confirmPasswordType')}
                visible={confirmPasswordType !== 'text'}
              />
            }
          />
          <ErrorMessage>{status.non_field_errors}</ErrorMessage>
        </div>
        <div className="change-password-form__buttons-block">
          <Button color="filledGrey" onClick={() => history.push('/main/leads')}>
            Cancel
          </Button>
          <Button status={networkProcess && networkProcess.status} onClick={this.submitHandler}>
            Save
          </Button>
        </div>
      </div>
    );
  }
}
