import React from 'react';
import { connect } from 'react-redux';

// Components

import { Modal, PersonalInfoForm, Page, PageWrapper } from '../../../../../components';

// Action creators

import { updateUserInfo } from '../../../../../store/actionCreators/user';
import { toggleModal } from '../../../../../store/actionCreators/ui';

// Action types

import { UPDATE_USER_INFO_NETWORK } from '../../../../../store/actionTypes/user';

// ----------------

class PersonalInfoTab extends Page {
  // Route config

  static title = 'Personal info';
  static asTab = {
    displayName: 'Personal info',
    queryName: 'info'
  };

  // -------- Methods --------

  // Render

  render() {
    const { networkProcess, formData, modalData, onModalClose, updateUserInfo } = this.props;

    return (
      <PageWrapper>
        <PersonalInfoForm
          data={formData}
          submitAction={updateUserInfo}
          networkProcess={networkProcess}
        />
        {!!modalData && (
          <Modal data={modalData} onClose={onModalClose} popupTitle="Success" confirmation={true} />
        )}
      </PageWrapper>
    );
  }
}

export default connect(
  state => ({
    networkProcess: state.network[UPDATE_USER_INFO_NETWORK],
    modalData: state.ui.modal.updateUserInfoModal,
    formData: state.user.userInfo
  }),
  {
    onModalClose: () => toggleModal({ name: 'updateUserInfoModal' }),
    updateUserInfo
  }
)(PersonalInfoTab);
