import React from 'react';
import { connect } from 'react-redux';

// Components

import {
  PageWrapper,
  CenterBlock,
  PageTitle,
  Heading,
  Button,
  Page,
  Text
} from '../../../components';

// Modules

import history from '../../../modules/history';

// ----------------
class HomePage extends Page {
  static title = 'Home';
  static path = '/main/home';
  static exactPath = true;

  // -------- Methods --------

  // Render

  render() {
    const { isSubscribed } = this.props;

    return (
      <PageWrapper>
        <PageTitle desktopHidden>Home</PageTitle>
        <CenterBlock withPadding>
          <Heading type="h1" color="darkBlue" withMargin>
            Welcome
          </Heading>
          <Text center withMargin type="small" style={{ lineHeight: '1.9' }}>
            By now you should of received a confirmation email, confirming your 14-day free trial
            and links to download the Ezylead app from either Apple Store or Google Play.
          </Text>
          <Text center withMargin type="small" style={{ lineHeight: '1.9' }}>
            Below you will find a short introductory video, which walks you through the lead
            dashboard and some features of the app. The FAQ’s and Support sections should cover any
            queries you have. If you can’t find the solution within these sections, please use the
            form on the support pages to contact us or email{' '}
            {<a href="mailto:support@ezylead.io">support@ezylead.io</a>}
          </Text>
          <Text center withMargin type="small" style={{ lineHeight: '1.9' }}>
            Once your free trial has ended, we will contact you via email with details of how to
            upgrade to a full subscription.
          </Text>
          <div className="youtube-video">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  '<iframe src="https://www.youtube.com/embed/nGpbgQFCy7Q?enablejsapi=1" class="youtube-video__player" frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" width="100%" height="100%" >'
              }}
            />
          </div>
          {!isSubscribed && (
            <Button onClick={() => history.push('/main/plans')}>Select plan</Button>
          )}
        </CenterBlock>
      </PageWrapper>
    );
  }
}

export default connect(state => ({
  isSubscribed: !!state.user.userInfo.StripeDetail
}))(HomePage);
