export default {
  string: {
    max: 255
  },

  longString: {
    max: 1000
  },

  email: {
    max: 254,
    regExp: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/ // eslint-disable-line
  },

  password: {
    max: 64,
    regExp: /^(?=.*[A-z])(?=.*[A-Z])(?=.*[0-9])\S{8,}$/
  },

  empty: {
    min: 1
  },

  files: {
    optional: true,
    max: 5
  },

  floatNumber: {
    regExp: /^\d+(\.\d+)?$/
  },

  smallFloatNumber: {
    regExp: /^\d{1,3}(\.\d{0,2})?$/
  },

  date: {
    regExp: /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
  },

  phone: {
    max: 15,
    min: 3,
    regExp: /^\+?[\d]+$/
  }
};
