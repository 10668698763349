import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Endpoints

import { USER_INFO } from './constants/endpoints';

// Constants

import { localStorageNames } from './constants';

// Components

import { ContextProvider } from './components';

// Modules

import fetch from './modules/fetch';
import isExpired from './modules/isTokenExpired';

// Action creators

import { logout } from './store/actionCreators/user';

// Store creator

import createStore from './store';

(async () => {
  // -------- Operations that need to be done before first render --------

  const token = localStorage.getItem(localStorageNames.token);
  const refreshToken = localStorage.getItem(localStorageNames.refreshToken);
  let store;

  if (token && isExpired(token)) {
    if (refreshToken && !isExpired(refreshToken)) {
      try {
        const refreshPromise = fetch('auth/refresh-token', {
          method: 'POST',
          body: JSON.stringify({ refreshToken })
        });

        const result = await refreshPromise;
        localStorage.setItem(localStorageNames.token, result.token);
      } catch (error) {
        store = createStore();
        store.dispatch(logout());
      }
    } else {
      store = createStore();
      store.dispatch(logout());
    }
  }

  if (token) {
    try {
      // Get user data

      const userInfo = await fetch(USER_INFO, {});
      const reduxUserBranch = {
        userInfo: userInfo.data,
        subscription: {},
        isVerified: false
      };
      store = createStore({ user: reduxUserBranch });
    } catch (error) {
      store = createStore();
      store.dispatch(logout());
    }
  } else {
    store = createStore();
  }

  ReactDOM.render(
    <Provider store={store}>
      <ContextProvider store={store}>
        <App />
      </ContextProvider>
    </Provider>,
    document.getElementById('root')
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.register();
})();
