import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Constants

import { redirectPath } from '../../constants';

import { Page, AuthStruct } from '../../components';

// Sub routes

const subRoutes = [
  require('./forgotPassword').default,
  require('./register').default,
  require('./terms').default,
  require('./verify').default,
  require('./login').default,
  require('./reset').default
];

class AuthPage extends Page {
  static title = 'Auth';
  static access = 'unauth';
  static path = '/auth';

  // Bind methods

  pageContent = this.pageContent.bind(this);

  // -------- Methods --------

  // Content of route

  pageContent() {
    return (
      <AuthStruct>
        <Switch>
          {subRoutes.map(route => (
            <Route path={route.path} component={route} key={route.path} />
          ))}
          <Route component={() => <Redirect to={redirectPath.withoutToken} />} />
        </Switch>
      </AuthStruct>
    );
  }

  // Render

  render() {
    return this.securRenderPage(this.pageContent);
  }
}

export default AuthPage;
