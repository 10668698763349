import React from 'react';
import types from 'prop-types';
import classNames from 'classnames';

// Components

import { Button } from '../../../components';

// Styles

import './styles.scss';

// ----------------

// Type of props

SubscriptionPlanItem.propTypes = {
  contentItems: types.array,
  buttonTitle: types.string,
  description: types.string,
  className: types.string,
  heading: types.string,
  onButtonClick: types.func,
  disabled: types.bool,
  price: types.string,
  style: types.object,
  vat: types.string
};

// Default value for props

SubscriptionPlanItem.defaultProps = {
  className: '',
  buttonTitle: 'Enroll'
};

// Modify styles

const modify = props =>
  classNames({
    'subscription-plan--disabled': props.disabled
  });

// ----------------

export default function SubscriptionPlanItem(props) {
  const {
    onButtonClick,
    contentItems,
    description,
    buttonTitle,
    className,
    disabled,
    heading,
    price,
    vat,
    style
  } = props;

  return (
    <div className={`subscription-plan ${className} ${modify(props)}`} style={style}>
      <div className="subscription-plan__header">
        <div className="subscription-plan__heading">{heading}</div>
        <div className="subscription-plan__price">{price}</div>
        <div className="subscription-plan__vat">{vat}</div>
        <div className="subscription-plan__description">{description}</div>
      </div>
      <div className="subscription-plan__body">
        {contentItems && contentItems.length
          ? contentItems.map(item => (
              <div key={item} className="subscription-plan__body-item">
                {item}
              </div>
            ))
          : null}
      </div>
      <div className="subscription-plan__button-block">
        <Button
          disabled={disabled}
          onClick={onButtonClick}
          style={{ maxWidth: '150px' }}
          type="secondary"
        >
          {buttonTitle}
        </Button>
      </div>
    </div>
  );
}
