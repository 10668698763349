import React from 'react';
import types from 'prop-types';

// Modules

import history from '../../../modules/history';

// Constants

import languages from '../../../constants/languages';
import rules from '../../../constants/validation';

// Components

import { Button, Input, ErrorMessage } from '../../../components';
import Form from '../../core/Form';

// Styles

import './styles.scss';

// ----------------

export default class ForgotPasswordForm extends Form {
  // Type of props

  static propTypes = { className: types.string, style: types.object };

  // Default value for props

  static defaultProps = { className: '' };

  constructor(props) {
    super(props);

    // State of component

    this.state = {
      form: {
        email: ''
      }
    };

    // Validation rules

    this.rules = {
      email: {
        ...rules.email,
        message: {
          regExp: languages.errorMessage.email
        }
      }
    };

    // Init form

    this.init();
  }

  // -------- Methods --------

  // Render

  render() {
    const { className, style, networkProcess } = this.props;
    const { status } = this.state;

    return (
      <div className={`forgot-password-form ${className}`} style={style}>
        <div className="login-form__upper-block">
          <Input name="email" ctx={this} placeholder="email" />
          <ErrorMessage>{status.non_field_errors}</ErrorMessage>
        </div>
        <div className="forgot-password-form__buttons-block">
          <Button status={networkProcess && networkProcess.status} onClick={this.submitHandler}>
            Send
          </Button>
          <Button color="grey" onClick={() => history.goBack()}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}
