import deleteProps from '../modules/deleteProps';
import history from '../modules/history';
import query from 'querystringify';

/**
 * Adding or removing new query params
 *
 * @param {object}  newQuery     - Object with new query params
 * @param {array}   deleteQuery  - Array with query params for delete
 * @param {boolean} createString - If true, then return string with new queries
 * @param {boolean} replace - If true, call history.replace() method instead of history.push()
 *
 * @return {string/undefined}
 */

export default function(newQuery, deleteQuery, createString, replace = false) {
  const previousQuery =
    deleteQuery && deleteQuery.length
      ? deleteProps(query.parse(history.location.search), deleteQuery)
      : query.parse(history.location.search);

  if (createString) {
    return query.stringify({ ...previousQuery, ...newQuery }, true);
  }

  if (replace) {
    history.replace({
      search: query.stringify({
        ...previousQuery,
        ...newQuery,
        ...(!newQuery.page && previousQuery.page && { page: 1 })
      })
    });
  } else {
    history.push({
      search: query.stringify({
        ...previousQuery,
        ...newQuery,
        ...(!newQuery.page && previousQuery.page && { page: 1 })
      })
    });
  }
}
