import React from 'react';
import types from 'prop-types';
import ReactSVG from 'react-svg';

// Components

import { Button } from '../../../components';

// Styles

import './styles.scss';

// Icons

import emptyIcon from '../../../static/img/empty-folder.svg';

// ----------------

// Type of props

IntegrationBlock.propTypes = {
  className: types.string,
  style: types.object,
  buttonTitle: types.string,
  onClick: types.func,
  icon: types.node
};

// Default value for props

IntegrationBlock.defaultProps = {
  className: '',
  icon: emptyIcon
};

// ----------------

export default function IntegrationBlock(props) {
  const { className, style, buttonTitle, icon, onClick } = props;

  return (
    <div className={`integration-block ${className}`} style={style}>
      <div className="integration-block__icon">
        <ReactSVG src={icon} />
      </div>
      <Button color="borderedBlue" onClick={onClick}>
        {buttonTitle}
      </Button>
    </div>
  );
}
