import fetch from 'isomorphic-fetch';
import { localStorageNames } from '../constants';
import { API_URL } from '../constants/enviroment/index.js';
import download from 'downloadjs';

/**
 * Makes network request
 *
 * @param {string} endpoint
 * @param {object} init
 *
 * @return {object}
 **/

export default async function(endpoint, init, options = {}) {
  const token = localStorage.getItem(localStorageNames.token);

  // Set headers

  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...(token ? { 'x-access-token': `${token}` } : {}),
    ...(init.headers ? init.headers : {})
  };

  // Merging default headers with another params for request

  const _init = {
    ...init,
    headers
  };

  // Request

  const res = await fetch(`${API_URL}${endpoint}`, _init);
  const responseHeaders = res.headers;
  const contentType = responseHeaders && responseHeaders.get('content-type');
  let data = '';

  if (contentType && contentType.includes('text/csv')) {
    data = await res.text();
    download(data, options.fileName || 'report.csv');
  } else {
    data = await res.json();
  }

  if (res.status < 200 || res.status >= 300) {
    let error = new Error('Network error');
    error.des = { status: 'error', code: res.status, object: data };

    throw error;
  }

  return data;
}
