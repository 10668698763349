import React from 'react';
import types from 'prop-types';
import classNames from 'classnames';

// Styles

import './styles.scss';

// ----------------

// Type of props

Heading.propTypes = {
  withLargeMargin: types.bool,
  withMargin: types.bool,
  className: types.string,
  center: types.bool,
  weight: types.string,
  style: types.object,
  color: types.string,
  type: types.string
};

// Default value for props

Heading.defaultProps = {
  className: ''
};

// Modify styles

const modify = props =>
  classNames({
    [`paragraph--${props.color}`]: props.color,
    [`paragraph--${props.type}`]: props.type,
    [`paragraph--${props.weight}`]: props.weight,
    'paragraph--center': props.center,
    'paragraph--with-small-margin': props.withSmallMargin,
    'paragraph--with-margin': props.withMargin,
    'paragraph--with-large-margin': props.withLargeMargin
  });

// ----------------

export default function Heading(props) {
  const { className, style, children } = props;

  return (
    <p className={`paragraph ${modify(props)} ${className}`} style={style}>
      {children}
    </p>
  );
}
