/**
 * Middleware for refresh token if expired
 */

import { localStorageNames } from '../../constants';
import fetch from '../../modules/fetch';

// Modules

import isExpired from '../../modules/isTokenExpired';

// Action creators

import { logout } from '../actionCreators/user';

// ----------------

export default function() {
  return ({ dispatch, getState }) => next => actionCreator => {
    if (typeof actionCreator === 'object' && actionCreator.networkType) {
      // Refresh token if needed
      const token = localStorage.getItem(localStorageNames.token);
      const refreshToken = localStorage.getItem(localStorageNames.refreshToken);

      if (token && isExpired(token)) {
        if (refreshToken && !isExpired(refreshToken)) {
          const refreshPromise = fetch('auth/refresh-token', {
            method: 'POST',
            body: JSON.stringify({ refreshToken })
          });
          (async () => {
            try {
              const result = await refreshPromise;
              localStorage.setItem(localStorageNames.token, result.token);
              return next(actionCreator);
            } catch (error) {
              dispatch(logout());
            }
          })();
        } else {
          dispatch(logout());
        }
      } else {
        return next(actionCreator);
      }
    } else {
      return next(actionCreator);
    }
  };
}
