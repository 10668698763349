import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Components

import { Page } from '../../../components';

// Sub routes

const subRoutes = [require('./details').default, require('./terms').default];

// ----------------
class SettingsPage extends Page {
  static title = 'Personal settings';
  static path = '/main/settings';

  // Bind methods

  pageContent = this.pageContent.bind(this);

  // -------- Methods --------

  // Content of route

  pageContent() {
    return (
      <Switch>
        {subRoutes.map(route => (
          <Route path={route.path} component={route} key={route.path} exact={route.exactPath} />
        ))}
      </Switch>
    );
  }

  // Render

  render() {
    return this.securRenderPage(this.pageContent);
  }
}

export default SettingsPage;
