import React from 'react';
import types from 'prop-types';
import classNames from 'classnames';
import ReactSVG from 'react-svg';

// Styles

import './styles.scss';

// ----------------

// Type of props

IconButton.propTypes = {
  withBackground: types.bool,
  className: types.string,
  children: types.oneOfType([types.string, types.node]),
  onClick: types.func,
  rounded: types.bool,
  status: types.oneOfType([types.string, types.bool]),
  style: types.object,
  title: types.string,
  color: types.string,
  icon: types.node
};

// Default value for props

IconButton.defaultProps = {
  className: ''
};

// Modify styles

const modify = props =>
  classNames({
    [`icon-button__wrapper--${props.color}`]: props.color,
    [`icon-button__wrapper--${props.type}`]: props.type,
    'icon-button__wrapper--rounded': props.rounded,
    'icon-button__wrapper--with-background': props.withBackground
  });

// ----------------

export default function IconButton(props) {
  const { className, style, status, onClick, children, title, icon } = props;

  return (
    <button
      className={`icon-button__wrapper ${modify(props)} ${className}`}
      onClick={status === 'loading' ? null : onClick}
      style={style}
      title={title}
    >
      {icon ? <ReactSVG src={icon} /> : children}
    </button>
  );
}
