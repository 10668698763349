import React from 'react';
import types from 'prop-types';
import classNames from 'classnames';
import ReactSVG from 'react-svg';

// Components

import { Heading } from '../../../components';

// Styles

import './styles.scss';

// Images

import logo from '../../../static/img/logo.svg';

// ----------------

// Type of props

AuthStruct.propTypes = {
  bodyStyle: types.object,
  small: types.bool,
  title: types.string,
  style: types.object
};

// Default value for props

AuthStruct.defaultProps = { className: '' };

// Modify styles

const modify = small =>
  classNames({
    'auth-form-structure--small': small
  });

// ----------------

export default function AuthStruct(props) {
  const { children, small, title, style, bodyStyle } = props;

  return (
    <div className={`auth-form-structure ${modify(small)}`} style={style}>
      {title ? (
        <Heading type="h1">{title}</Heading>
      ) : (
        <div className="auth-form-structure__logo">
          <ReactSVG src={logo} />
        </div>
      )}

      <div className="auth-form-structure__body" style={bodyStyle}>
        {children}
      </div>
    </div>
  );
}
