import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

// Constants

import { redirectPath, trialTime } from '../../constants';

// Component

import { MainStruct, Page, ToastNotifications } from '../../components';

// Modules

import websocketConnect, { websocketDisconnect } from '../../modules/webSockets';

// Action creators

import { logout } from '../../store/actionCreators/user';
import { removeToastNotification } from '../../store/actionCreators/ui';

// ----------------

// Sub routes

const subRoutes = [
  require('./new-integration/instapages').default,
  require('./new-integration/leadpages').default,
  require('./new-integration/facebook').default,
  require('./new-integration/custom').default,
  require('./new-integration').default,
  require('./integrations').default,
  require('./new-lead').default,
  require('./settings').default,
  require('./accounts').default,
  require('./edit-account').default,
  require('./support').default,
  require('./leads').default,
  require('./plans').default,
  require('./home').default
];

class MainPage extends Page {
  static title = 'Main';
  static access = 'auth';
  static path = '/main';

  // State

  state = {
    isSidebarOpen: false,
    socket: null
  };

  // Bind methods

  pageContent = this.pageContent.bind(this);
  openSidebar = this.openSidebar.bind(this);
  closeSidebar = this.closeSidebar.bind(this);
  toggleSidebar = this.toggleSidebar.bind(this);

  // -------- Methods --------

  openSidebar() {
    this.setState({ isSidebarOpen: true });
  }

  closeSidebar() {
    this.setState({ isSidebarOpen: false });
  }

  toggleSidebar() {
    this.setState(prevState => ({ isSidebarOpen: !prevState.isSidebarOpen }));
  }

  // Content of route

  pageContent() {
    const { logout, userInfo, removeToastNotification, notifications } = this.props;
    return (
      <MainStruct
        hasSubscribeAccess={
          userInfo.StripeDetail || moment().diff(userInfo.createdAt, 'hours') <= trialTime
        }
        isSuperUser={userInfo.Roles && userInfo.Roles.some(role => role.name === 'ADMIN')}
        isSidebarOpen={this.state.isSidebarOpen}
        openSidebar={this.openSidebar}
        toggleSidebar={this.toggleSidebar}
        closeSidebar={this.closeSidebar}
        onLogout={logout}
      >
        <Switch>
          {subRoutes.map(route => (
            <Route path={route.path} component={route} key={route.path} exact={route.exactPath} />
          ))}
          <Route component={() => <Redirect to={redirectPath.withToken} />} />
        </Switch>
        <ToastNotifications
          removeToastNotification={removeToastNotification}
          notifications={notifications}
        />
      </MainStruct>
    );
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.props.userInfo.id) {
      const store = (this.context && this.context.store) || {};
      const dispatch = store.dispatch;
      const socket = websocketConnect(this.props.userInfo.id, dispatch);
      this.setState({ socket });
    }
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate();
    if (this.props.location !== prevProps.location && this.state.isSidebarOpen) {
      this.closeSidebar();
    }
    if (
      this.props.userInfo.id &&
      prevProps.userInfo.id !== this.props.userInfo.id &&
      !this.state.socket
    ) {
      const store = (this.context && this.context.store) || {};
      const dispatch = store.dispatch;
      const socket = websocketConnect(this.props.userInfo.id, dispatch);
      this.setState({ socket });
    }
  }

  componentWillUnmount() {
    const { socket } = this.state;
    websocketDisconnect(socket);
  }

  // Render

  render() {
    return this.securRenderPage(this.pageContent);
  }
}

export default connect(
  state => ({
    notifications: state.ui.toastNotifications,
    userInfo: state.user.userInfo
  }),
  { logout, removeToastNotification }
)(MainPage);
