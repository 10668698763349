import React from 'react';
import types from 'prop-types';
import moment from 'moment';
import ReactSVG from 'react-svg';
import classNames from 'classnames';

// Components

import { TableRow, IconButton } from '../../../../components';

// Icons

import restoreIcon from '../../../../static/img/restore.svg';
import deleteIcon from '../../../../static/img/delete.svg';
import emailIcon from '../../../../static/img/email.svg';
import editIcon from '../../../../static/img/edit.svg';

// Styles

import './styles.scss';

// ----------------

// Type of props

AccountsRow.propTypes = {
  onRestore: types.func,
  onDelete: types.func,
  onEdit: types.func,
  data: types.object
};

// Modify styles

const modifyRow = markedForRemoval =>
  classNames({
    'accounts-row--removed': markedForRemoval
  });

// ----------------

export default function AccountsRow(props) {
  const { data, onEdit, onDelete, onRestore } = props;

  return (
    <TableRow {...props} className={`accounts-row ${modifyRow(data.markedForRemoval)}`}>
      {data.name}
      {data.email}
      {moment
        .utc(data.createdAt)
        .local()
        .format('D MMM YYYY')}
      {data.integrationsCount}
      {(data.StripeDetail && data.StripeDetail.subscriptionName) || '-'}
      <>
        <IconButton
          title="Edit"
          onClick={() => onEdit(data)}
          style={{ marginRight: '10px' }}
          type="xsmall"
          color="grey"
        >
          <ReactSVG src={editIcon} />
        </IconButton>
        <IconButton title="Send email" style={{ margin: '0 10px' }} type="xsmall" color="grey">
          <a target="_blank" rel="noopener noreferrer" href={`mailto:${data.email}`}>
            <ReactSVG src={emailIcon} />
          </a>
        </IconButton>
        <IconButton
          title={data.markedForRemoval ? 'Restore' : 'Delete'}
          onClick={data.markedForRemoval ? () => onRestore(data) : () => onDelete(data)}
          style={{ marginLeft: '10px' }}
          type="xsmall"
          color="grey"
        >
          <ReactSVG src={data.markedForRemoval ? restoreIcon : deleteIcon} />
        </IconButton>
      </>
    </TableRow>
  );
}
