import React from 'react';
import types from 'prop-types';
import classNames from 'classnames';

// Components

import { Loader } from '../../../components';

// Styles

import './styles.scss';

// ----------------

// Type of props

Button.propTypes = {
  fullWidth: types.bool,
  autoWidth: types.bool,
  className: types.string,
  children: types.oneOfType([types.string, types.node]),
  disabled: types.bool,
  onClick: types.func,
  status: types.oneOfType([types.string, types.bool]),
  style: types.object,
  type: types.string,
  color: types.string,
  center: types.bool
};

// Default value for props

Button.defaultProps = {
  className: ''
};

// Modify styles

const modify = props =>
  classNames({
    [`button__wrapper--${props.color}`]: props.color,
    [`button__wrapper--${props.type}`]: props.type,
    'button__wrapper--full-width': props.fullWidth,
    'button__wrapper--auto-width': props.autoWidth,
    'button__wrapper--center': props.center
  });

// ----------------

export default function Button(props) {
  const { className, style, status, onClick, children, disabled } = props;

  return (
    <button
      disabled={disabled}
      className={`button__wrapper ${modify(props)} ${className}`}
      onClick={status === 'loading' ? null : onClick}
      style={style}
    >
      {status === 'loading' ? <Loader /> : children}
    </button>
  );
}
