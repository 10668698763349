import React from 'react';
import types from 'prop-types';

// Components

import {
  CopyToClipboard,
  ErrorMessage,
  Heading,
  Button,
  Loader,
  Input,
  Text
} from '../../../components';
import Form from '../../core/Form';

// Modules

import history from '../../../modules/history';

// Styles

import './styles.scss';

// ----------------

export default class InstapageIntegrationForm extends Form {
  // Type of props

  static propTypes = {
    generatingTokenProcess: types.object,
    networkProcess: types.object,
    integrationId: types.oneOfType([types.string, types.number]),
    className: types.string,
    style: types.object
  };

  // Default value for props

  static defaultProps = { className: '' };

  constructor(props) {
    super(props);

    const data = props.data || {};

    // State of component

    this.state = {
      form: {
        ...(!this.props.integrationId && { token: data.token || '' }),
        type: data.type || 'Instapages',
        name: data.name || '',
        redirectUrl: data.redirectUrl || data.url || ''
      }
    };

    // Validation rules

    this.commonRules = {
      optional: false
    };

    // Init form

    this.init();
  }

  // -------- Methods --------

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
    if (prevProps.data !== this.props.data) {
      const { data } = this.props;
      this.setState(prevState => ({
        form: {
          ...(!this.props.integrationId && { token: data.token || '' }),
          type: data.type || 'Instapages',
          name: data.name || '',
          redirectUrl: data.redirectUrl || data.url || ''
        }
      }));
    }
  }

  // Render

  render() {
    const { className, style, networkProcess, integrationId, generatingTokenProcess } = this.props;
    const { status } = this.state;

    return (
      <div className={`instapage-integration-form ${className}`} style={style}>
        <div className="instapage-integration-form__upper-block">
          <Heading type="h5" color="blue" withMargin>
            Get ready!
          </Heading>
          <Text type="small" center withMargin>
            From your Instapage account create a funnel webhook to integrate. Use this link for
            integrations.
          </Text>
          <Input
            customSideBlockAlignment="right"
            customSideBlock={
              generatingTokenProcess && generatingTokenProcess.status === 'loading' ? (
                <Loader color="mainBlue" size="small" />
              ) : (
                <CopyToClipboard isIcon text={this.state.form.redirectUrl} />
              )
            }
            placeholder="use this link to integrate to instapage"
            style={{ paddingRight: '18px' }}
            mainInput
            readOnly
            name="redirectUrl"
            ctx={this}
          />
          <Input name="name" ctx={this} placeholder="set integration name" mainInput />
          <ErrorMessage>{status.non_field_errors}</ErrorMessage>
        </div>
        <div className="instapage-integration-form__buttons-block">
          <Button color="filledGrey" onClick={() => history.push('/main/integrations')}>
            Cancel
          </Button>
          <Button
            status={networkProcess && networkProcess.status}
            onClick={() => this.submitHandler(integrationId)}
          >
            {integrationId ? 'Save' : 'Integrate'}
          </Button>
        </div>
      </div>
    );
  }
}
