/**
 * Middleware for network request
 */

import createActionType from '../../modules/createActionType';
import fetch from '../../modules/fetch';

// Action creators

import { logout } from '../actionCreators/user';
import { addToastNotification } from '../actionCreators/ui';

// ----------------

export default function() {
  return ({ dispatch, getState }) => next => actionCreator => {
    if (typeof actionCreator === 'function') {
      return actionCreator(dispatch, getState);
    }

    const {
      networkType,
      thenError,
      fileName,
      endpoint,
      payload,
      headers,
      before,
      method,
      then,
      ...rest
    } = actionCreator;

    if (!networkType) {
      return next(actionCreator);
    }

    next({ type: networkType, ...rest });

    let query = '';

    if (before) query = before(getState);

    const promise = fetch(
      endpoint(query),
      {
        headers,
        method: method || 'GET',
        body: JSON.stringify(payload)
      },
      {
        fileName
      }
    );

    (async () => {
      try {
        const result = await promise;

        next({
          type: createActionType(networkType),
          result,
          method: method || 'GET',
          ...rest
        });

        if (then) then(result, dispatch, getState);
      } catch (error) {
        const errorInfo = error;

        switch (errorInfo.des && errorInfo.des.code) {
          case 401: {
            if (errorInfo.des.object && errorInfo.des.object.error === 'Unauthorized') {
              dispatch(logout());
              break;
            }
          }

          /* case 404: {
           
            break;
          }*/

          /* eslint-disable */

          default: {
            if (!errorInfo.des || /^5\d{2}$/.test(errorInfo.des.code)) {
              dispatch(
                addToastNotification({
                  data: 'Server error. Please, try again',
                  type: 'error'
                })
              );
            }

            next({
              type: createActionType(networkType, 'err'),
              errorInfo
            });

            if (thenError) thenError(errorInfo, dispatch);
          }
        }
      }
    })();

    return promise;
  };
}
