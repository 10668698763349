import React from 'react';
import types from 'prop-types';
import ReactSVG from 'react-svg';
import classNames from 'classnames';

// Styles

import './styles.scss';

// Image and icons

import visibleOffIcon from '../../../static/img/blind.svg';
import visibleOnIcon from '../../../static/img/eye.svg';

// ----------------

// Type of props

Visible.propTypes = {
  visible: types.bool,
  onClick: types.func
};

// Modify styles

const modify = props =>
  classNames({
    [`visible-icon--${props.color}`]: props.color
  });

// ----------------

export default function Visible(props) {
  const { onClick, visible } = props;

  return (
    <div className={`visible-icon ${modify(props)}`} onClick={onClick}>
      <ReactSVG src={visible ? visibleOffIcon : visibleOnIcon} />
    </div>
  );
}
