import React from 'react';
import types from 'prop-types';
import classNames from 'classnames';

// Styles

import './styles.scss';

// ----------------

// Type of props

CheckBox.propTypes = {
  className: types.string,
  style: types.object,
  title: types.node,
  name: types.string.isRequired,
  ctx: types.object.isRequired,
  disabled: types.bool
};

// Default value for props

CheckBox.defaultProps = {
  className: ''
};

// Modify styles

const modify = error =>
  classNames({
    'checkbox__input--error': error
  });

const modifyWrapper = props =>
  classNames({
    'checkbox__wrapper--align-left': props.alignLeft
  });

const modifyInput = props =>
  classNames({
    'checkbox__input--main-input': props.mainInput
  });

// ----------------

export default function CheckBox(props) {
  const { className, title, name, ctx, style, error, disabled } = props;

  return (
    <div
      className={`checkbox__wrapper ${className} ${modifyWrapper(props)}`}
      style={style}
      ref={ref => (ctx.formRefs[name] = ref)}
    >
      <label className="checkbox__title">
        <input
          disabled={disabled}
          className={`checkbox__input ${modify(error || ctx.state.status[name])} ${modifyInput(
            props
          )}`}
          onChange={ctx.changeHandler}
          checked={ctx.state.form[name]}
          name={name}
          type="checkbox"
        />
        <span>{title || ''}</span>
      </label>
    </div>
  );
}
