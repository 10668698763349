import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducers';

// Middleware

import { createLogger } from 'redux-logger';
import network from '../store/middleware/network';
import refreshToken from '../store/middleware/refreshToken';

// ----------------

export default function(initialState) {
  const isDevelopment = process.env.NODE_ENV !== 'production';
  const composeEnhancers = isDevelopment
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;
  const middlewares = [refreshToken(), network()];
  isDevelopment &&
    middlewares.push(
      createLogger({
        collapsed: true
      })
    );

  return createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middlewares)));
}
