export const API_URL =
  process.env.REACT_APP_API_URL || 'https://ezlead-back-dev.scenario-projects.com/api/';

export const WEBSOCKET_URL =
  process.env.REACT_APP_WEBSOCKET_URL || 'wss://ezlead-back-dev.scenario-projects.com/';

export const FACEBOOK_APP_ID = '2265535037052125'; //TODO need to replace with 'process.env.REACT_APP_FACEBOOK_APP_ID' and test with another created ezylead facebook apps

export const STRIPE_PUBLIC_KEY =
  process.env.REACT_APP_STRIPE_PUBLIC_KEY || 'pk_test_8qgIVBBCXiNRs4nwpajCeqvN';
