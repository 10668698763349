// Default branch state

export const DEFAULT_BRANCH_STATE = '@ezylead/DEFAULT_BRANCH_STATE_UI';

// Toggle modal

export const TOGGLE_MODAL = '@lifeline/TOGGLE_MODAL';

// Toggle sidebar

export const TOGGLE_SIDEBAR = '@lifeline/TOGGLE_SIDEBAR';

// Add toast notification

export const ADD_TOAST_NOTIFICATION = '@lifeline/ADD_TOAST_NOTIFICATION';

// Remove toast notification

export const REMOVE_TOAST_NOTIFICATION = '@lifeline/REMOVE_TOAST_NOTIFICATION';

// Save form

export const SAVE_FORM = '@lifeline/SAVE_FORM';

// Clear saved form

export const CLEAR_SAVED_FORM = '@lifeline/CLEAR_SAVED_FORM';
