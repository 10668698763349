import React from 'react';
import types from 'prop-types';
import { Link } from 'react-router-dom';

// Constants

import rules from '../../../constants/validation';
import languages from '../../../constants/languages';

// Components

import {
  Button,
  Input,
  Visible,
  CheckBox,
  ErrorMessage,
  RadioButton,
  Text
} from '../../../components';
import Form from '../../core/Form';

// Styles

import './styles.scss';

// ----------------

export default class LoginForm extends Form {
  // Type of props

  static propTypes = {
    networkProcess: types.object,
    className: types.string,
    clearForm: types.func,
    saveForm: types.func,
    style: types.object
  };

  // Default value for props

  static defaultProps = { className: '' };

  constructor(props) {
    super(props);

    const data = this.props.savedForm || {};

    // State of component

    this.state = {
      form: {
        name: data.name || '',
        email: data.email || '',
        password: data.password || '',
        phone_number: data.phone_number || '',
        confirm_password: data.confirm_password || '',
        terms: data.terms || false,
        isEuResident: data.isEuResident || ''
      },
      passwordType: 'password',
      confirmPasswordType: 'password'
    };

    // Validation rules

    this.rules = {
      name: {
        ...rules.string
      },
      phone_number: {
        ...rules.phone
      },
      password: {
        ...rules.password,
        message: {
          regExp: languages.errorMessage.password
        }
      },
      confirm_password: { confirm: 'password' },
      email: {
        ...rules.email,
        message: {
          regExp: languages.errorMessage.email
        }
      }
    };

    this.commonRules = {
      optional: false
    };

    // Bind

    this.changePasswordType = this.changePasswordType.bind(this);
    this.successCallBack = this.successCallBack.bind(this);
    this.clearSavedForm = this.clearSavedForm.bind(this);

    // Init form

    this.init();
  }

  // -------- Methods --------

  transformForm(form) {
    const { name, email, phone_number, password, isEuResident } = form;
    return {
      name,
      email,
      phone_number,
      password,
      isEuResident
    };
  }

  // Change password field type

  changePasswordType(type) {
    const passwordType = this.state[type];

    if (passwordType === 'password') {
      this.setState({ [type]: 'text' });
    } else {
      this.setState({ [type]: 'password' });
    }
  }

  // Callback after form sent

  successCallBack() {
    this.clearSavedForm();
  }

  // Clear saved form

  clearSavedForm() {
    const { clearForm } = this.props;
    clearForm && clearForm({ name: 'registerForm' });
  }

  // Render

  render() {
    const { className, style, networkProcess, saveForm } = this.props;
    const { status, passwordType, confirmPasswordType, form } = this.state;

    return (
      <div className={`register-form ${className}`} style={style}>
        <div className="register-form__upper-block">
          <Input name="name" ctx={this} placeholder="name" className="fullWidth" fullWidth />
          <Input name="phone_number" type="tel" ctx={this} placeholder="phone" />
          <Input name="email" ctx={this} placeholder="email" />
          <Input
            autoComplete="off"
            type={passwordType}
            name="password"
            placeholder="password"
            ctx={this}
            customSideBlock={
              <Visible
                onClick={() => this.changePasswordType('passwordType')}
                visible={passwordType !== 'text'}
              />
            }
          />
          <Input
            type={confirmPasswordType}
            autoComplete="off"
            name="confirm_password"
            placeholder="confirm password"
            ctx={this}
            customSideBlock={
              <Visible
                onClick={() => this.changePasswordType('confirmPasswordType')}
                visible={confirmPasswordType !== 'text'}
              />
            }
          />
        </div>
        <div className="register-form__down-block">
          <Text center color={this.state.status.isEuResident ? 'red' : 'white'}>
            For GDPR compliance, please confirm if you are based in the EU:
          </Text>
          <div className="register-form__radio-wrapper">
            <RadioButton
              autoWidth
              title="Yes"
              style={{ height: '40px' }}
              value="true"
              name="isEuResident"
              ctx={this}
            />
            <RadioButton
              autoWidth
              title="No"
              style={{ height: '40px' }}
              value="false"
              name="isEuResident"
              ctx={this}
            />
          </div>
          <CheckBox
            name="terms"
            className="fullWidth"
            title={
              <Link
                className="link link--white"
                to="/auth/terms"
                onClick={() => saveForm({ registerForm: form })}
              >
                Terms and Conditions
              </Link>
            }
            ctx={this}
          />
          <ErrorMessage withoutTransform withMargin>
            {status.non_field_errors}
          </ErrorMessage>
        </div>
        <div className="register-form__buttons-block">
          <Button status={networkProcess && networkProcess.status} onClick={this.submitHandler}>
            Sign up
          </Button>
        </div>
        <div className="register-form__links-block">
          <Link
            className="link link--button-transparent"
            onClick={this.clearSavedForm}
            to="/auth/login"
          >
            Sign in
          </Link>
        </div>
      </div>
    );
  }
}
