import React from 'react';
import types from 'prop-types';

// Constants

import rules from '../../../constants/validation';

// Components

import { Button, ErrorMessage, Textarea, Text } from '../../../components';
import Form from '../../core/Form';

// Styles

import './styles.scss';

// ----------------

export default class FAQForm extends Form {
  // Type of props

  static propTypes = { className: types.string, style: types.object };

  // Default value for props

  static defaultProps = { className: '' };

  constructor(props) {
    super(props);

    // State of component

    this.state = {
      form: {
        message: ''
      }
    };

    // Validation rules

    this.rules = {
      message: { ...rules.longString }
    };

    // Bind methods

    this.successCallBack = this.successCallBack.bind(this);

    // Init form

    this.init();
  }

  // -------- Methods --------

  successCallBack() {
    this.setState({ ...this.state, form: { message: '' } });
  }

  // Render

  render() {
    const { className, style, networkProcess } = this.props;
    const { status } = this.state;

    return (
      <div className={`faq-form ${className}`} style={style}>
        <Text type="small" weight="medium">
          Didn't find answer above? Feel free to contact us using this form.
        </Text>
        <div className="faq-form__input-block">
          <Textarea
            placeholder="message"
            mainInput
            style={{ height: '125px' }}
            name="message"
            ctx={this}
          />
          <ErrorMessage>{status.non_field_errors}</ErrorMessage>
        </div>
        <div className="faq-form__buttons-block">
          <Button
            onClick={() => this.submitHandler()}
            status={networkProcess && networkProcess.status}
            type="secondary"
          >
            Send
          </Button>
        </div>
      </div>
    );
  }
}
