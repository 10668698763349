import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

// Modules

import history from '../../../modules/history';
import deleteProps from '../../../modules/deleteProps';

// Components

import {
  LeadsFilterForm,
  PageWrapper,
  PageTitle,
  LeadsRow,
  Button,
  Modal,
  Table,
  Page
} from '../../../components';

// Constants

import { trialTime } from '../../../constants';

// Action creators

import { fetchLeads, deleteLead, downloadLeadsCSV } from '../../../store/actionCreators/leads';
import { fetchIntegrationNames } from '../../../store/actionCreators/integrations';
import { toggleModal } from '../../../store/actionCreators/ui';

// Action types

import { FETCH_INTEGRATION_NAMES_NETWORK } from '../../../store/actionTypes/integrations';
import { FETCH_LEADS_NETWORK } from '../../../store/actionTypes/leads';

// ----------------
class LeadsPage extends Page {
  static title = 'Leads';
  static path = '/main/leads';
  static exactPath = true;

  // Data

  mount = [{ ac: fetchLeads }, { ac: fetchIntegrationNames }];
  pathUpdate = [fetchLeads];

  // Bind methods

  pageContent = this.pageContent.bind(this);

  // -------- Methods --------

  // Content of route

  pageContent() {
    const {
      fetchingIntegrationNamesProcess,
      onDeleteModalClose,
      onDeleteModalOpen,
      integrationNames,
      downloadLeadsCSV,
      deleteModalData,
      networkProcess,
      deleteLead,
      userInfo,
      leads,
      pages
    } = this.props;

    // !TODO Create function which will controll access to the UI elements

    const isSuperUser = userInfo.Roles.find(role => role.name === 'ADMIN');
    const access =
      isSuperUser ||
      userInfo.StripeDetail ||
      moment().diff(userInfo.createdAt, 'hours') <= trialTime;

    return (
      <PageWrapper>
        <PageTitle
          sideBlock={
            <>
              {access && (
                <Button type="secondary" onClick={() => history.push('/main/new-lead')}>
                  + New lead
                </Button>
              )}
              <Button type="secondary" onClick={downloadLeadsCSV}>
                Download csv
              </Button>
            </>
          }
        >
          Leads
        </PageTitle>
        <LeadsFilterForm
          integrationNames={integrationNames}
          networkProcess={fetchingIntegrationNamesProcess}
        />
        <Table
          columns={[
            {
              label: 'Status',
              width: 10,
              sort: 'status'
            },
            {
              label: 'Name',
              width: 27,
              sort: 'name'
            },
            {
              label: 'Date',
              width: 27,
              sort: 'createdAt'
            },
            {
              label: 'Source',
              width: 26,
              sort: 'source',
              style: { flex: 1 }
            },
            {
              label: 'Actions',
              style: { width: '65px', textAlign: 'left' }
            }
          ]}
          row={LeadsRow}
          networkProcess={networkProcess}
          list={leads}
          pages={pages}
          rowProps={{
            onEdit: data => history.push(`/main/edit-lead/${data.id}`),
            onDelete: data =>
              onDeleteModalOpen(
                deleteProps(data, [
                  'IntegrationId',
                  'UserId',
                  'createdAt',
                  'submitOnEnter',
                  'updatedAt'
                ])
              )
          }}
        />
        {!!deleteModalData && (
          <Modal
            data={deleteModalData}
            onClose={onDeleteModalClose}
            onConfirm={() => deleteLead(deleteModalData.lead)}
            withCancel
            warning
          />
        )}
      </PageWrapper>
    );
  }

  // Render

  render() {
    return this.securRenderPage(this.pageContent);
  }
}

export default connect(
  state => ({
    fetchingIntegrationNamesProcess: state.network[FETCH_INTEGRATION_NAMES_NETWORK],
    integrationNames: state.integrations.integrationNames,
    deleteModalData: state.ui.modal.deleteLeadModal,
    networkProcess: state.network[FETCH_LEADS_NETWORK],
    userInfo: state.user.userInfo,
    leads: state.leads.leads,
    pages: state.leads.pages
  }),
  {
    fetchIntegrationNames,
    downloadLeadsCSV,
    deleteLead,
    onDeleteModalClose: () => toggleModal({ name: 'deleteLeadModal' }),
    onDeleteModalOpen: lead =>
      toggleModal({
        name: 'deleteLeadModal',
        data: { lead, message: 'Do you want to delete this lead?' }
      })
  }
)(LeadsPage);
