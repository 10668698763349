import React from 'react';
import { connect } from 'react-redux';

// Components

import {
  SubscriptionPlanItem,
  CenterBlock,
  PageWrapper,
  PageTitle,
  Page
} from '../../../components';

// Modules

import history from '../../../modules/history';

// ----------------

// Subscription plans data

const getSubscriptionPlans = isEuResident => [
  {
    id: 1,
    heading: 'Individual account',
    description: 'Monthly',
    price: '$19.99',
    ...(isEuResident && { vat: '(incl. VAT)' }),
    onButtonClick: () => history.push('/main/settings/details?tab=subscription&planId=0'),
    contentItems: [
      'Single user',
      'Real-time lead alerts',
      'Unlimited leads',
      'Integration with Instapage, Leadpages, Facebook Lead Ads, Custom webforms',
      'Lead add, edit & forwarding',
      'Call scheduling',
      'Lead management dashboard',
      'Email Support'
    ]
  },
  {
    id: 2,
    heading: 'Individual account',
    description: '($203.88) Annual',
    price: '$16.99',
    ...(isEuResident && { vat: '(incl. VAT)' }),
    onButtonClick: () => history.push('/main/settings/details?tab=subscription&planId=1'),
    contentItems: [
      'Single user',
      'Real-time lead alerts',
      'Unlimited leads',
      'Integration with Instapage, Leadpages, Facebook Lead Ads, Custom webforms',
      'Lead add, edit & forwarding',
      'Call scheduling',
      'Lead management dashboard',
      'Email Support'
    ]
  },
  {
    id: 3,
    heading: 'Agency account',
    description: '',
    price: 'Coming soon',
    contentItems: [
      '1-5 Users',
      'Lead allocation by user',
      'Lead call response tracking by user',
      'Real-time lead alerts',
      'Unlimited leads',
      'Integration with Instapage, Leadpages, Facebook Lead Ads, Custom webforms',
      'Lead add, edit & forwarding',
      'Call scheduling',
      'Lead management dashboard',
      'Telephone / Email Support'
    ],
    disabled: true
  }
];

// ----------------

class PlansPage extends Page {
  static title = 'Plan';
  static path = '/main/plans';
  static exactPath = true;

  // Bind methods

  pageContent = this.pageContent.bind(this);

  // -------- Methods --------

  // Content of route

  pageContent() {
    const { isEuResident } = this.props;
    return (
      <PageWrapper>
        <PageTitle>Plan</PageTitle>
        <CenterBlock flex maxWidth="770px" withPadding>
          {getSubscriptionPlans(isEuResident).map(plan => (
            <SubscriptionPlanItem {...plan} key={plan.id} />
          ))}
        </CenterBlock>
      </PageWrapper>
    );
  }

  // Render

  render() {
    return this.securRenderPage(this.pageContent);
  }
}

export default connect(state => ({
  isEuResident: state.user.userInfo.isEuResident
}))(PlansPage);
