import { PureComponent } from 'react';
import types from 'prop-types';

export default class ContextProvider extends PureComponent {
  getChildContext() {
    const { store } = this.props;
    return { store };
  }

  render() {
    return this.props.children;
  }
}

ContextProvider.childContextTypes = {
  store: types.object.isRequired
};
