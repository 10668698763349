import React from 'react';
import types from 'prop-types';

// Styles

import './styles.scss';

// Image and icons

import authImage from '../../../static/img/authBackground.jpg';

// ----------------

// Type of props

AuthStruct.propTypes = {
  className: types.string,
  children: types.object
};

// Default value for props

AuthStruct.defaultProps = { className: '' };

// ----------------

export default function AuthStruct(props) {
  const { className, children } = props;

  return (
    <div
      className={`auth-structure ${className}`}
      style={{ backgroundImage: `url("${authImage}")` }}
    >
      {children}
    </div>
  );
}
