// Constants

import { WEBSOCKET_URL } from '../constants/enviroment';
import io from 'socket.io-client';

// Action creators

import { addToastNotification } from '../store/actionCreators/ui';

/**
 * The function establishes connection to the websocket server and dispatches actions based on received messages
 *
 * @param {number} userId - ID of user who logged in
 * @param {function} dispatch - dispatch function from redux
 *
 * @return {object} - socket instance
 **/

export default function websocketConnect(userId, dispatch) {
  try {
    const socket = io(WEBSOCKET_URL);

    socket.on('connect', () => {
      socket.emit('join', userId);
    });

    socket.on('newLead', e => {
      if (dispatch && e.lead) {
        const { id } = e.lead;
        dispatch(
          addToastNotification({
            data: 'You have new lead. Click to view.',
            url: `/main/edit-lead/${id}`,
            id
          })
        );
      }
    });

    socket.on('error', console.error);

    return socket;
  } catch (error) {
    console.error(error);
  }
}

/**
 * The function closes websocket connection
 **/

export function websocketDisconnect(socket) {
  socket && socket.close();
}
