import React from 'react';
import { CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';

// Styles

import './styles.scss';

// Images

import visa from '../../../static/img/payments/visa.png';
import mastercard from '../../../static/img/payments/mastercard.png';
import mastercardSecure from '../../../static/img/payments/mastercard_secure.png';
import visaVerified from '../../../static/img/payments/visa_verified.jpg';

// ----------------

// Stripe elements  styles

const elementStyles = {
  base: {
    color: '#32325D',
    fontWeight: 500,
    fontSize: '17px',
    letterSpacing: '6px',

    '::placeholder': {
      color: '#CFD7DF'
    }
  },
  invalid: {
    color: '#fa6678',

    '::placeholder': {
      color: '#fa6678'
    }
  },

  complete: {
    color: '#00b2ab'
  }
};

// ----------------

export default function PaymentCard(props) {
  const { lastCardNumbers } = props;

  return (
    <div className="payment-card__wrapper">
      <div className="payment-card payment-card--front">
        <div className="payment-card__body">
          <div className="payment-card__row">
            <div className="payment-card__row-label">Card number</div>
            <div className="payment-card__row-input">
              <CardNumberElement
                placeholder={`****  ****  ****  ${lastCardNumbers ? lastCardNumbers : '****'}`}
                style={elementStyles}
              />
            </div>
          </div>
          <div className="payment-card__row payment-card__row--short">
            <div className="payment-card__row-label">Expiry date</div>
            <div className="payment-card__row-input">
              <CardExpiryElement style={elementStyles} />
            </div>
          </div>
        </div>
        <div className="payment-card__footer">
          <img
            className="payment-card__footer-image payment-card__footer-image--visa"
            src={visa}
            alt="visa"
          />
          <img
            className="payment-card__footer-image payment-card__footer-image--mastercard"
            src={mastercard}
            alt="mastercard"
          />
          <img
            className="payment-card__footer-image payment-card__footer-image--visa-verified"
            src={visaVerified}
            alt="visa Verified"
          />
          <img
            className="payment-card__footer-image payment-card__footer-image--mastercard-secure"
            src={mastercardSecure}
            alt="mastercard Secure"
          />
        </div>
      </div>
      <div className="payment-card payment-card--back">
        <div className="payment-card__magnet" />
        <div className="payment-card__body">
          <div className="payment-card__row">
            <div className="payment-card__row-label">Cvv code</div>
            <div className="payment-card__row-input">
              <CardCVCElement style={elementStyles} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
