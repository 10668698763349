import React from 'react';
import { connect } from 'react-redux';

// Components

import { ChangePasswordForm, Page, Modal, PageWrapper } from '../../../../../components';

// Action creators

import { changePassword } from '../../../../../store/actionCreators/user';
import { toggleModal } from '../../../../../store/actionCreators/ui';

// Action types

import { CHANGE_PASSWORD_NETWORK } from '../../../../../store/actionTypes/user';

// ----------------

class ChangePasswordTab extends Page {
  // Route config

  static title = 'Change password';
  static asTab = {
    displayName: 'Change password',
    queryName: 'password'
  };

  // -------- Methods --------

  // Render

  render() {
    const { networkProcess, modalData, onModalClose, changePassword } = this.props;

    return (
      <PageWrapper>
        <ChangePasswordForm submitAction={changePassword} networkProcess={networkProcess} />
        {!!modalData && (
          <Modal data={modalData} onClose={onModalClose} popupTitle="Success" confirmation={true} />
        )}
      </PageWrapper>
    );
  }
}

export default connect(
  state => ({
    networkProcess: state.network[CHANGE_PASSWORD_NETWORK],
    modalData: state.ui.modal.changePasswordConfirmModal
  }),
  {
    onModalClose: () => toggleModal({ name: 'changePasswordConfirmModal' }),
    changePassword
  }
)(ChangePasswordTab);
