import React from 'react';
import { connect } from 'react-redux';

// Components

import {
  BaseTabsHeader,
  BaseTabsBody,
  PageWrapper,
  PageTitle,
  Button,
  Page,
  Tabs,
  Modal
} from '../../../../components';

// Action creators

import { deleteProfile } from '../../../../store/actionCreators/user';
import { toggleModal } from '../../../../store/actionCreators/ui';

// Action types

import { DELETE_PROFILE_NETWORK } from '../../../../store/actionTypes/user';

// Sub routes

const subRoutes = [
  require('./info').default,
  require('./password').default,
  require('./subscription').default
];

// ----------------
class DetailsPage extends Page {
  static title = 'Details';
  static path = '/main/settings/details';
  static exactPath = true;

  // -------- Methods --------

  // Render

  render() {
    const { isModalOpen, toggleModal, deleteProfile, networkProcess } = this.props;

    return (
      <PageWrapper>
        <PageTitle
          sideBlock={
            <Button onClick={toggleModal} type="secondary" color="borderedRed">
              Delete Account
            </Button>
          }
        >
          Details
        </PageTitle>
        <Tabs
          fullHeight
          noPadding
          header={BaseTabsHeader}
          style={{ height: '100%' }}
          body={BaseTabsBody}
          name="tab"
        >
          {subRoutes}
        </Tabs>
        {isModalOpen && (
          <Modal
            data={{
              message:
                'We are sorry to see that you want to delete your account. Once you confirm that you want to do it, we still give you the opportunity to login during next 24 hours and to cancel the process. If you don’t your account will be permanently deleted'
            }}
            bodyTextStyle="small"
            onClose={toggleModal}
            onConfirm={deleteProfile}
            popupTitle="Delete account"
            confirmButtonTitle="delete"
            confirmButtonColor="filledRed"
            networkProcess={networkProcess}
            withCancel
            error
          />
        )}
      </PageWrapper>
    );
  }
}

export default connect(
  state => ({
    isModalOpen: !!state.ui.modal.deleteAccountModal,
    networkProcess: state.network[DELETE_PROFILE_NETWORK]
  }),
  {
    toggleModal: () => toggleModal({ name: 'deleteAccountModal' }),
    deleteProfile
  }
)(DetailsPage);
